import type {V1NotebookContent} from '../../types/version/v1';
import type {V2NotebookContent, V2NotebookMetadata} from '../../types/version/v2';
import {JupyterNotebookUIConfigVersion} from '../../constants/version';

export const mapV1NotebookContentToV2 = (notebook: V1NotebookContent): V2NotebookContent => {
    const tractoNotebookMetadata: V2NotebookMetadata['tracto'] = {
        is_solution_notebook: notebook.metadata.tracto.is_solution_notebook,
        notebook_cypress_id: notebook.metadata.tracto.notebook_cypress_id,
    };

    return {
        ...notebook,
        metadata: {
            ...notebook.metadata,
            tracto: tractoNotebookMetadata,
        },
        cells: notebook.cells.map((cell) => {
            return {
                ...cell,
                metadata: {
                    ...cell.metadata,
                    tracto: {
                        view_cell_type: cell.metadata.tracto.type,
                        view_source: cell.source,
                    },
                },
            };
        }),
        tracto_notebook_content_version: JupyterNotebookUIConfigVersion.V2,
    };
};
