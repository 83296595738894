import {type PayloadAction, createSlice} from '@reduxjs/toolkit';
import type {SQLTemplates} from '../../types/sql/temaplate';

type InitialState = {
    templates: SQLTemplates | null;
};

const initialState: InitialState = {
    templates: null,
};

export const sqlSlice = createSlice({
    name: 'jupyter.sql',
    initialState,
    reducers: {
        setTemplates: (state, action: PayloadAction<{templates: SQLTemplates}>) => {
            state.templates = action.payload.templates;
        },
    },
});
