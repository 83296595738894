import React from 'react';
import TractoMonacoEditor from 'components/TractoMonacoEditor/TractoMonacoEditor';
import type * as monaco from 'monaco-editor/esm/vs/editor/editor.api';
import block from 'bem-cn-lite';
import type {TractoNotebookMarkdownCell} from '../../types/version';
import transform from '@diplodoc/transform';
import {defaultOptions} from '@diplodoc/transform/lib/sanitize';
import {TractoMarkdown} from '../../../../components/TractoMarkdown/TractoMarkdown';

import './MarkdownCellSource.scss';

function replaceAll(str: string, find: string, replace: string) {
    return str.replace(new RegExp(find, 'g'), replace);
}

const b = block('markdown-cell-source');

type MarkdownCellSourceProps = {
    source: TractoNotebookMarkdownCell['source'];
    attachments: TractoNotebookMarkdownCell['attachments'];
    isEditable?: boolean;
    editorRef: React.MutableRefObject<monaco.editor.IStandaloneCodeEditor | undefined>;
    onChange: (value: string) => void;
    onMarkdownBlur?: () => void;
    onImagePaste?: (value: {name: string; type: string; base64: string}) => void;
};

export const MarkdownCellSource: React.FC<MarkdownCellSourceProps> = (
    props: MarkdownCellSourceProps,
) => {
    const {source, attachments, isEditable, editorRef, onChange, onMarkdownBlur, onImagePaste} =
        props;

    const markdownEditorModel = React.useRef<monaco.editor.ITextModel | null | undefined>();
    const markdownEditorViewState = React.useRef<
        monaco.editor.ICodeEditorViewState | null | undefined
    >();

    React.useEffect(() => {
        if (isEditable) {
            // restoring model and view-state, when editor should become focused
            // this steps should appear earlier than focus and onFocusHandlers otherwise focus will not work
            if (markdownEditorViewState.current) {
                editorRef.current?.restoreViewState(markdownEditorViewState.current);
            }

            if (markdownEditorModel.current) {
                editorRef.current?.setModel(markdownEditorModel.current);
            }
        }
    }, [isEditable]);

    const handleMarkdownEditorBlur = React.useCallback(() => {
        onMarkdownBlur?.();
        markdownEditorModel.current = editorRef.current?.getModel();
        markdownEditorViewState.current = editorRef.current?.saveViewState();
        // set model null, when monaco-editor has display:none
        // we stop storing the whole editor in DOM, and it's improving performance (especially with large markdown)
        editorRef.current?.setModel(null);
    }, [onMarkdownBlur]);

    const markdown = String(Array.isArray(source) ? source.join('') : source || '');

    let processedMarkdown = markdown;

    for (const pair of Object.entries(attachments || {})) {
        const attachmentName: string = pair[0];
        const attachment = pair[1] as Record<string, string>;
        const [mimeType, base64Data] = Object.entries(attachment)[0];

        const imageInBase64 = `data:${mimeType};base64,${base64Data}`;

        processedMarkdown = replaceAll(markdown, `attachment:${attachmentName}`, imageInBase64);
    }

    const result = React.useMemo(() => {
        return transform(processedMarkdown, {
            allowHTML: true,
            disableLiquid: true,
            lang: 'en',
            sanitizeOptions: Object.assign(
                {},
                {
                    ...defaultOptions,
                    allowedTags: [
                        // @ts-ignore
                        ...defaultOptions.allowedTags,
                        'img',
                    ],
                    allowedAttributes: {
                        // @ts-ignore
                        ...defaultOptions.allowedAttributes,
                        img: ['src'],
                    },
                    allowedSchemes: [
                        // @ts-ignore
                        ...defaultOptions.allowedSchemes,
                        'data',
                    ],
                },
            ),
        });
    }, [processedMarkdown]);

    return (
        <>
            <div className={b('markdown-wrapper', {hidden: isEditable})}>
                {!isEditable ? (
                    <TractoMarkdown html={result.result.html} className={b('markdown-container')} />
                ) : null}
            </div>
            <div className={b('markdown-editor-wrapper', {hidden: !isEditable})}>
                <TractoMonacoEditor
                    editorRef={editorRef}
                    language={'markdown'}
                    value={markdown}
                    onChange={onChange}
                    onBlur={handleMarkdownEditorBlur}
                    onImagePaste={onImagePaste}
                />
            </div>
        </>
    );
};
