import {useSelector} from 'react-redux';
import {YTDFDialog} from '@ytsaurus-ui-platform/src/ui/components/Dialog';
import React from 'react';
import {FormApi} from '@gravity-ui/dialog-fields';
import {useTractoDispatch} from '../../../../store/tracto-dispatch';
import {selectModalCreateDocumentState} from 'features/Navigation/store/selectors/modals/modal-create-document';
import {modalCreateDocumentSlice} from 'features/Navigation/store/slices/modals/modal-create-document';
import {createBlueprint} from 'features/ImageBuilder/store/actions/api/blueprints';
import {updateView} from '@ytsaurus-ui-platform/src/ui/store/actions/navigation';
import {getPath} from '@ytsaurus-ui-platform/src/ui/store/selectors/navigation';
import {getBlueprintIdFromPath} from 'features/ImageBuilder/utils/blueprint';

type FormValues = {
    path: string;
};

export const BlueprintCreateDialog = () => {
    const dispatch = useTractoDispatch();
    const state = useSelector(selectModalCreateDocumentState);
    const path = `${useSelector(getPath)}/New blueprint`;

    const handleClose = React.useCallback(() => {
        dispatch(modalCreateDocumentSlice.actions.close());
    }, []);

    const handleIsApplyDisabled = React.useCallback(() => {
        return false;
    }, []);

    const handleAdd = React.useCallback(async (formApi: FormApi<FormValues>) => {
        const {path} = formApi.getState().values;

        await dispatch(
            createBlueprint({
                blueprint_id: getBlueprintIdFromPath(path),
            }),
        );

        await dispatch(modalCreateDocumentSlice.actions.close());

        await dispatch(updateView());
    }, []);

    return (
        <YTDFDialog<FormValues>
            visible={state.documentType === 'blueprint'}
            headerProps={{title: 'Create blueprint'}}
            onClose={handleClose}
            fields={[
                {
                    type: 'text',
                    name: 'path',
                    caption: 'Blueprint path',
                    required: true,
                    extras: {
                        placeholder: 'Please enter path for your blueprint',
                    },
                    initialValue: path,
                },
            ]}
            onAdd={handleAdd}
            isApplyDisabled={handleIsApplyDisabled}
        />
    );
};
