import React, {useRef} from 'react';
import block from 'bem-cn-lite';
import './JupyterCell.scss';
import {JupyterCellSource} from '../JupyterCellSource/JupyterCellSource';
import {JupyterCellOutput} from '../JupyterCellOutput/JupyterCellOutput';
import {JupyterCellQa} from '../../../../../shared/qa';
import type {TractoNotebookCell} from '../../types/version';
import {isCode} from '@jupyterlab/nbformat';
import {JupyterCellControlsContainer} from '../../containers/JupyterCellControlsContainer/JupyterCellControlsContainer';
import {getAnchorHashCellId} from '../../utils/cell/anchor';

const b = block('jupyter-cell');

export type JupyterCellProps = {
    isEditable?: boolean;
    isFocused?: boolean;
    isDirty?: boolean;
    cellRef?: React.MutableRefObject<HTMLDivElement | null>;
    cell: TractoNotebookCell;
    isRunning?: boolean;
    onChange: (value: string) => void;
    onClick?: () => void;
    onEditorFocus?: (event: React.FocusEvent<HTMLElement>) => void;
    onDoubleClick?: () => void;
    onMarkdownBlur?: () => void;
    onImagePaste?: (value: {name: string; type: string; base64: string}) => void;
    cellIndex: number;
};

export const JupyterCell: React.FC<JupyterCellProps> = ({
    isDirty,
    isEditable,
    isFocused,
    cell,
    cellRef = useRef(null),
    isRunning,
    onChange,
    onEditorFocus,
    onDoubleClick,
    onMarkdownBlur,
    onImagePaste,
    onClick,
    cellIndex,
}: JupyterCellProps) => {
    const cellType = cell.cell_type;

    React.useEffect(() => {
        if (isFocused && !isEditable) {
            cellRef.current?.focus({preventScroll: true});
        }
    }, [isFocused, isEditable]);

    return (
        <div
            data-qa={JupyterCellQa.JupyterCell}
            tabIndex={-1}
            data-id={cell.id}
            className={b({focused: isFocused, editable: isEditable, [cellType]: true})}
            ref={cellRef}
            onDoubleClick={onDoubleClick}
            onClick={onClick}
            id={getAnchorHashCellId(cell.id)}
        >
            <JupyterCellControlsContainer
                cellIndex={cellIndex}
                className={b('actions', {[cellType]: true})}
            />
            <div className={b('source')}>
                <JupyterCellSource
                    cell={cell}
                    isDirty={isDirty}
                    isEditable={isEditable}
                    isRunning={isRunning}
                    isFocused={isFocused}
                    onChange={onChange}
                    onFocus={onEditorFocus}
                    onMarkdownBlur={onMarkdownBlur}
                    onImagePaste={onImagePaste}
                />
            </div>
            {isCode(cell) ? (
                <div className={b('output')}>
                    <JupyterCellOutput cell={cell} />
                </div>
            ) : null}
        </div>
    );
};
