import {
    type ChytSQLTemplate,
    type QLSQLTemplate,
    type SpytSQLTemplate,
    type YQLSQLTemplate,
} from 'features/Jupyter/types/sql/temaplate';
import type {SQLViewTemplate} from '../../../types/sql/view';

const getControlVariables = <T extends {query: any}>(variables: T): Omit<T, 'query'> => {
    return (Object.entries(variables) as Array<[keyof T, any]>).reduce(
        (acc, [key, value]) => {
            if (key === 'query') {
                return acc;
            }

            // @ts-ignore
            acc[key] = value;

            return acc;
        },
        {} as Omit<T, 'query'>,
    );
};

export const prepareChytSQLViewTemplate = (
    variables: ChytSQLTemplate['variables'],
    cellVariables: Record<string, string>,
): Array<SQLViewTemplate> => {
    const controls = getControlVariables(variables);

    return (Object.keys(controls) as Array<keyof Omit<ChytSQLTemplate['variables'], 'query'>>).map(
        (key): SQLViewTemplate => {
            const variable = controls[key];

            return {
                variable: key,
                title: variable.title ?? key,
                required: variable.required,
                default_value: 'default_value' in variable ? variable.default_value : '',
                value: cellVariables[key] ?? '',
                view: 'input',
            };
        },
    );
};

export const prepareQLSQLViewTemplate = (
    variables: QLSQLTemplate['variables'],
    cellVariables: Record<string, string>,
): Array<SQLViewTemplate> => {
    const controls = getControlVariables(variables);

    return (Object.keys(controls) as Array<keyof Omit<QLSQLTemplate['variables'], 'query'>>).map(
        (key): SQLViewTemplate => {
            const variable = controls[key];

            return {
                variable: key,
                title: variable.title ?? key,
                required: variable.required,
                default_value: 'default_value' in variable ? variable.default_value : '',
                value: cellVariables[key] ?? '',
                view: 'input',
            };
        },
    );
};

export const prepareSpytSQLViewTemplate = (
    variables: SpytSQLTemplate['variables'],
    cellVariables: Record<string, string>,
): Array<SQLViewTemplate> => {
    const controls = getControlVariables(variables);

    return (Object.keys(controls) as Array<keyof Omit<SpytSQLTemplate['variables'], 'query'>>).map(
        (key): SQLViewTemplate => {
            const variable = controls[key];

            return {
                variable: key,
                title: variable.title ?? key,
                required: variable.required,
                default_value: 'default_value' in variable ? variable.default_value : '',
                value: cellVariables[key] ?? '',
                view: 'input',
            };
        },
    );
};

export const prepareYQLSQLViewTemplate = (
    variables: YQLSQLTemplate['variables'],
    cellVariables: Record<string, string>,
): Array<SQLViewTemplate> => {
    const controls = getControlVariables(variables);

    return (Object.keys(controls) as Array<keyof Omit<YQLSQLTemplate['variables'], 'query'>>).map(
        (key): SQLViewTemplate => {
            const variable = controls[key];

            return {
                variable: key,
                title: variable.title ?? key,
                required: variable.required,
                default_value: 'default_value' in variable ? variable.default_value : '',
                value: cellVariables[key] ?? '',
                view: 'input',
            };
        },
    );
};
