import type {TractoRootState} from '../../../store/reducers';
import {produce} from 'immer';
import {updateIfChanged} from '@ytsaurus-ui-platform/src/ui/utils/utils';
import {schedulingParams} from '@ytsaurus-ui-platform/src/ui/store/reducers/scheduling/url-mapping';

export function getMonitoringPreparedState(
    state: TractoRootState,
    {query}: {query: TractoRootState},
) {
    return produce(state, (draft) => {
        updateIfChanged(draft.scheduling.scheduling, 'pool', query.scheduling.scheduling.pool);
        updateIfChanged(draft.scheduling.scheduling, 'tree', query.scheduling.scheduling.tree);
        updateIfChanged(
            draft.tracto.monitoring.dashboard,
            'spd__state',
            query.tracto.monitoring.dashboard.spd__state,
        );
    });
}

export const monitoringParams = {
    ...schedulingParams,
    spd__state: {
        stateKey: 'tracto.monitoring.dashboard.spd__state',
        initialState: '',
    },
};
