import {TractoThunkDispatch} from '../../../../store/tracto-dispatch';
import {BlueprintType, getBlueprint, saveBlueprint} from './api/blueprints';
import {TractoRootState} from 'store/reducers';
import {selectBlueprint, selectBlueprintId} from '../selectors/blueprint';
import {blueprintSlice} from '../slices/blueprintSlice';
import {runNewBuild} from './api/runs';
import {wrapApiPromiseByToaster} from '@ytsaurus-ui-platform/src/ui/utils/utils';
import {Page} from '@ytsaurus-ui-platform/src/shared/constants/settings';
import YT from '@ytsaurus-ui-platform/src/ui/config/yt-config';
import Link from '@ytsaurus-ui-platform/src/ui/components/Link/Link';
import React from 'react';
import {getTractoClusterUiConfig} from '../../../../store/selectors/tracto-cluster-ui-config';
import {batch} from 'react-redux';

export const userSaveBlueprint = () => {
    return async (dispatch: TractoThunkDispatch, getState: () => TractoRootState) => {
        const blueprint_id = selectBlueprintId(getState());

        const blueprint: BlueprintType = selectBlueprint(getState());

        dispatch(blueprintSlice.actions.setSavingInProgress({savingInProgress: true}));

        await dispatch(saveBlueprint({blueprint_id, blueprint}));

        dispatch(blueprintSlice.actions.setSavingInProgress({savingInProgress: false}));
    };
};

export const userGetBlueprint = () => {
    return async (dispatch: TractoThunkDispatch, getState: () => TractoRootState) => {
        const blueprint_id = selectBlueprintId(getState());
        const clusterUIConfig = getTractoClusterUiConfig(getState());

        const {blueprint} = await dispatch(getBlueprint({blueprint_id}));

        const imageRegistry = (
            blueprint.docker_params.image.registry || clusterUIConfig.tracto_registry_base_url!
        )
            .replace('https://', '')
            .replace('http://', '');
        const imageName = blueprint.docker_params.image.name;

        const content = blueprint.content.map((item) => item.content).join('\n');

        const attachedFiles = blueprint.files;

        const memoryLimit = blueprint.build_params?.memory_limit || 0;
        const cpuLimit = blueprint.build_params?.cpu_limit || 0;

        batch(() => {
            dispatch(blueprintSlice.actions.setContent({content}));
            dispatch(blueprintSlice.actions.setAttachedFiles({attachedFiles}));
            dispatch(blueprintSlice.actions.setImageRegistry({imageRegistry}));
            dispatch(blueprintSlice.actions.setImageName({imageName}));
            dispatch(blueprintSlice.actions.setBlueprintLoaded());
            dispatch(blueprintSlice.actions.setMemoryLimit({memoryLimit}));
            dispatch(blueprintSlice.actions.setCpuLimit({cpuLimit}));
        });
    };
};

export const userRunNewBuild = ({tag}: {tag: string}) => {
    return async (dispatch: TractoThunkDispatch, getState: () => TractoRootState) => {
        const blueprint_id = selectBlueprintId(getState());

        const blueprint: BlueprintType = selectBlueprint(getState());

        const promise = dispatch(
            runNewBuild({
                blueprint_id,
                blueprint,
                run_params: {
                    tag,
                },
                blueprint_type: 'yt',
            }),
        );

        return wrapApiPromiseByToaster(promise, {
            toasterName: 'blueprint-user-build',
            successTitle: 'Blueprint build was started',
            successContent: (response: any) => {
                const runId = response.run_id;

                const url = `/${YT.cluster}/${Page.OPERATIONS}/${runId}/details`;

                return <Link url={url}>Visit operation page for more details</Link>;
            },
            errorTitle: 'Failed to strart blueprint build',
            errorContent: (error) => {
                const code = error.code ? `[code ${error.code}]` : '';
                const message = error.message;

                return [code, message].filter(Boolean).join(' ');
            },
        });
    };
};
