import React from 'react';

import {
    ButtonWithHotkey,
    type ButtonWithHotkeyProps,
} from '../components/ButtonWithHotkey/ButtonWithHotkey';
import type {HotkeyDescriptor} from '../types';
import type {KeysWithCommand} from '../types/helpers';

export function withButtonBoundHotkeys<T extends {[key: string]: HotkeyDescriptor}>(
    hotkeys: T,
    name: string,
) {
    const displayName = `withButtonBoundHotkeys_${name}`;

    const Component: React.FC<{id: KeysWithCommand<T>} & Omit<ButtonWithHotkeyProps, 'hotkey'>> =
        function (props: {id: KeysWithCommand<T>} & Omit<ButtonWithHotkeyProps, 'hotkey'>) {
            const {id, ...other} = props;

            const hotkey = React.useMemo(() => {
                return hotkeys[id];
            }, [id]);

            return <ButtonWithHotkey hotkey={hotkey} {...other} />;
        };

    Component.displayName = displayName;

    return Component;
}
