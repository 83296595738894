import {getCodeCell, getMarkdownCell} from '../../../utils/cell/create';
import {NotebookCellType} from '../../../constants/cell';
import type {TractoThunkDispatch} from '../../../../../store/tracto-dispatch';
import type {TractoRootState} from '../../../../../store/reducers';
import {selectSQLTemplateByCellType} from '../../selectors/sql';
import {NotebookSQLHelper} from '../../../utils/sql/helper';
import {prepareTemplateVariables} from '../../../utils/sql/template';
import type {MultilineString} from '@jupyterlab/nbformat';
import type {SQLCellViewType} from '../../../types/cell/sql';

type CreateCellPayload = {
    cellId: string;
    source: MultilineString;
};

const prepareCellPayload = (payload?: CreateCellPayload): Required<CreateCellPayload> => {
    return {
        cellId: payload?.cellId ?? crypto.randomUUID(),
        source: payload?.source ?? [],
    };
};

const prepareSQLTemplate = (cellViewType: SQLCellViewType, source: MultilineString) => {
    return (_dispatch: TractoThunkDispatch, getState: () => TractoRootState) => {
        const state = getState();

        const preparedSource = Array.isArray(source) ? source.join('') : source;

        const template = selectSQLTemplateByCellType(state, cellViewType);

        const templateId = NotebookSQLHelper.getTemplateKey(cellViewType);

        const variables = prepareTemplateVariables(template.variables);

        variables.query = preparedSource;

        const query = NotebookSQLHelper.getQuery(templateId, variables);

        return {query, templateId, variables};
    };
};

const createCodeCell = (payload: CreateCellPayload) => {
    return (_dispatch: TractoThunkDispatch, _getState: () => TractoRootState) => {
        return getCodeCell({
            ...payload,
            metadata: {
                tracto: {
                    view_cell_type: NotebookCellType.CODE,
                    view_source: payload.source,
                },
            },
        });
    };
};

const createMarkdownCell = (payload: CreateCellPayload) => {
    return (_dispatch: TractoThunkDispatch, _getState: () => TractoRootState) => {
        return getMarkdownCell({
            ...payload,
            metadata: {
                tracto: {
                    view_cell_type: NotebookCellType.MARKDOWN,
                    view_source: payload.source,
                },
            },
        });
    };
};

const createCHYTCell = (payload: CreateCellPayload) => {
    return (dispatch: TractoThunkDispatch, _getState: () => TractoRootState) => {
        const {query, templateId, variables} = dispatch(
            prepareSQLTemplate(NotebookCellType.CHYT, payload.source),
        );

        return getCodeCell({
            ...payload,
            source: query,
            metadata: {
                tracto: {
                    view_cell_type: NotebookCellType.CHYT,
                    view_source: payload.source,
                    sql: {
                        variables,
                        template_id: templateId,
                    },
                },
            },
        });
    };
};

const createSPYTCell = (payload: CreateCellPayload) => {
    return (dispatch: TractoThunkDispatch, _getState: () => TractoRootState) => {
        const {query, templateId, variables} = dispatch(
            prepareSQLTemplate(NotebookCellType.SPYT, payload.source),
        );

        return getCodeCell({
            ...payload,
            source: query,
            metadata: {
                tracto: {
                    view_cell_type: NotebookCellType.SPYT,
                    view_source: payload.source,
                    sql: {
                        variables,
                        template_id: templateId,
                    },
                },
            },
        });
    };
};

const createQLCell = (payload: CreateCellPayload) => {
    return (dispatch: TractoThunkDispatch, _getState: () => TractoRootState) => {
        const {query, templateId, variables} = dispatch(
            prepareSQLTemplate(NotebookCellType.QL, payload.source),
        );

        return getCodeCell({
            ...payload,
            source: query,
            metadata: {
                tracto: {
                    view_cell_type: NotebookCellType.QL,
                    view_source: payload.source,
                    sql: {
                        variables,
                        template_id: templateId,
                    },
                },
            },
        });
    };
};

const createYQLCell = (payload: CreateCellPayload) => {
    return (dispatch: TractoThunkDispatch, _getState: () => TractoRootState) => {
        const {query, templateId, variables} = dispatch(
            prepareSQLTemplate(NotebookCellType.YQL, payload.source),
        );

        return getCodeCell({
            ...payload,
            source: query,
            metadata: {
                tracto: {
                    view_cell_type: NotebookCellType.YQL,
                    view_source: payload.source,
                    sql: {
                        variables,
                        template_id: templateId,
                    },
                },
            },
        });
    };
};

export const createCell = (type: NotebookCellType, payload?: CreateCellPayload) => {
    return (dispatch: TractoThunkDispatch) => {
        switch (type) {
            case NotebookCellType.CODE:
                return dispatch(createCodeCell(prepareCellPayload(payload)));
            case NotebookCellType.MARKDOWN:
                return dispatch(createMarkdownCell(prepareCellPayload(payload)));
            case NotebookCellType.CHYT:
                return dispatch(createCHYTCell(prepareCellPayload(payload)));
            case NotebookCellType.YQL:
                return dispatch(createYQLCell(prepareCellPayload(payload)));
            case NotebookCellType.SPYT:
                return dispatch(createSPYTCell(prepareCellPayload(payload)));
            case NotebookCellType.QL:
                return dispatch(createQLCell(prepareCellPayload(payload)));
            default:
                return null as never;
        }
    };
};
