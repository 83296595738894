import React from 'react';

import type {HotkeyDescriptor} from '../types';
import {
    DropdownWithHotkeys,
    type DropdownWithHotkeysProps,
} from '../components/DropdownWithHotkeys/DropdownWithHotkeys';
import type {BoundDropdownWithHotkeysItem} from '../types/bound-dropdown';

export type BoundDropdownWithHotkeysProps<T extends {[key: string]: HotkeyDescriptor}> = Omit<
    DropdownWithHotkeysProps,
    'items'
> & {
    items: BoundDropdownWithHotkeysItem<T>[];
};

export function withDropdownBoundHotkeys<T extends {[key: string]: HotkeyDescriptor}>(
    hotkeys: T,
    name: string,
) {
    const Component: React.FC<BoundDropdownWithHotkeysProps<T>> = function (
        props: BoundDropdownWithHotkeysProps<T>,
    ) {
        const {items, ...other} = props;

        const preparedItems: DropdownWithHotkeysProps['items'] = React.useMemo(() => {
            return items.map((item) => {
                const {keyId, ...rest} = item;
                const hotkey = keyId ? hotkeys[keyId] : null;

                return {
                    ...rest,
                    hotkey: hotkey?.key,
                    platform: hotkey?.platform,
                };
            });
        }, [items]);

        return <DropdownWithHotkeys {...other} items={preparedItems} />;
    };

    Component.displayName = `withDropdownBoundHotkeys_${name}`;

    return Component;
}
