import {useSelector} from 'react-redux';
import {YTDFDialog} from '@ytsaurus-ui-platform/src/ui/components/Dialog';
import React from 'react';
import {FormApi} from '@gravity-ui/dialog-fields';
import {createWorkflow} from '../../../store/actions/workflow';
import {useTractoDispatch} from '../../../../../store/tracto-dispatch';
import {updateView} from '@ytsaurus-ui-platform/src/ui/store/actions/navigation';
import {selectModalCreateDocumentState} from 'features/Navigation/store/selectors/modals/modal-create-document';
import {modalCreateDocumentSlice} from 'features/Navigation/store/slices/modals/modal-create-document';

type FormValues = {
    workflowId: string;
};

export const WorkflowCreateModal = () => {
    const dispatch = useTractoDispatch();
    const state = useSelector(selectModalCreateDocumentState);

    const handleClose = React.useCallback(() => {
        dispatch(modalCreateDocumentSlice.actions.close());
    }, []);

    const handleAdd = React.useCallback(async (formApi: FormApi<FormValues>) => {
        const {workflowId} = formApi.getState().values;

        const {payload} = await dispatch(createWorkflow({workflowId}));

        if (payload) {
            dispatch(modalCreateDocumentSlice.actions.close());
            return dispatch(updateView());
        }

        return Promise.reject();
    }, []);

    return (
        <YTDFDialog<FormValues>
            visible={state.documentType === 'workflow'}
            headerProps={{title: 'Create workflow'}}
            onClose={handleClose}
            fields={[
                {
                    type: 'text',
                    name: 'workflowId',
                    caption: 'Workflow ID',
                    required: true,
                    extras: {
                        placeholder: 'Please enter name of your workflow',
                    },
                },
            ]}
            onAdd={handleAdd}
        />
    );
};
