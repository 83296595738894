import React from 'react';

import type {HotkeyDescriptor} from '../types';
import {DialogHotkeysPreview} from '../components/DialogHotkeysPreview/DialogHotkeysPreview';
import {useDispatch, useSelector} from 'react-redux';
import {selectHotkeysDialogOpen} from '../store/selectors';
import {hotkeysSlice} from '../store/slice';

export function withDialogBoundHotkeys<T extends {[key: string]: HotkeyDescriptor}>(
    hotkeys: T,
    name: string,
) {
    const preparedHotkeys = Object.values(hotkeys);

    const Component: React.FC = function () {
        const dispatch = useDispatch();
        const open = useSelector(selectHotkeysDialogOpen);

        const handleClose = React.useCallback(() => {
            dispatch(hotkeysSlice.actions.close());
        }, []);

        return <DialogHotkeysPreview onClose={handleClose} open={open} hotkeys={preparedHotkeys} />;
    };

    Component.displayName = `withDialogBoundHotkeys_${name}`;

    return Component;
}
