import {AnyAction} from 'redux';
import {ThunkDispatch} from 'redux-thunk';
import type {TractoRootState} from './reducers';
import {useDispatch} from 'react-redux';

export type TractoThunkDispatch<T extends AnyAction = AnyAction> = ThunkDispatch<
    TractoRootState,
    any,
    T
>;

type ActionWithoutMatch<T> = Omit<T, 'match'>;

export type SliceActionType<T extends {[key: string]: any}> = {
    [K in keyof T]: ActionWithoutMatch<T[K]>;
}[keyof T];

export type TractoSliceThunkDispatch<T extends AnyAction> = ThunkDispatch<TractoRootState, any, T>;

export const useTractoDispatch = <T extends AnyAction>() => useDispatch<TractoThunkDispatch<T>>();
