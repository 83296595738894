import React, {useEffect} from 'react';
import {BlueprintTabComponent} from 'features/ImageBuilder/components/BlueprintTabComponent/BlueprintTabComponent';
import {useTractoDispatch} from '../../../../store/tracto-dispatch';
import {userGetBlueprint} from 'features/ImageBuilder/store/actions/blueprints';
import {blueprintSlice} from 'features/ImageBuilder/store/slices/blueprintSlice';
import {NavigationTabLoader} from 'components/NavigationTabLoader/NavigationTabLoader';
import {useSelector} from 'react-redux';
import {selectIsBlueprintLoaded} from 'features/ImageBuilder/store/selectors/blueprint';

export const BlueprintTabContainer: React.FC = () => {
    const dispatch = useTractoDispatch();
    const isBlueprintLoaded = useSelector(selectIsBlueprintLoaded);

    useEffect(() => {
        dispatch(userGetBlueprint());

        return () => {
            dispatch(blueprintSlice.actions.clearState());
        };
    }, []);

    if (!isBlueprintLoaded) {
        return <NavigationTabLoader />;
    }

    return <BlueprintTabComponent />;
};
