import {NotebookCellType} from '../../../constants/cell';
import {createTractoAsyncThunk} from '../../../../../store/tracto-async-thunk';
import {extractCellId} from '../../../utils/cell/common';
import {notebookSlice} from '../../slices/notebook';
import {batch} from 'react-redux';
import {selectCell, selectDirtyCells, selectEditableCell} from '../../selectors/notebook';
import {getItemStrict} from '../../../utils/strict-selectors';
import {getCellView} from '../../../../../utils/cell';
import {createCell} from './create-cell';
import {isCode} from '@jupyterlab/nbformat';
import {setCellSource} from './cell-source';

type AddNotebookCellPayload = {
    currentIndex: number;
    type: NotebookCellType;
    focus?: boolean;
    editable?: boolean;
};

export const addNotebookCell = createTractoAsyncThunk<void, AddNotebookCellPayload>(
    'jupyter.cell.actions.addNotebookCell',
    ({currentIndex, type, focus, editable}, thunkAPI) => {
        const cell = thunkAPI.dispatch(createCell(type));

        const cellId = extractCellId(cell);

        batch(() => {
            thunkAPI.dispatch(notebookSlice.actions.addCellAfter({currentIndex, cell}));

            if (focus ?? true) {
                thunkAPI.dispatch(notebookSlice.actions.setFocusedCellById({cellId}));
            }
            if (editable ?? true) {
                thunkAPI.dispatch(notebookSlice.actions.makeCellEditable());
            }
        });
    },
);

type ChangeCellTypePayload = {cellId: string; type: NotebookCellType};

export const changeCellType = createTractoAsyncThunk<void, ChangeCellTypePayload>(
    'jupyter.cell.actions.changeCellType',
    ({cellId, type}, thunkAPI) => {
        const state = thunkAPI.getState();

        const prevCell = getItemStrict(selectCell(state, cellId));
        const editableId = selectEditableCell(state);

        const cellView = getCellView(prevCell);

        const newCell = thunkAPI.dispatch(
            createCell(type, {cellId: prevCell.id, source: cellView.view_source}),
        );

        batch(() => {
            if (prevCell.id === editableId) {
                thunkAPI.dispatch(notebookSlice.actions.removeCellEditable());
            }

            thunkAPI.dispatch(notebookSlice.actions.changeCellType({cellId, cell: newCell}));
        });
    },
);

type UpdateCellSourcePayload = {cellId: string; source: string};

export const updateCellSource = createTractoAsyncThunk<void, UpdateCellSourcePayload>(
    'jupyter.cell.actions.updateCellSource',
    (payload, thunkAPI) => {
        const {cellId, source} = payload;

        const state = thunkAPI.getState();

        const cell = getItemStrict(selectCell(state, cellId));
        const dirtyCells = selectDirtyCells(state);

        batch(() => {
            if (isCode(cell) && !dirtyCells[cellId] && cell.source && cell.execution_count) {
                thunkAPI.dispatch(notebookSlice.actions.updateDirtyCells({cell}));
            } else if (dirtyCells[cellId] && dirtyCells[cellId].source === source) {
                thunkAPI.dispatch(notebookSlice.actions.removeDirtyCell({cellId}));
            }

            thunkAPI.dispatch(
                setCellSource(source, {cellId, type: getCellView(cell).view_cell_type}),
            );
        });
    },
);
