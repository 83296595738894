import YT from '@ytsaurus-ui-platform/src/ui/config/yt-config';
import axios from 'axios';
import type {BlueprintType} from './blueprints';

const getRunsBaseUrl = () => `/${YT.cluster}/image-builder/api/v1/runs`;

type RunBuildPayload = {
    blueprint_id: string;
    blueprint: BlueprintType;
    run_params: {
        tag: string;
    };
    builder_params?: {
        cpu_limit: number;
        memory_limit: number;
    };
    blueprint_type: 'yt';
};

type RunNewBuildResponse = {
    run_id: string;
    runner: string;
};

export const runNewBuild = (payload: RunBuildPayload) => {
    return () => {
        return axios
            .post<RunNewBuildResponse>(getRunsBaseUrl(), payload)
            .then((response) => response.data);
    };
};

type GetBuildInfoPayload = {
    run_id: string;
};

type GetBuildInfoResponse = {
    run_id: string;
    runner: string;
    status: string;
};

export const getBuildInfo = ({run_id}: GetBuildInfoPayload) => {
    return (): Promise<GetBuildInfoResponse> => {
        return axios.get(`${getRunsBaseUrl()}/${run_id}`);
    };
};
