import React from 'react';
import block from 'bem-cn-lite';

import '@diplodoc/transform/dist/css/yfm.css';
import '@diplodoc/transform/dist/js/yfm';
import '@ytsaurus-ui-platform/src/ui/components/Markdown/yfm-overrides.scss';
import {TractoMarkdownQa} from '../../../shared/qa/common';

type TractoMarkdownProps = {
    html: string;
    className?: string;
};

const b = block('tracto-markdown');

const TractoMarkdownComponent: React.FC<TractoMarkdownProps> = (props: TractoMarkdownProps) => {
    const {className, html} = props;
    const mixins: string[] = React.useMemo(() => {
        return className ? [className, 'yfm'] : ['yfm'];
    }, [className]);

    return (
        <div
            data-qa={TractoMarkdownQa.Root}
            className={b(null, mixins)}
            dangerouslySetInnerHTML={{__html: html}}
        />
    );
};

export const TractoMarkdown = React.memo(TractoMarkdownComponent);
