import React from 'react';

import {YTTokenInputProps} from '../../../components/YTTokenInput/YTTokenInput';
import {YTTokenInputContainer} from '../../YTTokenInputContainer/YTTokenInputContainer';

type YTTokenInputControlProps = {
    value: string;
    onChange: (value: string) => void;
} & Pick<YTTokenInputProps, 'description'>;

export function YTTokenInputControl(props: YTTokenInputControlProps) {
    return <YTTokenInputContainer {...props} />;
}

YTTokenInputControl.getDefaultValue = () => {
    return '';
};

YTTokenInputControl.isEmpty = (value: YTTokenInputControlProps['value']) => {
    return !value;
};
