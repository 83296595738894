import React from 'react';
import type {ExtraTab} from '@ytsaurus-ui-platform/src/ui/UIFactory';
import {Tab as NavigationTab} from '@ytsaurus-ui-platform/src/ui/constants/navigation/index';
import {getUserAttributeKeys} from '../../utils/attributes-selectors';
import {Icon} from '@gravity-ui/uikit';
import {Factory} from '@gravity-ui/icons';
import type {Node} from '@ytsaurus-ui-platform/src/ui/utils/navigation/content/map-nodes/node';
import {BlueprintTabContainer} from './containers/BlueprintTabContainer/BlueprintTabContainer';

const IS_IMAGE_BUILDER_ATTRIBUTE = 'is_blueprint';

export const IMAGE_BUILDER_BLUEPRINT_NAVIGATION_TAB: ExtraTab = {
    value: `extra_blueprint`,
    text: 'Blueprint',
    title: 'Go to blueprint interface',
    component: BlueprintTabContainer,
    position: {before: NavigationTab.CONTENT},
    isSupported: (attributes) => {
        const keys = getUserAttributeKeys(attributes);

        return keys.has(IS_IMAGE_BUILDER_ATTRIBUTE);
    },
};

export const IMAGE_BUILDER_NAVIGATION_MAP_NODE_SETTINGS = {
    additionalAttributes: [IS_IMAGE_BUILDER_ATTRIBUTE],
    renderNodeIcon: (item: Node) => {
        if (item.$attributes?.[IS_IMAGE_BUILDER_ATTRIBUTE]) {
            return <Icon data={Factory} size={14} />;
        }

        return null;
    },
};
