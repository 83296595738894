import * as React from 'react';
import block from 'bem-cn-lite';
import moment from 'moment/moment';
import {Button, Icon, Label, Table, TableColumnConfig} from '@gravity-ui/uikit';
import {CircleInfo, TrashBin} from '@gravity-ui/icons';
import {ConfirmDialog} from '@gravity-ui/components';
import ClipboardButton from '@ytsaurus-ui-platform/src/ui/components/ClipboardButton/ClipboardButton';
import {TableProps} from '@gravity-ui/uikit/build/esm/components/Table/Table';

import {getTagsTableData} from '../../store/selectors/repositories';

const b = block('tracto-registry-tags-list');

type Col = ReturnType<typeof getTagsTableData>[number];

interface TractoRegistryTagsListProps {
    tags: Col[];
    tractoRegistryUrl: string;
    repository: string;
    onRowClick: TableProps<Col>['onRowClick'];
    onDeleteImage: (tag: string) => void;
}

const ConfirmDeleteImageDialog: React.FC<{onConfirm: () => void}> = (props) => {
    const [open, setOpen] = React.useState(false);

    return (
        <span onClick={(e) => e.stopPropagation()}>
            <Button
                size="s"
                view="flat"
                onClick={() => {
                    setOpen(true);
                }}
            >
                <Icon data={TrashBin} />
            </Button>
            <ConfirmDialog
                title="Delete Docker image"
                message="You are deleting the Docker image"
                textButtonApply="Delete"
                textButtonCancel="Cancel"
                onClickButtonApply={() => {
                    props.onConfirm();
                    setOpen(false);
                }}
                onClickButtonCancel={() => {
                    setOpen(false);
                }}
                onClose={() => setOpen(false)}
                open={open}
            />
        </span>
    );
};

export const TractoRegistryTagsList: React.FC<TractoRegistryTagsListProps> = ({
    tags,
    tractoRegistryUrl,
    repository,
    onRowClick,
    onDeleteImage,
}) => {
    const columns: TableColumnConfig<Col>[] = [
        {
            id: 'tag',
            name: 'Tag',
            template: (item) => {
                return (
                    <div onClick={(event) => event.stopPropagation()}>
                        <Label
                            type="copy"
                            theme="normal"
                            className={b('row-label')}
                            copyText={`${tractoRegistryUrl}/${repository}:${item.tag}`}
                        >
                            {item.tag}
                        </Label>
                    </div>
                );
            },
        },
        {
            id: 'created',
            name: 'Date created',
            template: (item) => {
                return moment(item.created).format('DD/MM/YYYY HH:mm:ss');
            },
        },
        {
            id: 'size',
            name: 'Size',
        },
        {
            id: 'sha256',
            name: 'Content Digest',
        },
        {
            id: 'architecture',
            name: 'Arch',
        },
        {
            id: 'details',
            name: '',
            template: (item) => {
                return (
                    <>
                        <Button size="s" view="flat">
                            <Icon data={CircleInfo} />
                        </Button>
                        <ConfirmDeleteImageDialog
                            onConfirm={() => {
                                onDeleteImage(item.tag);
                            }}
                        />
                    </>
                );
            },
        },
    ];

    return (
        <div className={b()}>
            <h2>
                {repository}
                &nbsp;
                <ClipboardButton
                    view={'flat-secondary'}
                    text={`${tractoRegistryUrl}/${repository}`}
                />
            </h2>
            <h3>Tags</h3>
            <div>
                <Table data={tags} columns={columns} onRowClick={onRowClick} />
            </div>
        </div>
    );
};
