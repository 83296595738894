import {WorkflowDocumentType, WorkflowStepType} from 'features/Orchestracto/types/workflow';
import {createSelector} from 'reselect';
import {TractoRootState} from 'store/reducers';
import {getTractoClusterUiConfig} from '../../../../store/selectors/tracto-cluster-ui-config';

export const selectWorkflow = (state: TractoRootState) =>
    state.tracto.orchestracto.workflow.workflow;

export const selectParsedWorkflow = createSelector([selectWorkflow], (workflow) => {
    if (!workflow.trim()) {
        return null;
    }

    try {
        return JSON.parse(workflow) as unknown as WorkflowDocumentType;
    } catch {
        return null;
    }
});

export const selectStepsMap = createSelector([selectParsedWorkflow], (parsedWorkflow) => {
    let stepsMap: Record<string, WorkflowStepType> = {};

    if (parsedWorkflow) {
        stepsMap = parsedWorkflow.steps.reduce((acc: Record<string, WorkflowStepType>, step) => {
            acc[step.step_id] = step;
            return acc;
        }, {});
    }

    return stepsMap;
});

const selectMarkers = (state: TractoRootState) => state.tracto.orchestracto.workflow.markers;

export const selectIsWorkflowValid = createSelector(
    [selectWorkflow, selectMarkers],
    (workflow, markers) => {
        const isNotEmpty = workflow.trim() !== '';
        const hasNoMarkers = markers.length === 0;

        return isNotEmpty && hasNoMarkers;
    },
);

export const selectIsWorkflowChanged = (state: TractoRootState) =>
    state.tracto.orchestracto.workflow.workflow !==
    state.tracto.orchestracto.workflow.initialWorkflow;

export const selectIsWorkflowLoaded = (state: TractoRootState) =>
    state.tracto.orchestracto.workflow.isWorkflowLoaded;

export const selectIsWorkflowRunning = (state: TractoRootState) =>
    state.tracto.orchestracto.workflow.running;

export const isWorkflowAPIEnabled = createSelector(getTractoClusterUiConfig, (config) => {
    return Boolean(config.orc_base_url);
});
