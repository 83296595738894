import {
    type Kernel,
    KernelManager,
    ServerConnection,
    type Session,
    type SessionManager,
} from '@jupyterlab/services';
import {TractoLogger} from '../../../../utils/logging';
import {Deferred} from '../../../../utils/deferred';
import {
    createSession,
    getOptionalSessionManager,
    getSessionManager,
    resetSessionManager,
} from './sessions';
import {delay} from '@ytsaurus-ui-platform/src/ui/packages/delays';
import {getJupyterApiServerSettings} from '../../utils/api';

export class JupyterApi {
    private static statusChangedHandler:
        | ((connection: Kernel.IKernelConnection, status: Kernel.Status) => void)
        | null = null;
    private static kernelManager: KernelManager | null = null;
    private static sessionConnection: Deferred<Session.ISessionConnection> =
        new Deferred<Session.ISessionConnection>();
    private static isReconnecting = false;

    static async setupKernelConnection(args: {
        alias: string;
        cluster: string;
        path: string;
        notebookId: string;
        onKernelStatusChange: (_: Kernel.IKernelConnection, status: Kernel.Status) => void;
    }) {
        const {alias, cluster, notebookId, path, onKernelStatusChange} = args;

        if (!this.kernelManager) {
            this.kernelManager = await this.createKernelManager({alias, cluster, path});
        }

        TractoLogger.log('Starting kernel connection.');

        const sessionManager = getSessionManager(this.kernelManager, {alias, cluster, path});

        await this.createSessionConnection(sessionManager, {
            path,
            notebookId,
            onKernelStatusChange,
        });
    }

    static disposeConnection() {
        const sessionManager = getOptionalSessionManager();

        if (!sessionManager) {
            TractoLogger.log('Session manager is not initialized...');
            TractoLogger.log('Sessions do not exist');
            return;
        }

        this.isReconnecting = false;

        sessionManager.dispose();
        this.kernelManager?.dispose();

        this.sessionConnection = new Deferred<Session.ISessionConnection>();
        this.kernelManager = null;
        resetSessionManager();
    }

    static getKernelConnection() {
        return this.sessionConnection.promise.then((connection) => connection.kernel!);
    }

    static getSessionConnection() {
        return this.sessionConnection.promise;
    }

    static async getCompletion(
        source: string,
        cursor: number,
        {alias, cluster, path}: {alias: string; cluster: string; path: string},
    ) {
        if (!this.kernelManager) {
            this.kernelManager = await this.createKernelManager({alias, cluster, path});
        }

        const kernelConnection = await this.getKernelConnection();

        return kernelConnection.requestComplete({
            code: source,
            cursor_pos: cursor,
        });
    }

    static async restartKernel() {
        const kernelConnection = await this.getKernelConnection();
        await kernelConnection.restart();
    }

    private static async createKernelManager({
        alias,
        cluster,
        path,
    }: {
        alias: string;
        cluster: string;
        path: string;
    }) {
        TractoLogger.log('Trying to create KernelManager...');

        const serverSettings = getJupyterApiServerSettings({alias, cluster, path});

        const MAX_CONNECT_ATTEMPTS = 5;

        for (let connectAttempt = 0; connectAttempt < MAX_CONNECT_ATTEMPTS; connectAttempt++) {
            TractoLogger.log(`Trying to get api/status... ${connectAttempt + 1}`);

            const kernel = await ServerConnection.makeRequest(
                `${serverSettings.baseUrl}/api/status`,
                {},
                serverSettings,
            );

            if (kernel.ok) {
                TractoLogger.log(`Status is ok!`);

                return new KernelManager({
                    serverSettings,
                });
            }

            await delay(5 * 1000);
        }

        TractoLogger.log(`Didn't get api/status for ${MAX_CONNECT_ATTEMPTS} times`);

        throw new Error(`Can't connect to kernel`);
    }

    private static async reconnect(
        sessionManager: SessionManager,
        session: Session.ISessionConnection,
        {
            path,
            notebookId,
            onKernelStatusChange,
        }: {
            path: string;
            notebookId: string;
            onKernelStatusChange: (_: Kernel.IKernelConnection, status: Kernel.Status) => void;
        },
    ) {
        TractoLogger.log('Start kernel reconnecting...');

        this.isReconnecting = true;

        this.sessionConnection = new Deferred<Session.ISessionConnection>();

        // eslint-disable-next-line no-constant-condition
        while (this.isReconnecting) {
            try {
                await this.createSessionConnection(sessionManager, {
                    path,
                    notebookId,
                    onKernelStatusChange,
                });

                TractoLogger.log('Dispose prev session and resources');
                if (this.statusChangedHandler && session.kernel) {
                    session.kernel.statusChanged.disconnect(this.statusChangedHandler);
                }

                session.kernel?.dispose();
                session.dispose();
                session.kernel?.interrupt();

                TractoLogger.log('Reconnect is done successfully.');

                this.isReconnecting = false;
            } catch (error) {
                TractoLogger.log('Failed to reconnect. Retrying in 5 seconds...', error);
                await delay(5 * 1000);
            }
        }
    }

    private static async createSessionConnection(
        sessionManager: SessionManager,
        {
            path,
            notebookId,
            onKernelStatusChange,
        }: {
            path: string;
            notebookId: string;
            onKernelStatusChange: (
                connection: Kernel.IKernelConnection,
                status: Kernel.Status,
            ) => void;
        },
    ) {
        const result = await createSession(sessionManager, {path, notebookId});

        TractoLogger.log('Session connection created');
        TractoLogger.log('SessionConnection ID: ', result.id);
        TractoLogger.log('KernelConnection ID: ', result.kernel?.id);

        if (!result.kernel) {
            TractoLogger.log("KernelConnection doesn't exist, something went wrong");
            throw new Error("KernelConnection doesn't exist!");
        }

        TractoLogger.log('KernelConnection successfully started');

        const reconnectListener = this.getReconnectListener(sessionManager, result, {
            path,
            notebookId,
            onKernelStatusChange,
        });

        this.attachListeners(result.kernel, [onKernelStatusChange, reconnectListener]);

        this.sessionConnection.resolve(result);
    }

    private static getReconnectListener(
        sessionManager: SessionManager,
        session: Session.ISessionConnection,
        {
            path,
            notebookId,
            onKernelStatusChange,
        }: {
            path: string;
            notebookId: string;
            onKernelStatusChange: (_: Kernel.IKernelConnection, status: Kernel.Status) => void;
        },
    ) {
        return (_connection: Kernel.IKernelConnection, status: Kernel.Status) => {
            if (status === 'dead') {
                this.reconnect(sessionManager, session, {path, notebookId, onKernelStatusChange});
            }
        };
    }

    private static attachListeners(
        kernel: Kernel.IKernelConnection,
        listeners: Array<(connection: Kernel.IKernelConnection, status: Kernel.Status) => void>,
    ) {
        if (this.statusChangedHandler) {
            kernel.statusChanged.disconnect(this.statusChangedHandler);
        }

        kernel.statusChanged.connect(this.getStatusChangedListener(listeners));
    }

    private static getStatusChangedListener(
        listeners: Array<(connection: Kernel.IKernelConnection, status: Kernel.Status) => void>,
    ) {
        const handler = (connection: Kernel.IKernelConnection, status: Kernel.Status) => {
            listeners.forEach((fn) => fn(connection, status));
        };

        this.statusChangedHandler = handler;

        return handler;
    }
}
