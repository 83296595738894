import React from 'react';
import {Flex} from '@gravity-ui/uikit';
import {BlueprintEditorContainer} from 'features/ImageBuilder/containers/BlueprintEditorContainer/BlueprintEditorContainer';
import block from 'bem-cn-lite';
import './BlueprintTabComponent.scss';
import {BlueprintAttachedFilesComponent} from '../BlueprintAttachedFilesComponent/BlueprintAttachedFilesComponent';
import {
    BlueprintMainImageParamsContainer,
    BlueprintSecondaryImageParamsContainer,
} from 'features/ImageBuilder/containers/BlueprintImageParamsContainer/BlueprintImageParamsContainer';
import {NewBuildDialogContainer} from 'features/ImageBuilder/containers/NewBuildDialogContainer/NewBuildDialogContainer';
import {BluePrintCrossLinksContainer} from 'features/ImageBuilder/containers/BluePrintCrossLinksContainer/BluePrintCrossLinksContainer';
import WithStickyToolbar from '@ytsaurus-ui-platform/src/ui/components/WithStickyToolbar/WithStickyToolbar';
import {BlueprintTabToolbarContainer} from 'features/ImageBuilder/containers/BlueprintTabToolbarContainer/BlueprintTabToolbarContainer';
import CollapsibleSection from '@ytsaurus-ui-platform/src/ui/components/CollapsibleSection/CollapsibleSection';

const b = block('blueprint-tab');

export const BlueprintTabComponent: React.FC = () => {
    const toolbar = <BlueprintTabToolbarContainer />;

    const content = (
        <Flex direction="column" className={b()}>
            <Flex direction="row" width="100%" justifyContent="space-between" gap={8}>
                <Flex width="50%" direction="column">
                    <BlueprintMainImageParamsContainer />
                    <CollapsibleSection name="Additional settings" size="m" collapsed>
                        <BlueprintSecondaryImageParamsContainer />
                        <BlueprintAttachedFilesComponent className={b('section')} />
                    </CollapsibleSection>
                </Flex>
                <Flex width="50%" direction="column">
                    <BluePrintCrossLinksContainer />
                </Flex>
            </Flex>
            <BlueprintEditorContainer className={b('section')} />
            <NewBuildDialogContainer />
        </Flex>
    );
    return <WithStickyToolbar toolbar={toolbar} content={content} />;
};
