import type {HotkeyDescriptor} from '../../../libs/hotkeys';

export const JUPYTER_HOTKEYS = {
    insertAbove: {
        key: 'a',
        description: 'Insert a cell above selected cell',
        command: 'Add cell above',
    },
    insertBelow: {
        key: 'b',
        description: 'Insert a cell below selected cell',
        command: 'Add cell below',
    },
    copy: {
        key: 'c',
        description: 'Copy selected cell',
    },
    cut: {
        key: 'x',
        description: 'Cut selected cell',
    },
    paste: {
        key: 'v',
        description: 'Paste cell',
    },
    delete: {
        key: 'Double D',
        description: 'Delete selected cell',
        command: 'Delete cell',
    },
    run: {
        key: 'Shift+Enter',
        description: 'Run selected cell',
        command: 'Run',
    },
    save: {
        key: 'mod+S',
        description: 'Save notebook',
    },
    comment: {
        key: 'mod+/',
        description: 'Comment',
    },
    escape: {
        key: 'ESC',
        description: 'Exit from Edit mode',
        platform: 'pc',
    },
    arrowUp: {
        key: '↑',
        description: 'Move focus to upper cell',
    },
    arrowDown: {
        key: '↓',
        description: 'Move focus to lower cell',
    },
    showPalette: {
        key: 'Double Shift / mod+P',
        description: 'Show command palette',
    },
    showHelp: {
        key: 'Shift+?',
        description: 'Show shortcuts help',
        command: 'Show help',
    },
} as const satisfies {[key: string]: HotkeyDescriptor};
