import {getCluster} from '@ytsaurus-ui-platform/src/ui/store/selectors/global';
import {Delegation} from 'features/SecretStore/types/delegations';
import {TractoThunkDispatch} from '../../../../store/tracto-dispatch';
import {TractoRootState} from 'store/reducers';
import axios from 'axios';
import {getPath} from '@ytsaurus-ui-platform/src/ui/store/selectors/navigation';
import {delegationsSlice} from '../slices/delegationsSlice';

export const loadDelegationsFromCypress = () => {
    return async (dispatch: TractoThunkDispatch, getState: () => TractoRootState) => {
        const secret_path = getPath(getState());
        const delegations = await dispatch(listDelegations({secret_path}));
        dispatch(
            delegationsSlice.actions.setDelegations({
                delegations,
            }),
        );
    };
};

type ListDelegationsResponse = {
    result: Delegation[];
};

const listDelegations = ({secret_path}: {secret_path: string}) => {
    return (
        _dispatch: TractoThunkDispatch,
        getState: () => TractoRootState,
    ): Promise<Delegation[]> => {
        const cluster = getCluster(getState());

        return axios
            .get(`/${cluster}/secret-service/api/v1/list_delegations`, {
                params: {
                    secret_path,
                },
            })
            .then((response) => (response.data as ListDelegationsResponse).result);
    };
};

type CreateDelegationPayload = {
    secret_path: string;
    consumer_type: string;
    consumer_identity: string;
};

export const createDelegation = ({
    secret_path,
    consumer_type,
    consumer_identity,
}: CreateDelegationPayload) => {
    return (
        _dispatch: TractoThunkDispatch,
        getState: () => TractoRootState,
    ): Promise<ListDelegationsResponse> => {
        const cluster = getCluster(getState());

        return axios.post(`/${cluster}/secret-service/api/v1/create_delegation`, {
            secret_path,
            consumer_type,
            consumer_identity,
        });
    };
};

type RemoveDelegationPayload = {
    delegation_id: string;
};

export const removeDelegation = ({delegation_id}: RemoveDelegationPayload) => {
    return (
        _dispatch: TractoThunkDispatch,
        getState: () => TractoRootState,
    ): Promise<ListDelegationsResponse> => {
        const cluster = getCluster(getState());

        return axios.post(`/${cluster}/secret-service/api/v1/remove_delegation`, {
            delegation_id,
        });
    };
};
