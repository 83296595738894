import {YTApiId, ytApiV4Id} from '@ytsaurus-ui-platform/src/ui/rum/rum-wrap-api';
import type {GetSysUserAttributeData} from '../../../shared/types/yt-api/v4/users';
import {getTractoConfigData} from '../../config/ui-config';

const getSysUserAttributeData = (login: string, attribute: string) => {
    return ytApiV4Id.get<GetSysUserAttributeData>(YTApiId.usersData, {
        path: `//sys/users/${login}/${attribute}`,
    });
};

export const getSysUserData = async (login: string) => {
    const settings = getTractoConfigData().uiSettings;

    return settings?.userDataAttribute
        ? await getSysUserAttributeData(login, settings.userDataAttribute)
        : {value: null};
};
