const presets = [
    {from: 'now-5m', to: 'now', title: 'Last 5 minutes'},
    {from: 'now-15m', to: 'now', title: 'Last 15 minutes'},
    {from: 'now-30m', to: 'now', title: 'Last 30 minutes'},
    {from: 'now-1h', to: 'now', title: 'Last hour'},
    {from: 'now-3h', to: 'now', title: 'Last 3 hours'},
    {from: 'now-6h', to: 'now', title: 'Last 6 hours'},
    {from: 'now-12h', to: 'now', title: 'Last 12 hours'},
    {from: 'now-1d', to: 'now', title: 'Last day'},
    {from: 'now-7d', to: 'now', title: 'Last week'},
];

export const RUNS_FILTER_PRESETS = [
    {
        id: 'main',
        title: 'Time',
        presets,
    },
];

export const RUNS_FILTER_UI_FORMAT = 'DD/MM/YYYY HH:mm:ss';

export const RUNS_FILTER_ORC_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
