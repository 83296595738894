import React, {useCallback} from 'react';

import {useTractoDispatch} from '../../../../store/tracto-dispatch';
import {AddJupyterCellButton} from 'features/Jupyter/components/AddJupyterCellButton/AddJupyterCellButton';
import {addNotebookCell} from '../../store/actions/notebook/cell';
import {NotebookCellType} from '../../constants/cell';

type AddJupyterCellConainerProps = {addIndex: number; visible?: boolean; qa?: string};

export const AddJupyterCellButtonContainer = ({addIndex, qa}: AddJupyterCellConainerProps) => {
    const dispatch = useTractoDispatch();

    const onClick = useCallback(() => {
        dispatch(addNotebookCell({currentIndex: addIndex, type: NotebookCellType.CODE}));
    }, [addIndex]);

    return <AddJupyterCellButton onClick={onClick} qa={qa} />;
};
