import React from 'react';
import {
    Button,
    Flex,
    Icon,
    type SelectOption,
    type SelectRenderControlProps,
    Text,
} from '@gravity-ui/uikit';
import block from 'bem-cn-lite';
import {ChevronDown, ChevronUp} from '@gravity-ui/icons';
import type {JupytSelectorOptionData} from '../types';
import {NotebookPageQa} from '../../../../../../shared/qa';
import {JupytSelectOption} from '../JupytSelectOption/JupytSelectOption';
import {getJupytStatus} from '../helpers';

import './JupytSelectControl.scss';

type JupytSelectControlProps = {
    selectedItem: SelectOption<JupytSelectorOptionData> | undefined;
} & SelectRenderControlProps;

const b = block('jupyt-select-control');

export const JupytSelectControl: React.FC<JupytSelectControlProps> = (
    props: JupytSelectControlProps,
) => {
    const {selectedItem} = props;

    return selectedItem ? (
        <Button
            {...props}
            qa={NotebookPageQa.KernelSelect}
            onClick={props.onClick}
            className={b('control')}
            view="outlined"
            width="max"
        >
            <Flex alignItems="center" justifyContent={'spa'} className={b('option')}>
                <JupytSelectOption
                    qa={NotebookPageQa.KernelSelectText}
                    value={selectedItem.value}
                    suspended={selectedItem.data?.suspended}
                    status={getJupytStatus(selectedItem.data)}
                />
                <Icon className={b('icon')} data={props.open ? ChevronUp : ChevronDown} />
            </Flex>
        </Button>
    ) : (
        <Button {...props} qa={NotebookPageQa.KernelSelect} onClick={props.onClick} view="outlined">
            <Text variant="body-1">Select kernel</Text>
        </Button>
    );
};
