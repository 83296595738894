import React, {useCallback, useState} from 'react';
import {Icon, TextArea, TextInput} from '@gravity-ui/uikit';
import Button from '@ytsaurus-ui-platform/src/ui/components/Button/Button';
import {Eye, EyeSlash, Xmark} from '@gravity-ui/icons';
import block from 'bem-cn-lite';
import {produce} from 'immer';

import './SecretsList.scss';

const b = block('secrets-list');

type SecretsListProps = {
    value: SecretListItem[];
    onUpdate?: (value: SecretListItem[]) => void;
    isReadonly?: boolean;
};

export type SecretListItem = {
    name: string;
    value: string;
    nameError?: string;
    new?: boolean;
};

export function SecretsList({
    value: secretsList,
    onUpdate = () => {},
    isReadonly,
}: SecretsListProps) {
    const onAddSecretClick = useCallback(() => {
        onUpdate(secretsList.concat({name: '', value: '', new: true}));
    }, [secretsList]);

    return (
        <div className={b()}>
            {secretsList.map(
                (
                    {value: secretValue, name: secretName, nameError: nameErrorMessage},
                    secretIndex,
                ) => {
                    const onNameUpdate = (newSecretName: string) => {
                        onUpdate(
                            produce(secretsList, (draft) => {
                                draft[secretIndex].name = newSecretName;

                                let nameError = '';

                                if (secretsList.some((item) => item.name === newSecretName)) {
                                    nameError = 'This secret name already in use';
                                }

                                if (!newSecretName) {
                                    nameError = 'Secret name should not be empty';
                                }

                                draft[secretIndex].nameError = nameError;
                            }),
                        );
                    };

                    return (
                        <SecretComponent
                            name={secretName}
                            nameErrorMessage={nameErrorMessage}
                            value={secretValue}
                            onNameUpdate={onNameUpdate}
                            onValueUpdate={
                                isReadonly
                                    ? undefined
                                    : (newSecretValue) => {
                                          onUpdate(
                                              produce(secretsList, (draft) => {
                                                  draft[secretIndex].value = newSecretValue;
                                              }),
                                          );
                                      }
                            }
                            onRemove={() => {
                                onUpdate(secretsList.filter((_, index) => index !== secretIndex));
                            }}
                            valueHidden={Boolean(secretValue)}
                            isReadonly={isReadonly}
                        />
                    );
                },
            )}
            {isReadonly ? null : (
                <div className={b('add-field-row', {'no-secrets': !secretsList.length})}>
                    <Button className={b('add-field-button')} onClick={onAddSecretClick}>
                        Add secret
                    </Button>
                </div>
            )}
        </div>
    );
}

type SecretsComponentProps = {
    name: string;
    value: string;
    onNameUpdate?: (value: string) => void;
    onValueUpdate?: (value: string) => void;
    onRemove: () => void;
    valueHidden?: boolean;
    isReadonly?: boolean;
    nameErrorMessage?: string;
};

function SecretComponent({
    name,
    value,
    onNameUpdate,
    onValueUpdate,
    onRemove,
    valueHidden = true,
    isReadonly,
    nameErrorMessage,
}: SecretsComponentProps) {
    const [isValueHidden, setIsValueHidden] = useState(valueHidden);

    const onRevealClick = useCallback(() => {
        setIsValueHidden(!isValueHidden);
    }, [setIsValueHidden, isValueHidden]);

    return (
        <div className={b('secret-entry-container')}>
            <div className={b('secret-entry-row')}>
                <div className={b('secret-entry-left')}>Name:</div>
                <div className={b('secret-entry-right')}>
                    <TextInput
                        value={name}
                        placeholder="Secret name"
                        onUpdate={onNameUpdate}
                        errorMessage={nameErrorMessage}
                        validationState={nameErrorMessage ? 'invalid' : undefined}
                    />
                </div>
                <div className={b('secret-entry-actions')}>
                    {isReadonly ? null : (
                        <Button
                            view="flat"
                            size="s"
                            onClick={onRemove}
                            className={b('reveal-button')}
                        >
                            <Icon data={Xmark} size={14} />
                        </Button>
                    )}
                </div>
            </div>
            <div className={b('secret-entry-row')}>
                <div className={b('secret-entry-left')}>Value:</div>
                <div className={b('secret-entry-right')}>
                    {isValueHidden ? (
                        <TextArea rows={3} disabled placeholder="*****" value="" />
                    ) : (
                        <TextArea
                            rows={3}
                            value={value}
                            placeholder="Secret value"
                            onUpdate={onValueUpdate}
                        />
                    )}
                </div>
                <div className={b('secret-entry-actions')}>
                    <Button
                        view="flat"
                        size="s"
                        onClick={onRevealClick}
                        className={b('reveal-button')}
                    >
                        <Icon data={isValueHidden ? Eye : EyeSlash} size={14} />
                    </Button>
                </div>
            </div>
        </div>
    );
}
