import React from 'react';
import {produce} from 'immer';
import {ExtraTab, UIFactoryClusterPageInfo} from '@ytsaurus-ui-platform/src/ui/UIFactory';
import {JupyterView} from 'features/Jupyter/containers/JupyterView/JupyterView';
import {Tab as NavigationTab} from '@ytsaurus-ui-platform/src/ui/constants/navigation/index';
import type {Node} from '@ytsaurus-ui-platform/src/ui/utils/navigation/content/map-nodes/node';
import {getUserAttributeKeys} from '../../utils/attributes-selectors';
import {getTractoConfigData} from 'config/ui-config';
import {isEnvTrue} from '../../../shared/utils/env';
import {IS_JUPYTER_NOTEBOOK, JUPYT_OPERATIONS_PAGE_ID} from 'features/Jupyter/constants';
import JupytPage from './containers/JupytPage/JupytPage';
import {JupytListPageTopRowContainer} from './containers/JupytListPageTopRowContainer/JupytListPageTopRowContainer';
import {Icon} from '@gravity-ui/uikit';
import {LogoPython} from '@gravity-ui/icons';
import type {RootState} from '@ytsaurus-ui-platform/src/ui/store/reducers';
import type {TractoRootState} from '../../store/reducers';

export const JUPYTER_NOTEBOOK_NAVIGATION_TAB: ExtraTab = {
    value: `extra_notebook`,
    text: 'Notebook',
    title: 'Go to notebook interface',
    component: JupyterView,
    position: {before: NavigationTab.CONTENT},
    isSupported: (attributes) => {
        const keys = getUserAttributeKeys(attributes);

        return (
            keys.has(IS_JUPYTER_NOTEBOOK) &&
            isEnvTrue(getTractoConfigData().env.SHOW_JUPYT_INTERFACE)
        );
    },
};

export function getKernelsPreparedState(_state: RootState, {query: _query}: {query: RootState}) {
    const state = _state as TractoRootState;
    const query = _query as TractoRootState;

    return produce(state, (draft) => {
        if (
            draft.tracto.jupyter.jupyt.searchParams.creator !==
            query.tracto.jupyter.jupyt.searchParams.creator
        ) {
            draft.tracto.jupyter.jupyt.searchParams.creator =
                query.tracto.jupyter.jupyt.searchParams.creator;
        }

        if (
            draft.tracto.jupyter.jupyt.searchParams.state !==
            query.tracto.jupyter.jupyt.searchParams.state
        ) {
            draft.tracto.jupyter.jupyt.searchParams.state =
                query.tracto.jupyter.jupyt.searchParams.state;
        }

        if (
            draft.tracto.jupyter.jupyt.searchParams.health !==
            query.tracto.jupyter.jupyt.searchParams.health
        ) {
            draft.tracto.jupyter.jupyt.searchParams.health =
                query.tracto.jupyter.jupyt.searchParams.health;
        }
    });
}

export const JUPYT_OPERATIONS_PAGE: UIFactoryClusterPageInfo = {
    title: 'Kernels',
    pageId: JUPYT_OPERATIONS_PAGE_ID,
    svgIcon: LogoPython,
    reactComponent: JupytPage,
    topRowComponent: JupytListPageTopRowContainer,
    urlMapping: {
        [`/*/kernels`]: [
            {
                creator: {
                    stateKey: 'tracto.jupyter.jupyt.searchParams.creator',
                },
                state: {
                    stateKey: 'tracto.jupyter.jupyt.searchParams.state',
                },
                health: {
                    stateKey: 'tracto.jupyter.jupyt.searchParams.health',
                },
            },
            getKernelsPreparedState,
        ],
    },
};

export const JUPYTER_NAVIGATION_MAP_NODE_SETTINGS = {
    additionalAttributes: [IS_JUPYTER_NOTEBOOK],
    renderNodeIcon: (item: Node) => {
        if (item.$attributes?.[IS_JUPYTER_NOTEBOOK]) {
            return <Icon data={LogoPython} size={14} />;
        }

        return null;
    },
};
