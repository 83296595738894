import React from 'react';
import block from 'bem-cn-lite';

import './FilePicker.scss';

const b = block('tracto-file-picker');

interface Props {
    className?: string;
    onChange: (v: FileList | null) => void;
    multiple?: boolean;
    children: React.ReactNode;
    qa?: string;
}

export class FilePicker extends React.Component<Props> {
    inputRef = React.createRef<HTMLInputElement>();

    onClick = () => {
        if (!this.inputRef.current) {
            return;
        }

        this.inputRef.current.click();
    };

    onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.props.onChange(event.target.files);
    };

    render() {
        const {children, multiple, className, qa} = this.props;
        return (
            <div data-qa={qa} onClick={this.onClick} className={b(null, className)}>
                {children}
                <input
                    ref={this.inputRef}
                    className={b('input')}
                    onChange={this.onChange}
                    type={'file'}
                    multiple={multiple}
                />
            </div>
        );
    }
}
