import type {ExternalTractoNotebookContent, TractoNotebookContent} from '../../types/version';
import {JupyterNotebookUIConfigVersion} from '../../constants/version';
import {mapV0NotebookContentToV1} from './v1';
import {mapV1NotebookContentToV2} from './v2';

const fixVersion = (notebook: ExternalTractoNotebookContent): ExternalTractoNotebookContent => {
    // this fix needs only for v0,v1 version because v2 and greater already has version in tracto_notebook_content_version
    if (typeof notebook.tracto_notebook_content_version === 'undefined') {
        if (notebook.metadata.tracto) {
            notebook.tracto_notebook_content_version = JupyterNotebookUIConfigVersion.V1;
        } else {
            notebook.tracto_notebook_content_version = JupyterNotebookUIConfigVersion.V0;
        }
    }

    return notebook;
};

export const mapNotebookToLatestVersion = (
    externalNotebook: ExternalTractoNotebookContent,
): TractoNotebookContent => {
    let notebook = fixVersion(externalNotebook);

    if (notebook.tracto_notebook_content_version === JupyterNotebookUIConfigVersion.V0) {
        notebook = mapV0NotebookContentToV1(notebook);
    }

    if (notebook.tracto_notebook_content_version === JupyterNotebookUIConfigVersion.V1) {
        notebook = mapV1NotebookContentToV2(notebook);
    }

    return notebook as TractoNotebookContent;
};
