import React, {useCallback, useMemo} from 'react';
import {useSelector} from 'react-redux';
import {
    selectCurrentJupytOperation,
    selectJupytOperations,
    selectJupytUserOperations,
} from '../../store/selectors/jupyt';
import {
    clearJupytAlias,
    loadNotebookJupytList,
    setCurrentJupytAlias,
    setInitialJupytAlias,
} from '../../store/actions/jupyt/jupyt';
import type {JupytListResponseItem} from '../../api/jupyt';
import {Button, Flex, type SelectOption} from '@gravity-ui/uikit';
import type {JupytOwnerType, JupytSelectorOptionData} from '../../components/JupytSelect/types';
import {JupytSelect, type JupytSelectorProps} from '../../components/JupytSelect/JupytSelect';
import {TractoYT} from '../../../../config/ui-config';
import {getCurrentUserName} from '@ytsaurus-ui-platform/src/ui/store/selectors/global';
import {asyncOpenCreateJupytDialog} from '../../store/actions/modals/modal-create-jupyt';
import {useTractoDispatch} from '../../../../store/tracto-dispatch';

const selectifyJupytItem = (
    item: JupytListResponseItem,
    username: string,
): SelectOption<JupytSelectorOptionData> => {
    return {
        content: item.$value,
        value: item.$value,
        data: {
            state: item.$attributes?.state,
            health: item.$attributes?.health,
            suspended: item.$attributes?.yt_operation_suspended,
            ownerType: item.$attributes?.creator === username ? 'user' : 'all',
        },
    };
};

const getDefaultOwnerType = (
    jupyt: JupytListResponseItem | undefined,
    username: string,
): JupytOwnerType => {
    if (!jupyt) {
        return 'user';
    }

    return jupyt.$attributes?.creator === username ? 'user' : 'all';
};

const useJupytSelect = () => {
    const dispatch = useTractoDispatch();

    const operations = useSelector(selectJupytOperations);
    const userOperations = useSelector(selectJupytUserOperations);
    const currentJupyt = useSelector(selectCurrentJupytOperation);
    const username = useSelector(getCurrentUserName);

    const onUpdate = useCallback(([alias]: string[]) => {
        dispatch(setCurrentJupytAlias({alias}));
    }, []);

    const jupyts = useMemo(() => {
        const preparedJupyts = operations.map((item) => selectifyJupytItem(item, username));

        if (TractoYT.devJupytConfig) {
            return preparedJupyts.concat(
                Object.keys(TractoYT.devJupytConfig ?? {}).map((item) => ({
                    value: item,
                    content: item,
                    data: {dev: true, ownerType: 'user'},
                })),
            );
        }

        return preparedJupyts;
    }, [username, operations]);

    const renderExtraPopupContent = useCallback(
        ({
            renderList,
            ownerType,
        }: {
            renderList: () => React.JSX.Element;
            ownerType: JupytOwnerType;
        }) => {
            if (!userOperations.length && ownerType === 'user') {
                return (
                    <Flex justifyContent="center" alignItems="center" height="150px">
                        <Button
                            view="action"
                            onClick={async () => {
                                const result = await dispatch(asyncOpenCreateJupytDialog());

                                if (!result.payload) {
                                    return null;
                                }

                                dispatch(clearJupytAlias());
                                await dispatch(loadNotebookJupytList());
                                dispatch(setInitialJupytAlias());

                                return null;
                            }}
                        >
                            Create kernel
                        </Button>
                    </Flex>
                );
            }

            return renderList();
        },
        [userOperations, currentJupyt],
    );

    return {
        jupyts,
        onUpdate,
        currentAlias: currentJupyt?.$value ? [currentJupyt?.$value] : [],
        defaultJupytOwnerType: getDefaultOwnerType(currentJupyt, username),
        renderExtraPopupContent,
    } satisfies JupytSelectorProps;
};

export const JupytSelectContainer: React.FC = () => {
    return <JupytSelect {...useJupytSelect()} />;
};
