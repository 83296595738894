import {SecretListItem, SecretsList} from 'components/SecretsList/SecretsList';
import React, {ComponentProps} from 'react';
import Button from '@ytsaurus-ui-platform/src/ui/components/Button/Button';
import block from 'bem-cn-lite';
import './SecretStoreTabComponent.scss';
import FlexSplitPane from '@ytsaurus-ui-platform/src/ui/components/FlexSplitPane/FlexSplitPane';
import {DelegationsListContainer} from 'features/SecretStore/containers/DelegationsListContainer/DelegationsListContainer';
import WithStickyToolbar from '@ytsaurus-ui-platform/src/ui/components/WithStickyToolbar/WithStickyToolbar';
import {NavigationTabLoader} from 'components/NavigationTabLoader/NavigationTabLoader';

const b = block('tracto-secret-store-tab');

type SecretStoreTabComponentProps = {
    value: SecretListItem[];
    onUpdate: (value: SecretListItem[]) => void;
    loading: boolean;
    onSaveClick: () => void;
    onCreateDelegationClick: () => void;
};

export const SecretStoreTabComponent: React.FC<SecretStoreTabComponentProps> = ({
    value,
    onUpdate,
    loading,
    onSaveClick,
    onCreateDelegationClick,
}) => {
    if (loading) {
        return <NavigationTabLoader />;
    }

    const secretsListProps: ComponentProps<typeof SecretsList> = {
        value,
        onUpdate,
    };

    return (
        <WithStickyToolbar
            className={b()}
            toolbar={
                <div className={b('toolbar')}>
                    <Button size="m" view="action" onClick={onSaveClick}>
                        Save secrets
                    </Button>
                    <Button size="m" onClick={onCreateDelegationClick}>
                        Create delegation
                    </Button>
                </div>
            }
            content={
                <FlexSplitPane className={b('split-pane')} direction={FlexSplitPane.HORIZONTAL}>
                    <div className={b('split-pane-item')}>
                        <SecretsList {...secretsListProps} />
                    </div>
                    <div className={b('split-pane-item')}>
                        <DelegationsListContainer />
                    </div>
                </FlexSplitPane>
            }
        />
    );
};
