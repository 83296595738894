import React from 'react';
import block from 'bem-cn-lite';
import './BlueprintImageParamsComponent.scss';
import {TextInput} from '@gravity-ui/uikit';

const b = block('blueprint-image-params');

type BlueprintImageParamsComponentProps = {
    className?: string;
    imageRegistry: string;
    imageName: string;
    onImageNameUpdate: (value: string) => void;
};

export const BlueprintMainImageParamsComponent: React.FC<BlueprintImageParamsComponentProps> = ({
    className,
    imageRegistry,
    imageName,
    onImageNameUpdate,
}) => {
    return (
        <div className={b(null, className)}>
            <div className="elements-section">
                <div className="elements-heading elements-heading_size_xs">Registry</div>
                <TextInput placeholder="Registry..." disabled={true} value={imageRegistry} />
            </div>
            <div className="elements-section">
                <div className="elements-heading elements-heading_size_xs">Image name</div>
                <TextInput
                    placeholder="Image name..."
                    value={imageName}
                    onUpdate={onImageNameUpdate}
                />
            </div>
        </div>
    );
};

type BlueprintSecondaryImageParamsComponentProps = {
    className?: string;
    cpuLimit: number;
    memoryLimit: number;
    onCpuLimitUpdate: (value: string) => void;
    onMemoryLimitUpdate: (value: string) => void;
};

export const BlueprintSecondaryImageParamsComponent: React.FC<
    BlueprintSecondaryImageParamsComponentProps
> = ({className, cpuLimit, memoryLimit, onCpuLimitUpdate, onMemoryLimitUpdate}) => {
    return (
        <div className={b(null, className)}>
            <div className="elements-section">
                <div className="elements-heading elements-heading_size_xs">Build params</div>
                <div className={b('build-param-row')}>
                    <div className={b('build-param-row-label')}>CPU limit:</div>
                    <TextInput
                        type="number"
                        placeholder="CPU limit..."
                        value={cpuLimit ? String(cpuLimit) : undefined}
                        onUpdate={onCpuLimitUpdate}
                    />
                </div>
                <div className={b('build-param-row')}>
                    <div className={b('build-param-row-label')}>Memory limit:</div>
                    <TextInput
                        type="number"
                        placeholder="Memory limit..."
                        value={memoryLimit ? String(memoryLimit) : undefined}
                        onUpdate={onMemoryLimitUpdate}
                    />
                </div>
            </div>
        </div>
    );
};
