import * as React from 'react';
import {useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import compact from 'lodash/compact';
import {getCluster} from '@ytsaurus-ui-platform/src/ui/store/selectors/global';
import {ChytInfo} from '@ytsaurus-ui-platform/src/ui/store/reducers/chyt/list';
import {concatByAnd} from '@ytsaurus-ui-platform/src/ui/common/hammer/predicate';
import WithStickyToolbar from '@ytsaurus-ui-platform/src/ui/components/WithStickyToolbar/WithStickyToolbar';
import {useUpdater} from '@ytsaurus-ui-platform/src/ui/hooks/use-updater';
import {SortState} from '@ytsaurus-ui-platform/src/ui/types';
import {
    OrderType,
    multiSortBySortStateArray,
    updateSortStateArray,
} from '@ytsaurus-ui-platform/src/ui/utils/sort-helpers';

import {useTractoDispatch} from '../../../../store/tracto-dispatch';
import JupytListTable from '../../components/JupytList/JupytListTable/JupytListTable';
import JupytListToolbar from '../../components/JupytList/JupytListToolbar/JupytListToolbar';
import {
    jupytClearState,
    jupytSetVisibleColumns,
    loadJupytDefaults,
    loadJupytPageList,
    removeJupyt,
    resumeJupyt,
    setSearchParams,
    startJupyt,
    stopJupyt,
} from '../../store/actions/jupyt/jupyt';
import {
    getJupytListSearchParams,
    getJupytListVisibleColumns,
    getJupytOperations,
} from '../../store/selectors/jupyt';
import {JUPYTER_OPERATIONS_TABLE_TITLES} from '../../components/JupytList/constants';
import {JupyterListFilters, JupyterListFiltersValues} from '../../components/JupytList/types';
import {JupytListTableItem} from '../../api/jupyt';

const useJupytListActions = () => {
    const dispatch = useTractoDispatch();

    const updateJupytList = React.useCallback(() => {
        dispatch(loadJupytPageList());
        dispatch(loadJupytDefaults());
    }, []);

    useUpdater(updateJupytList);

    const handleStartJupytClick = useCallback(({alias}: {alias: string}) => {
        dispatch(startJupyt({alias})).then(() => {
            dispatch(loadJupytPageList());
        });
    }, []);

    const handleStopJupytClick = useCallback(({alias}: {alias: string}) => {
        dispatch(stopJupyt({alias})).then(() => {
            dispatch(loadJupytPageList());
        });
    }, []);

    const handleRemoveJupytClick = useCallback(({alias}: {alias: string}) => {
        dispatch(removeJupyt({alias})).then(() => {
            dispatch(loadJupytPageList());
        });
    }, []);

    const handleResumeJupytClick = useCallback(({alias}: {alias: string}) => {
        dispatch(resumeJupyt({alias})).then(() => {
            dispatch(loadJupytPageList());
        });
    }, []);

    return {
        handleStopJupytClick,
        handleStartJupytClick,
        handleRemoveJupytClick,
        handleResumeJupytClick,
    };
};

const DEFAULT_SORT_VALUE: Array<SortState<keyof JupytListTableItem>> = [
    {column: 'alias', order: 'asc'},
];

const useJupytList = ({items}: {items: JupytListTableItem[]}) => {
    const dispatch = useTractoDispatch();

    const filtersOptions = React.useMemo(() => {
        const options = items.reduce(
            (ret, item) => {
                ret.creator.add(item.creator);
                ret.health.add(item.health);
                ret.state.add(item.state);

                return ret;
            },
            {
                creator: new Set(),
                health: new Set(),
                state: new Set(),
            },
        );

        return {
            creator: Array.from(options.creator),
            health: Array.from(options.health),
            state: Array.from(options.state),
        };
    }, [items]);
    const visibleColumns = useSelector(getJupytListVisibleColumns);
    const searchParams = useSelector(getJupytListSearchParams);
    const [sortState, setSort] = React.useState<typeof DEFAULT_SORT_VALUE>(DEFAULT_SORT_VALUE);

    const predicates = React.useMemo(() => {
        const predicates = compact([
            searchParams.alias
                ? (item: ChytInfo) => -1 !== item.alias.indexOf(searchParams.alias)
                : undefined,
            searchParams.creator
                ? (item: ChytInfo) => searchParams.creator === item.creator
                : undefined,
            searchParams.state ? (item: ChytInfo) => searchParams.state === item.state : undefined,
            searchParams.health
                ? (item: ChytInfo) => searchParams.health === item.health
                : undefined,
        ]);

        return predicates.length ? concatByAnd(...predicates) : undefined;
    }, [searchParams]);

    const columnsFilterValues = Object.entries(JUPYTER_OPERATIONS_TABLE_TITLES).map(
        ([column, name]) => {
            return {
                name,
                data: {column},
                checked: visibleColumns.includes(column),
            };
        },
    );

    const onFiltersUpdate = React.useCallback(
        (filtersState: Partial<JupyterListFiltersValues>) => {
            if ('columns' in filtersState && filtersState.columns) {
                dispatch(
                    jupytSetVisibleColumns(
                        filtersState.columns
                            .filter((col) => col.checked)
                            .map((col) => col.data.column),
                    ),
                );
            } else {
                dispatch(
                    setSearchParams({...searchParams, ...(filtersState as Record<string, string>)}),
                );
            }
        },
        [searchParams],
    );

    const onSort = React.useCallback(
        (column: keyof JupytListTableItem, order: OrderType, options: {multisort?: boolean}) => {
            setSort((prevSortState) => {
                return updateSortStateArray(prevSortState, {column, order}, options);
            });
        },
        [],
    );

    const filteredItems = !predicates ? items : items.filter(predicates);
    const sortedItems = multiSortBySortStateArray(filteredItems, sortState);

    return {
        onSort,
        sortState: sortState.reduce(
            (acc, item, index) => {
                if (item.column) {
                    acc[item.column] = {
                        ...item,
                        multisortIndex: sortState.length > 1 ? index + 1 : undefined,
                    };
                }
                return acc;
            },
            {} as Record<
                keyof JupytListTableItem,
                SortState<keyof JupytListTableItem> & {multisortIndex?: number}
            >,
        ),
        onFiltersUpdate,
        items: sortedItems,
        filters: {
            alias: {
                value: searchParams.alias,
            },
            creator: {
                value: searchParams.creator,
                options: filtersOptions.creator,
            },
            health: {
                value: searchParams.health,
                options: filtersOptions.health,
            },
            state: {
                value: searchParams.state,
                options: filtersOptions.state,
            },
            columns: {
                values: columnsFilterValues,
            },
        } as unknown as JupyterListFilters,
        checkedColumns: columnsFilterValues
            .filter((item) => item.checked)
            .map((item) => item.data.column) as Array<keyof JupytListTableItem>,
    };
};

export const JupytListPageContainer = () => {
    const dispatch = useDispatch();

    const jupytListActions = useJupytListActions();
    const cluster = useSelector(getCluster);
    const items = useSelector(getJupytOperations);
    const jupytList = useJupytList({items});

    React.useEffect(() => {
        return () => {
            dispatch(jupytClearState());
        };
    }, []);

    return (
        <div style={{padding: '6px 20px'}}>
            <WithStickyToolbar
                toolbar={
                    <JupytListToolbar
                        filters={jupytList.filters}
                        onFiltersUpdate={jupytList.onFiltersUpdate}
                    />
                }
                content={
                    <JupytListTable
                        cluster={cluster}
                        items={jupytList.items}
                        onSort={jupytList.onSort}
                        sortState={jupytList.sortState}
                        checkedColumns={jupytList.checkedColumns}
                        onRemoveRowClick={jupytListActions.handleRemoveJupytClick}
                        onStartRowClick={jupytListActions.handleStartJupytClick}
                        onStopRowClick={jupytListActions.handleStopJupytClick}
                        onResumeClick={jupytListActions.handleResumeJupytClick}
                    />
                }
            />
        </div>
    );
};
