import {Icon, QAProps} from '@gravity-ui/uikit';
import {Play, Stop} from '@gravity-ui/icons';
import React from 'react';
import {ACTIONS_CONTAINER_CONTROL_SIZE, ACTIONS_CONTAINER_ICON_SIZE} from '../../constants/size';
import {JupyterButtonWithHotkeys} from '../../../JupyterButtonWithHotkeys/JupyterButtonWithHotkeys';

type ExecuteButtonProps = {
    isRunning?: boolean;
    onRunClick?: () => void;
    onCancelClick?: () => void;
};

export function ExecuteButton({
    isRunning,
    onRunClick,
    onCancelClick,
    qa,
}: ExecuteButtonProps & QAProps) {
    const icon = isRunning ? Stop : Play;
    const onClick = isRunning
        ? () => {
              if (onCancelClick) {
                  onCancelClick();
              }
          }
        : onRunClick;

    return (
        <JupyterButtonWithHotkeys
            id="run"
            qa={qa}
            size={ACTIONS_CONTAINER_CONTROL_SIZE}
            view="flat"
            onClick={onClick}
            selected={isRunning}
        >
            <Icon data={icon} size={ACTIONS_CONTAINER_ICON_SIZE} />
        </JupyterButtonWithHotkeys>
    );
}
