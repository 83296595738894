import {createAsyncThunk} from '@reduxjs/toolkit';
import type {TractoRootState} from './reducers';
import type {TractoThunkDispatch} from './tracto-dispatch';

export type TractoAsyncThunkConfig = {
    state: TractoRootState;
    dispatch: TractoThunkDispatch;
};

export const createTractoAsyncThunk = createAsyncThunk.withTypes<TractoAsyncThunkConfig>();
