import {userSaveBlueprint} from 'features/ImageBuilder/store/actions/blueprints';
import {
    selectImageName,
    selectSavingInProgress,
} from 'features/ImageBuilder/store/selectors/blueprint';
import {blueprintSlice} from 'features/ImageBuilder/store/slices/blueprintSlice';
import {useHotkey} from 'hooks/useHotkey';
import React, {useCallback} from 'react';
import {useSelector} from 'react-redux';
import {useTractoDispatch} from 'store/tracto-dispatch';
import {Flex, Icon} from '@gravity-ui/uikit';
import Button from '@ytsaurus-ui-platform/src/ui/components/Button/Button';
import {CirclePlay, FloppyDisk} from '@gravity-ui/icons';

export const BlueprintTabToolbarContainer: React.FC = () => {
    const dispatch = useTractoDispatch();
    const isBlueprintChanged = true;

    const isBlueprintRunning = false;

    const isSavingInProgress = useSelector(selectSavingInProgress);
    const imageName = useSelector(selectImageName);
    const isSaveDisabled = !isBlueprintChanged;

    let content = 'Save changes';

    if (!isBlueprintChanged) {
        content = 'Saved';
    }

    const onSave = useCallback(() => {
        dispatch(userSaveBlueprint());
    }, []);

    const onBuild = useCallback(() => {
        dispatch(blueprintSlice.actions.showNewBuildDialog());
    }, []);

    useHotkey({
        keys: 'control+s,command+s',
        handler: (event) => {
            event.preventDefault();
            onSave();
        },
    });

    return (
        <Flex gap="2" alignItems="center" height="100%">
            <Button onClick={onBuild} loading={isBlueprintRunning} disabled={!imageName}>
                <Icon data={CirclePlay} size={13} />
                Start new build
            </Button>
            <Button onClick={onSave} disabled={isSaveDisabled} loading={isSavingInProgress}>
                <Icon data={FloppyDisk} size={13} />
                {content}
            </Button>
        </Flex>
    );
};
