import React, {useCallback} from 'react';
import * as nbformat from '@jupyterlab/nbformat';
import {useSelector} from 'react-redux';
import {
    selectDirtyCells,
    selectEditableCell,
    selectFocusedCellId,
    selectNotebookCells,
    selectRunningCells,
} from '../../store/selectors/notebook';
import {JupyterCell} from '../../components/JupyterCell/JupyterCell';
import {executeFocusedCell} from '../../store/actions/execute';
import {notebookSlice} from '../../store/slices/notebook';
import {extractCellId} from '../../utils/cell/common';
import {TractoRootState} from '../../../../store/reducers';
import {useTractoDispatch} from '../../../../store/tracto-dispatch';
import {useHotkey} from 'hooks/useHotkey';
import type {TractoNotebookCell} from '../../types/version';
import {NotebookCellType} from '../../constants/cell';
import {addNotebookCell, updateCellSource} from '../../store/actions/notebook/cell';

interface JupyterCellContainerProps {
    cellIndex: number;
}

const useJupyterCell = (cellIndex: number) => {
    const dispatch = useTractoDispatch();

    const cell = useSelector<TractoRootState, TractoNotebookCell>(
        (state) => selectNotebookCells(state)[cellIndex],
    );

    const cellId = extractCellId(cell);

    const isMarkdownCell = nbformat.isMarkdown(cell);

    const isRunning = useSelector<TractoRootState, boolean>(
        (state) => selectRunningCells(state)[cellId],
    );

    const isFocused = useSelector<TractoRootState, boolean>(
        (state) => selectFocusedCellId(state) === cellId,
    );

    const isEditable = useSelector<TractoRootState, boolean>(
        (state) => selectEditableCell(state) === cellId,
    );

    const isDirty = useSelector<TractoRootState, boolean>((state) =>
        Boolean(selectDirtyCells(state)[cellId]),
    );

    const onChange = useCallback(
        (source: string) => {
            dispatch(updateCellSource({cellId, source}));
        },
        [cellId],
    );

    const cellRef = React.useRef<HTMLDivElement>(null);

    useHotkey({
        keys: 'enter',
        handler: (event) => {
            if (isEditable || !isFocused) {
                return;
            }

            event.preventDefault();

            dispatch(notebookSlice.actions.makeCellEditable());
        },
    });

    //above
    useHotkey({
        keys: 'a',
        handler: (event) => {
            if (!isEditable && isFocused) {
                event.preventDefault();
                dispatch(
                    addNotebookCell({
                        currentIndex: cellIndex - 1,
                        type: NotebookCellType.CODE,
                        editable: false,
                        focus: false,
                    }),
                );
            }
        },
    });

    //below
    useHotkey({
        keys: 'b',
        handler: (event) => {
            if (!isEditable && isFocused) {
                event.preventDefault();
                dispatch(
                    addNotebookCell({
                        currentIndex: cellIndex,
                        type: NotebookCellType.CODE,
                        editable: false,
                        focus: false,
                    }),
                );
            }
        },
    });

    useHotkey({
        keys: 'c',
        handler: () => {
            if (isEditable || !isFocused) {
                return;
            }

            dispatch(
                notebookSlice.actions.setBufferCell({
                    cell,
                }),
            );
        },
    });

    useHotkey({
        keys: 'x',
        handler: () => {
            if (isEditable || !isFocused) {
                return;
            }

            dispatch(
                notebookSlice.actions.setBufferCell({
                    cell,
                }),
            );

            dispatch(notebookSlice.actions.deleteCell({currentIndex: cellIndex}));
        },
    });

    useHotkey({
        keys: 'v',
        handler: () => {
            if (isEditable || !isFocused) {
                return;
            }

            dispatch(
                notebookSlice.actions.pasteBufferCell({
                    currentIndex: cellIndex,
                }),
            );
        },
    });

    const lastDPress = React.useRef(0);

    useHotkey({
        keys: '*',
        handler: (event) => {
            if (isEditable || !isFocused) {
                return;
            }
            const isDKey = event.key === 'd';

            if (!isDKey) {
                lastDPress.current = 0;
                return;
            }

            const now = Date.now();

            if (now - lastDPress.current <= 500) {
                dispatch(notebookSlice.actions.deleteCell({currentIndex: cellIndex}));
                lastDPress.current = 0;
            } else {
                lastDPress.current = now;
            }
        },
    });

    useHotkey({
        keys: 'shift+enter,control+enter,command+enter',
        handler: (event) => {
            if (!isFocused) {
                return;
            }

            event.preventDefault();

            dispatch(executeFocusedCell());
        },
    });

    useHotkey({
        keys: 'escape',
        handler: () => {
            if (!(isEditable && isFocused)) {
                return;
            }

            cellRef.current?.focus();

            dispatch(notebookSlice.actions.removeCellEditable());
        },
    });

    const onEditorFocus = useCallback(
        (event: React.FocusEvent<HTMLElement>) => {
            if (!isEditable) {
                event.preventDefault();
                event.stopPropagation();

                if (!isFocused) {
                    dispatch(notebookSlice.actions.setFocusedCellById({cellId}));
                }

                if (!isMarkdownCell) {
                    dispatch(notebookSlice.actions.makeCellEditable());
                }
            }
        },
        [cellId, isEditable, isFocused, isMarkdownCell],
    );

    const onDoubleClick = useCallback(() => {
        if (isMarkdownCell) {
            dispatch(notebookSlice.actions.makeCellEditable());
        }
    }, [cellId, isMarkdownCell]);

    const onClick = useCallback(() => {
        if (!isFocused) {
            dispatch(notebookSlice.actions.setFocusedCellById({cellId}));
        }
    }, [isMarkdownCell, isFocused, cellId]);

    const onMarkdownBlur = useCallback(() => {
        dispatch(notebookSlice.actions.removeCellEditable());
    }, [cellId]);

    const onImagePaste = useCallback(
        ({name, base64, type}: {name: string; base64: string; type: string}) => {
            dispatch(
                notebookSlice.actions.setCellAttachment({
                    cellId,
                    name,
                    base64,
                    type,
                }),
            );
        },
        [cellId],
    );

    return {
        cell,
        cellRef,
        isEditable,
        isRunning,
        isFocused,
        isDirty,
        onChange,
        onEditorFocus,
        onDoubleClick,
        onMarkdownBlur,
        onImagePaste,
        onClick,
    };
};

export const JupyterCellContainer: React.FC<JupyterCellContainerProps> = (props) => {
    const useJupyterCellProps = useJupyterCell(props.cellIndex);

    return <JupyterCell {...useJupyterCellProps} cellIndex={props.cellIndex} />;
};
