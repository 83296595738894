import {PayloadAction, createSlice} from '@reduxjs/toolkit';
import {WorkflowRunData, WorkflowRunListItem} from 'features/Orchestracto/types/runs';
import type {RelativeRangeDatePickerValue} from '@gravity-ui/date-components';

type WorkflowRunsState = {
    runId: string | undefined;
    runs: WorkflowRunListItem[];
    isRunsLoaded: boolean;
    currentRun: WorkflowRunData | null;
    currentRunLoading: boolean;
    filter: RelativeRangeDatePickerValue;
    limit: string;
};

const initialState: WorkflowRunsState = {
    runId: undefined,
    runs: [],
    isRunsLoaded: false,
    currentRun: null,
    currentRunLoading: false,
    filter: {start: {type: 'relative', value: 'now-1w'}, end: {type: 'relative', value: 'now'}},
    limit: '20',
};

export const runsSlice = createSlice({
    name: 'orchestracto.runs',
    initialState,
    reducers: {
        setRuns: (state, action: PayloadAction<{runs: WorkflowRunListItem[]}>) => {
            state.runs = action.payload.runs;
            state.isRunsLoaded = true;
        },
        setCurrentRun: (state, action: PayloadAction<{currentRun: WorkflowRunData | null}>) => {
            state.currentRun = action.payload.currentRun;
        },
        setCurrentRunLoading: (state, action: PayloadAction<{loading: boolean}>) => {
            state.currentRunLoading = action.payload.loading;
        },
        setFilter(state, action: PayloadAction<RelativeRangeDatePickerValue>) {
            state.filter = action.payload;
        },
        setLimit(state, action: PayloadAction<string>) {
            state.limit = action.payload;
        },
        clearState: () => initialState,
    },
});
