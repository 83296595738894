import {combineReducers} from '@reduxjs/toolkit';
import {RootState} from '@ytsaurus-ui-platform/src/ui/store/reducers';
import {jupyterRoot} from 'features/Jupyter/store/slices';
import {navigationRoot} from 'features/Navigation/store/slices';
import {universeResourcesRoot} from 'features/UniverseResources/store/slices';
import {orchestractoRoot} from 'features/Orchestracto/store/slices';
import {tractoRegistryRoot} from 'features/TractoRegistry/store/slices';
import {monitoringRoot} from 'features/Monitoring/store';
import {secretStoreRoot} from 'features/SecretStore/store/slices/index';
import type {SupportedFeaturesState} from '@ytsaurus-ui-platform/src/ui/store/reducers/global/supported-features';
import {imageBuilderRoot} from 'features/ImageBuilder/store';
import {hotkeysSlice} from '../libs/hotkeys/store/slice';

export const tractoAppReducers = {
    tracto: combineReducers({
        jupyter: jupyterRoot,
        navigation: navigationRoot,
        universeResources: universeResourcesRoot,
        orchestracto: orchestractoRoot,
        tractoRegistry: tractoRegistryRoot,
        monitoring: monitoringRoot,
        secretStore: secretStoreRoot,
        imageBuilder: imageBuilderRoot,
        hotkeys: hotkeysSlice.reducer,
    }),
};

export type ExtendedSupportedFeatures = SupportedFeaturesState['features'] & {
    require_password_in_authentication_commands?: boolean;
};

export type ExtendedSupportedFeaturesState = Omit<SupportedFeaturesState, 'features'> & {
    features: ExtendedSupportedFeatures;
};

export type TractoRootState = Omit<RootState, 'supportedFeatures'> & {
    supportedFeatures: ExtendedSupportedFeaturesState;
} & {
    tracto: ReturnType<(typeof tractoAppReducers)['tracto']>;
};
