import type {
    TractoNotebookCellMetadata,
    TractoNotebookCodeCell,
    TractoNotebookMarkdownCell,
} from '../../types/version';
import type {MultilineString} from '@jupyterlab/nbformat';

type Payload = {
    cellId: string;
    source: MultilineString;
    metadata: TractoNotebookCellMetadata;
};

export const getCodeCell = ({cellId, source, metadata}: Payload): TractoNotebookCodeCell => {
    return {
        cell_type: 'code',
        id: cellId,
        source,
        metadata,
        outputs: [],
        execution_count: null,
    };
};

export const getMarkdownCell = ({
    cellId,
    source,
    metadata,
}: Payload): TractoNotebookMarkdownCell => {
    return {
        cell_type: 'markdown',
        id: cellId,
        source,
        metadata,
    };
};
