import React from 'react';

import {useSelector} from 'react-redux';
import {
    selectCurrentRun,
    selectCurrentRunLoading,
    selectCurrentRunStepsMap,
} from '../../store/selectors/runs';
import {buildGraphFromWorkflowWithStatuses} from '../../utils/graph';
import {Flex, Icon, Text} from '@gravity-ui/uikit';
import {GraphContainer} from '../GraphContainer/GraphContainer';
import {NodesLeft} from '@gravity-ui/icons';
import {NavigationTabLoader} from 'components/NavigationTabLoader/NavigationTabLoader';

export const WorkflowExecutionGraphContainer = () => {
    const workflowExecution = useSelector(selectCurrentRun);
    const loading = useSelector(selectCurrentRunLoading);
    const stepsMap = useSelector(selectCurrentRunStepsMap);

    const getData = React.useCallback(() => {
        if (!workflowExecution) {
            throw new Error('Cannot show graph without workflow');
        }

        return buildGraphFromWorkflowWithStatuses({
            workflow: workflowExecution.workflow,
            workflowStepsStateMap: workflowExecution.workflowStepsStateMap,
        });
    }, [workflowExecution]);

    if (loading) {
        return <NavigationTabLoader />;
    }

    if (!workflowExecution) {
        return (
            <Flex
                alignItems="center"
                justifyContent="center"
                direction="column"
                width="100%"
                height="100%"
                gap="10"
            >
                <Text variant="body-2">Select workflow run from the table</Text>
                <Icon data={NodesLeft} size={60} />
            </Flex>
        );
    }

    return <GraphContainer stepsMap={stepsMap} getData={getData} />;
};
