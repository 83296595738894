import React, {useCallback} from 'react';
import {Button, type ButtonProps, Icon} from '@gravity-ui/uikit';
import {MenuWithHotkeys, type MenuWithHotkeysProps} from '../MenuWithHotkeys/MenuWithHotkeys';
import {Ellipsis} from '@gravity-ui/icons';

export type DropdownWithHotkeysProps = {
    items: MenuWithHotkeysProps['items'];
    menu?: Pick<MenuWithHotkeysProps, 'placement' | 'hasArrow'>;
    button?: Omit<ButtonProps, 'children'>;
};

export const DropdownWithHotkeys: React.FC<DropdownWithHotkeysProps> = (
    props: DropdownWithHotkeysProps,
) => {
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef<HTMLButtonElement | null>(null);

    const {onClick, ...button} = props.button ?? {};

    const handleButtonClick = useCallback(
        (event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
            setOpen((prev) => !prev);
            onClick?.(event);
        },
        [onClick],
    );

    const handleMenuClose = useCallback(() => {
        setOpen(false);
    }, []);

    return (
        <>
            <Button onClick={handleButtonClick} {...button} ref={anchorRef}>
                <Icon data={Ellipsis} />
            </Button>
            {anchorRef.current && (
                <MenuWithHotkeys
                    anchorRef={anchorRef}
                    {...props.menu}
                    open={open}
                    onClose={handleMenuClose}
                    items={props.items}
                />
            )}
        </>
    );
};
