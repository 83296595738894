import React, {useCallback, useMemo, useState} from 'react';
import {Button, Pagination, Table, TableColumnConfig} from '@gravity-ui/uikit';
import moment from 'moment/moment';
import './DelegationsListComponent.scss';
import block from 'bem-cn-lite';
import {Delegation} from 'features/SecretStore/types/delegations';
import Link from '@ytsaurus-ui-platform/src/ui/components/Link/Link';
import Icon from '@ytsaurus-ui-platform/src/ui/components/Icon/Icon';
import YT from '@ytsaurus-ui-platform/src/ui/config/yt-config';

const b = block('delegations-list');

const PAGE_SIZE = 16;

interface DelegationsListComponentProps {
    data: Delegation[];
    onRemove: (delegation: Delegation) => void;
}

export const DelegationsListComponent: React.FC<DelegationsListComponentProps> = ({
    data,
    onRemove,
}) => {
    const [page, setPage] = useState(1);

    const columns: TableColumnConfig<Delegation>[] = [
        {
            id: 'consumer-type',
            name: 'Consumer type',
            template: (item) => {
                return item.consumer_type;
            },
        },
        {
            id: 'consumer-identity',
            name: 'Consumer',
            template: (item) => {
                return (
                    <Link url={`/${YT.cluster}/navigation?path=${item.consumer_identity}`}>
                        {item.consumer_identity}
                    </Link>
                );
            },
        },
        {
            id: 'created-at',
            name: 'Created at',
            template: (item) => {
                return moment(item.created_at).format('DD/MM/YYYY HH:mm:ss');
            },
        },
        {
            id: 'created-by',
            name: 'Created by',
            template: (item) => {
                return item.created_by;
            },
        },
        {
            id: 'remove-action',
            name: '',
            template: (item) => {
                const onClick = () => {
                    onRemove(item);
                };
                return (
                    <Button size="s" view="flat" onClick={onClick}>
                        <Icon awesome={'trash-bin'} />
                    </Button>
                );
            },
        },
    ];

    const handleUpdate = useCallback((page: number) => {
        setPage(page);
    }, []);

    const pagedData = useMemo(() => {
        return data.slice((page - 1) * PAGE_SIZE, page * PAGE_SIZE);
    }, [data, page]);

    let pagination: React.ReactNode = null;

    if (pagedData.length < data.length) {
        pagination = (
            <Pagination
                className={b('pagination')}
                compact={false}
                page={page}
                pageSize={PAGE_SIZE}
                total={data.length}
                onUpdate={handleUpdate}
            />
        );
    }

    return (
        <div className={b()}>
            <Table className={b('table')} data={pagedData} columns={columns} />
            {pagination}
        </div>
    );
};
