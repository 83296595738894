import {createTractoAsyncThunk} from '../../../../../store/tracto-async-thunk';
import {WorkflowRunData} from '../../../types/runs';
import {getPath} from '@ytsaurus-ui-platform/src/ui/store/selectors/navigation';
import {getIsOldWorkflow} from '../../../utils/old-workflow';
import {isWorkflowAPIEnabled} from '../../selectors/workflow';
import {OrchestractoApi} from '../../../api';
import type {
    WorkflowDocumentType,
    WorkflowExecutionStage,
    WorkflowExecutionState,
    WorkflowExecutionStatus,
    WorkflowStepState,
} from '../../../types/workflow';
import {
    getCurrentWorkflowLocationConfig,
    getCurrentWorkflowRunConfig,
} from '../../../utils/workflow';
import {ytApiV3} from '@ytsaurus-ui-platform/src/ui/rum/rum-wrap-api';
import {wrapApiPromiseByToaster} from '@ytsaurus-ui-platform/src/ui/utils/utils';
import type {BatchResultsItem} from '@ytsaurus-ui-platform/src/shared/yt-types';
import {runsSlice} from '../../slices/runs';
import CancelHelper from '@ytsaurus-ui-platform/src/ui/utils/cancel-helper';

const cancelHelper = new CancelHelper();

export const loadWorkflowRunState = createTractoAsyncThunk<WorkflowRunData, {runId: string}>(
    'orchestracto.actions.load-workflow-state',
    async ({runId}, thunkAPI): Promise<WorkflowRunData> => {
        const path = getPath(thunkAPI.getState());
        const isOldWorkflow = await getIsOldWorkflow(path);

        let currentRun: WorkflowRunData;

        if (isWorkflowAPIEnabled(thunkAPI.getState()) && !isOldWorkflow) {
            const response = await OrchestractoApi.api.runs.getRun({
                runId,
                workflowPath: path,
                cancelToken: cancelHelper.removeAllAndGenerateNextToken(),
            });

            const workflowStepsStateMap: Record<string, WorkflowStepState> = {};

            // eslint-disable-next-line guard-for-in
            for (const stageKey in response.workflow_execution_state.exec_stages) {
                const stage = stageKey as WorkflowExecutionStage;

                // eslint-disable-next-line guard-for-in
                for (const task in response.workflow_execution_state.exec_stages[stage]) {
                    workflowStepsStateMap[task] = {
                        stage,
                        finalState:
                            response.workflow_execution_state.exec_stages[stage][task].final_state,
                        finished:
                            response.workflow_execution_state.exec_stages[stage][task].finished,
                    };
                }
            }

            currentRun = {
                workflow: response.workflow,
                workflowStatus: response.stage as any,
                workflowStepsStateMap,
                runId,
            };
        } else {
            const {orchestractoRootPath, workflowId} = getCurrentWorkflowLocationConfig(path);

            const {workflowExecutionStatePath, workflowRunContentPath, workflowResultPath} =
                getCurrentWorkflowRunConfig({
                    orchestractoRootPath,
                    workflowId,
                    runId,
                });

            const executeBatchPromise = ytApiV3.executeBatch<
                WorkflowExecutionStatus | WorkflowExecutionState | WorkflowDocumentType
            >({
                cancellation: cancelHelper.removeAllAndSave,
                parameters: {
                    requests: [
                        {
                            command: 'get',
                            parameters: {
                                path: `${workflowResultPath}/status`,
                            },
                        },
                        {
                            command: 'get',
                            parameters: {
                                path: workflowExecutionStatePath,
                            },
                        },
                        {
                            command: 'get',
                            parameters: {
                                path: workflowRunContentPath,
                            },
                        },
                    ],
                    output_format: 'json',
                },
            });

            const response = await wrapApiPromiseByToaster<
                BatchResultsItem<
                    WorkflowExecutionStatus | WorkflowExecutionState | WorkflowDocumentType
                >[],
                'v3'
            >(executeBatchPromise, {
                toasterName: 'workflow-state-request',
                skipSuccessToast: true,
                batchType: 'v3',
                errorTitle: `Failed to load workflow run with ID: ${runId}`,
                errorContent: (error) => {
                    return `[code ${error.code}] ${error.message}`;
                },
            });
            const workflowExecutionStatus = (
                response[0] as BatchResultsItem<WorkflowExecutionStatus>
            ).output!;
            const workflowExecutionState = (response[1] as BatchResultsItem<WorkflowExecutionState>)
                .output!;
            const workflowExecutionDocumentType = (
                response[2] as BatchResultsItem<WorkflowDocumentType>
            ).output!;

            const workflowStepsStateMap: Record<string, WorkflowStepState> = {};

            // eslint-disable-next-line guard-for-in
            for (const stageKey in workflowExecutionState.exec_stages) {
                const stage = stageKey as WorkflowExecutionStage;

                // eslint-disable-next-line guard-for-in
                for (const task in workflowExecutionState.exec_stages[stage]) {
                    workflowStepsStateMap[task] = {
                        stage,
                        finalState: workflowExecutionState.exec_stages[stage][task].final_state,
                        finished: workflowExecutionState.exec_stages[stage][task].finished,
                    };
                }
            }

            currentRun = {
                workflow: workflowExecutionDocumentType,
                workflowStatus: workflowExecutionStatus,
                workflowStepsStateMap,
                runId,
            };
        }

        thunkAPI.dispatch(runsSlice.actions.setCurrentRun({currentRun}));

        return currentRun;
    },
);
