import type {RadioButtonOption, SelectOption} from '@gravity-ui/uikit';

export const selectify = (v: {
    value: string;
    content?: string;
    qa?: string;
}): RadioButtonOption | SelectOption => ({
    value: v.value,
    content: v.content ?? v.value,
    qa: v.qa,
});
