import type {RelativeRangeDatePickerValue} from '@gravity-ui/date-components';
import {dateTimeParse} from '@gravity-ui/date-utils';
import {RUNS_FILTER_ORC_FORMAT} from '../../constants/runs/filter';

export const prepareRunsDateFilter = (
    filter: RelativeRangeDatePickerValue,
): {start: string; end: string} | undefined => {
    if (!filter.start?.value || !filter.end?.value) {
        return undefined;
    }

    return {
        start: dateTimeParse(filter.start.value)!.utc().format(RUNS_FILTER_ORC_FORMAT),
        end: dateTimeParse(filter.end.value)!.utc().format(RUNS_FILTER_ORC_FORMAT),
    };
};
