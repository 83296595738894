import React from 'react';

import {Dialog, Hotkey, Table, type TableColumnConfig, Text} from '@gravity-ui/uikit';
import block from 'bem-cn-lite';
import type {HotkeyDescriptor} from '../../types';

import './DialogHotkeysPreview.scss';

export type DialogHotkeysPreviewProps = {
    open: boolean;
    onClose: () => void;
    hotkeys: Pick<HotkeyDescriptor, 'key' | 'description' | 'platform'>[];
};

const b = block('dialog-hotkeys-preview');

const config: TableColumnConfig<HotkeyDescriptor>[] = [
    {
        id: 'key',
        name: 'Hotkey',
        template: ({key, platform}) => <Hotkey value={key} platform={platform} />,
        width: '20%',
    },
    {
        id: 'description',
        name: 'Actions',
        template: ({description}) => <Text variant="code-inline-2">{description}</Text>,
    },
];

export const DialogHotkeysPreview: React.FC<DialogHotkeysPreviewProps> = (
    props: DialogHotkeysPreviewProps,
) => {
    const {hotkeys, open, onClose} = props;
    return (
        <Dialog open={open} onClose={onClose} className={b()}>
            <Dialog.Body className={b('body')}>
                <Table columns={config} data={hotkeys} className={b('table')} />
            </Dialog.Body>
        </Dialog>
    );
};
