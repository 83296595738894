import React from 'react';

import {
    DefaultSubjectCard,
    type SubjectCardProps,
} from '@ytsaurus-ui-platform/src/ui/components/SubjectLink/DefaultSubjectLink';
import {type GetUserData, UserCardPopover, type UserData} from '@nebius-ui/ava';
import {getSysUserData} from '../../yt-api/v4/users';
import {getDefaultSysUser, getSysUserEmail, getSysUserName} from '../../utils/user';

export type TractoSubjectCardProps = SubjectCardProps;

export const TractoSubjectCard: React.FC<TractoSubjectCardProps> = (props) => {
    const getUserData: GetUserData = async (): Promise<UserData> => {
        const login = String(props.name);
        try {
            const response = await getSysUserData(login);

            return response.value
                ? {
                      email: getSysUserEmail(response.value),
                      name: getSysUserName(response.value, login),
                  }
                : getDefaultSysUser(login);
        } catch {
            return getDefaultSysUser(login);
        }
    };

    return (
        <UserCardPopover getUserData={getUserData}>
            <DefaultSubjectCard {...props} />
        </UserCardPopover>
    );
};
