import React, {useCallback, useMemo} from 'react';
import {
    Select,
    type SelectOption,
    SelectRenderControlProps,
    type SelectRenderPopup,
} from '@gravity-ui/uikit';
import block from 'bem-cn-lite';
import {JupytSelectOption} from './JupytSelectOption/JupytSelectOption';
import type {JupytOwnerType, JupytSelectorOptionData} from './types';

import './JupytSelect.scss';
import {getJupytStatus} from './helpers';
import {JupytSelectControl} from './JupytSelectControl/JupytSelectControl';
import type {SelectRenderControlOptions} from '@gravity-ui/uikit/build/esm/components/Select/types';
import {JupytSelectPopup} from './JupytSelectPopup/JupytSelectPopup';

export type JupytSelectorProps = {
    jupyts: SelectOption<JupytSelectorOptionData>[];
    onUpdate: (value: string[]) => void;
    currentAlias: string[] | undefined;
    defaultJupytOwnerType: JupytOwnerType | undefined;
    renderExtraPopupContent?: (args: {
        renderList: () => React.JSX.Element;
        ownerType: JupytOwnerType;
    }) => React.JSX.Element;
};

const b = block('jupyt-select');

export const JupytSelect: React.FC<JupytSelectorProps> = (props: JupytSelectorProps) => {
    const {jupyts, onUpdate, currentAlias, defaultJupytOwnerType, renderExtraPopupContent} = props;

    const [ownerType, setOwnerType] = React.useState<JupytOwnerType>(
        defaultJupytOwnerType ?? 'user',
    );

    const renderedList = useMemo(() => {
        return jupyts.filter((j) => (ownerType === 'user' ? j.data?.ownerType === 'user' : true));
    }, [jupyts, ownerType]);

    const renderOption = useCallback(
        (option: SelectOption<JupytSelectorOptionData>) => (
            <JupytSelectOption
                value={option.value}
                status={getJupytStatus(option.data)}
                suspended={option?.data?.suspended}
            />
        ),
        [],
    );

    const renderControl = useCallback(
        (renderControlProps: SelectRenderControlProps, options: SelectRenderControlOptions) => {
            const selectedItem = jupyts.find((j) => j.value === options.value?.[0]);

            const onClick = (e: React.MouseEvent<HTMLElement>) => {
                setOwnerType(selectedItem?.data?.ownerType ?? 'user');
                renderControlProps.onClick(e);
            };

            return (
                <JupytSelectControl
                    selectedItem={selectedItem}
                    {...renderControlProps}
                    onClick={onClick}
                />
            );
        },
        [jupyts],
    );

    const renderPopup: SelectRenderPopup = useCallback(
        (renderPopupProps) => {
            return (
                <JupytSelectPopup
                    {...renderPopupProps}
                    value={ownerType}
                    onUpdate={setOwnerType}
                    renderExtraPopupContent={renderExtraPopupContent}
                />
            );
        },
        [ownerType, renderExtraPopupContent],
    );

    return (
        <Select
            className={b()}
            popupClassName={b('popup')}
            value={currentAlias}
            onUpdate={onUpdate}
            multiple={false}
            options={renderedList}
            filterable={true}
            renderOption={renderOption}
            renderControl={renderControl}
            renderPopup={renderPopup}
        />
    );
};
