import {PayloadAction, createSlice} from '@reduxjs/toolkit';

type DelegationsInitialState = {
    content: string;
    attachedFiles: Array<{
        local: string;
        yt_path: string;
    }>;
    savingInProgress: boolean;
    blueprintLoaded: boolean;
    imageRegistry: string;
    imageName: string;
    newBuildDialogVisible: boolean;
    cpuLimit: number;
    memoryLimit: number;
};

const initialState: DelegationsInitialState = {
    content: '',
    attachedFiles: [],
    savingInProgress: false,
    blueprintLoaded: false,
    imageRegistry: '',
    imageName: '',
    newBuildDialogVisible: false,
    cpuLimit: 0,
    memoryLimit: 0,
};

export const blueprintSlice = createSlice({
    name: 'imageBuilder.blueprint',
    initialState,
    reducers: {
        setContent: (
            state,
            action: PayloadAction<{content: DelegationsInitialState['content']}>,
        ) => {
            state.content = action.payload.content;
        },
        setAttachedFiles: (
            state,
            action: PayloadAction<{attachedFiles: DelegationsInitialState['attachedFiles']}>,
        ) => {
            state.attachedFiles = action.payload.attachedFiles;
        },
        setSavingInProgress: (
            state,
            action: PayloadAction<{savingInProgress: DelegationsInitialState['savingInProgress']}>,
        ) => {
            state.savingInProgress = action.payload.savingInProgress;
        },
        setBlueprintLoaded: (state) => {
            state.blueprintLoaded = true;
        },
        setImageRegistry: (
            state,
            action: PayloadAction<{imageRegistry: DelegationsInitialState['imageRegistry']}>,
        ) => {
            state.imageRegistry = action.payload.imageRegistry;
        },
        setImageName: (
            state,
            action: PayloadAction<{imageName: DelegationsInitialState['imageName']}>,
        ) => {
            state.imageName = action.payload.imageName;
        },
        showNewBuildDialog: (state) => {
            state.newBuildDialogVisible = true;
        },
        hideNewBuildDialog: (state) => {
            state.newBuildDialogVisible = false;
        },
        setCpuLimit: (
            state,
            action: PayloadAction<{cpuLimit: DelegationsInitialState['cpuLimit']}>,
        ) => {
            state.cpuLimit = action.payload.cpuLimit;
        },
        setMemoryLimit: (
            state,
            action: PayloadAction<{memoryLimit: DelegationsInitialState['memoryLimit']}>,
        ) => {
            state.memoryLimit = action.payload.memoryLimit;
        },
        clearState: () => initialState,
    },
});
