import YT from '@ytsaurus-ui-platform/src/ui/config/yt-config';
import axios from 'axios';

const getBlueprintsBaseUrl = () => `/${YT.cluster}/image-builder/api/v1/blueprints`;

export type BlueprintType = {
    content: Array<{
        type: 'raw';
        content: string;
    }>;
    files: Array<{
        local: string;
        yt_path: string;
    }>;
    registry_auth: Array<{
        username: string;
        registry: string;
        password_yt_path: string;
    }>;
    docker_params: {
        image: {
            name: string;
            registry: string;
        };
        build_args: string;
    };
    build_params?: {
        cpu_limit?: number;
        memory_limit?: number;
    };
};

type GetBlueprintPayload = {
    blueprint_id: string;
};

type GetBlueprintResponse = {
    blueprint: BlueprintType;
};

export const getBlueprint = ({blueprint_id}: GetBlueprintPayload) => {
    return () => {
        return axios
            .get<GetBlueprintResponse>(`${getBlueprintsBaseUrl()}/${blueprint_id}`)
            .then((response) => response.data);
    };
};

type CreateBlueprintPayload = {
    blueprint_id: string;
    blueprint?: BlueprintType;
};

const DEFAULT_BLUEPRINT: BlueprintType = {
    content: [],
    files: [],
    registry_auth: [],
    docker_params: {
        image: {
            name: '',
            registry: '',
        },
        build_args: '',
    },
    build_params: {
        cpu_limit: 0,
        memory_limit: 0,
    },
};

export const createBlueprint = ({blueprint_id}: CreateBlueprintPayload) => {
    return () => {
        return axios.put(`${getBlueprintsBaseUrl()}/${blueprint_id}`, {
            blueprint: DEFAULT_BLUEPRINT,
        });
    };
};

type SaveBlueprintPayload = {
    blueprint_id: string;
    blueprint: BlueprintType;
};

export const saveBlueprint = ({blueprint_id, blueprint}: SaveBlueprintPayload) => {
    return () => {
        return axios.put(`${getBlueprintsBaseUrl()}/${blueprint_id}`, {blueprint});
    };
};

type DeleteBlueprintPayload = {
    blueprint_id: string;
};

export const deleteBlueprint = ({blueprint_id}: DeleteBlueprintPayload) => {
    return () => {
        return axios.delete(`${getBlueprintsBaseUrl()}/${blueprint_id}`);
    };
};
