import * as React from 'react';
import block from 'bem-cn-lite';
import {Button, Dialog, Flex, Link} from '@gravity-ui/uikit';
import {DefinitionList} from '@gravity-ui/components';
import ClipboardButton from '@ytsaurus-ui-platform/src/ui/components/ClipboardButton/ClipboardButton';

import {getDockerFile, getTractoRegistryBlobConfig} from '../../store/selectors/repositories';
import {TractoRegistryDockerfile} from '../TractoRegistryDockerfile/TractoRegistryDockerfile';
import type {LinkBuilder} from '../../types';

import './TractoRegistryRepositoriesTagDescription.scss';

const b = block('tracto-registry-tag-description');

const TractoRegistryRepositoriesTagDescriptionDockerfileModal: React.FC<React.PropsWithChildren> = (
    props,
) => {
    const [open, setOpen] = React.useState(false);

    const handleToggle = () => {
        setOpen((prevState) => !prevState);
    };

    return (
        <>
            <Button view="action" onClick={handleToggle}>
                Dockerfile
            </Button>
            <Dialog open={open} onClose={handleToggle} size={'l'}>
                <Dialog.Header caption={'Dockerfile'} />
                <Dialog.Divider />
                <Dialog.Body>{props.children}</Dialog.Body>
            </Dialog>
        </>
    );
};

export interface TractoRegistryRepositoriesTagDescriptionProps {
    repository: string;
    tag: string;
    tractoRegistryUrl: string;
    blobConfig: ReturnType<ReturnType<typeof getTractoRegistryBlobConfig>>;
    dockerFile: ReturnType<ReturnType<typeof getDockerFile>>;
    buildOperationLink: LinkBuilder;
    buildBluePrintLink: LinkBuilder;
}

export const TractoRegistryRepositoriesTagDescription: React.FC<
    TractoRegistryRepositoriesTagDescriptionProps
> = (props) => {
    const crossLinks = React.useMemo(() => {
        const labels = props.blobConfig.find((item) => item.key === 'Labels');

        if (Array.isArray(labels?.value)) {
            return labels?.value
                .map((label) => {
                    const [key, value] = label.split('=');

                    switch (key) {
                        case 'blueprint_id': {
                            return (
                                <Link target={'_blank'} href={props.buildBluePrintLink(value)}>
                                    Blueprint
                                </Link>
                            );
                        }

                        case 'yt_operation_id': {
                            return (
                                <Link target={'_blank'} href={props.buildOperationLink(value)}>
                                    Operation
                                </Link>
                            );
                        }

                        default:
                            return null;
                    }
                })
                .filter(Boolean);
        }

        return [];
    }, [props.blobConfig]);

    return (
        <div className={b()}>
            <div className={b('header')}>
                <h3>
                    {props.repository}:{props.tag}
                    &nbsp;
                    <ClipboardButton
                        view={'flat-secondary'}
                        text={`${props.tractoRegistryUrl}/${props.repository}:${props.tag}`}
                    />
                </h3>
                {props.dockerFile && (
                    <div className={b('dockerfile')}>
                        <TractoRegistryRepositoriesTagDescriptionDockerfileModal>
                            <TractoRegistryDockerfile dockerfile={props.dockerFile} />
                        </TractoRegistryRepositoriesTagDescriptionDockerfileModal>
                    </div>
                )}
            </div>
            <Flex direction="row" width="100%" gap={8}>
                <Flex grow="1" shrink="1" direction="column">
                    <DefinitionList
                        items={props.blobConfig.map((item) => {
                            switch (item.key) {
                                case 'Labels':
                                case 'Env':
                                    return {
                                        name: item.key,
                                        content: (
                                            <div className={b('env')}>
                                                {item.value.map((item: string) => (
                                                    <div className={b('env-item')}>{item}</div>
                                                ))}
                                            </div>
                                        ),
                                    };
                                default:
                                    return {
                                        name: item.key,
                                        content: item.value,
                                    };
                            }
                        })}
                    />
                </Flex>
                {crossLinks.length ? (
                    <Flex grow="1" shrink="1" direction="column">
                        <div className="elements-heading elements-heading_size_xs">Cross-links</div>
                        {crossLinks.map((item) => (
                            <div className={b('elements-section')}>{item}</div>
                        ))}
                    </Flex>
                ) : null}
            </Flex>
        </div>
    );
};
