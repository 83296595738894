export const getBlueprintIdFromPath = (path: string) => {
    if (path.startsWith('//')) {
        path = path.replace('//', '');
    }

    if (path.startsWith('/')) {
        path = path.replace('/', '');
    }

    return path;
};
