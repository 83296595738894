import React, {useMemo} from 'react';

import {useSelector} from 'react-redux';
import {selectModalCopySolutionNotebookState} from '../../../store/selectors/modals';
import {YTDFDialog} from '@ytsaurus-ui-platform/src/ui/components/Dialog';
import {getPath} from '@ytsaurus-ui-platform/src/ui/store/selectors/navigation';
import {copySolutionNotebook} from '../../../store/actions/notebook/content';
import {modalCopySolutionNotebookSlice} from '../../../store/slices/modals/modal-copy-solution-notebook';
import {useTractoDispatch} from '../../../../../store/tracto-dispatch';
import {useHistory} from 'react-router';
import {
    getCluster,
    getCurrentUserName,
} from '@ytsaurus-ui-platform/src/ui/store/selectors/global/index';
import {selectNotebookContent} from 'features/Jupyter/store/selectors/notebook';

type FormValues = {notebookPath: string};

export const CopySolutionNotebookDialog = () => {
    const dispatch = useTractoDispatch();
    const state = useSelector(selectModalCopySolutionNotebookState);
    const path = useSelector(getPath);
    const user = useSelector(getCurrentUserName);
    const cluster = useSelector(getCluster);
    const history = useHistory();
    const notebookContent = useSelector(selectNotebookContent)!;

    const handleIsApplyDisabled = () => {
        return false;
    };

    const copyPath = useMemo(() => {
        const notebookName = path.split('/').pop().split('.').shift();
        return `//tmp/temporary_notebooks/${user}-${notebookName}-${crypto.randomUUID().slice(0, 8)}`;
    }, [path, user]);

    return (
        <YTDFDialog<FormValues>
            visible={state.visible}
            headerProps={{title: 'Copy solution notebook'}}
            onAdd={(args) => {
                const {notebookPath} = args.getState().values;

                return dispatch(copySolutionNotebook({notebookPath, notebookContent}))
                    .then(({payload}) =>
                        payload
                            ? dispatch(modalCopySolutionNotebookSlice.actions.close())
                            : Promise.reject(),
                    )
                    .then(() => {
                        history.push(`/${cluster}/navigation?path=${notebookPath}`);
                    });
            }}
            onClose={() => {
                dispatch(modalCopySolutionNotebookSlice.actions.close());
            }}
            fields={[
                {
                    name: 'description',
                    type: 'block',
                    extras: {
                        children: (
                            <p>
                                You can't save changes in solution notebook, but you can make a copy
                            </p>
                        ),
                    },
                },
                {
                    name: 'notebookPath',
                    type: 'text',
                    caption: 'New notebook path',
                    tooltip: 'Path to new notebook',
                    initialValue: copyPath,
                },
            ]}
            isApplyDisabled={handleIsApplyDisabled}
        />
    );
};
