import React, {useEffect, useRef} from 'react';
import block from 'bem-cn-lite';

import type * as monaco from 'monaco-editor/esm/vs/editor/editor.api';
import {MarkdownCellSource} from '../MarkdownCellSource/MarkdownCellSource';

import 'highlight.js/styles/github.css';

import './JupyterCellSource.scss';
import {JupyterCellQa} from '../../../../../shared/qa';
import {setupMonacoJupyterExtensions} from 'components/TractoMonacoEditor/extensions';
import type {TractoNotebookCell, TractoNotebookMarkdownCell} from '../../types/version';
import {NotebookCellType} from '../../constants/cell';
import {getCellSQLConfig, getCellView} from '../../../../utils/cell';
import {SQLCellSource} from '../SQLCellSource/SQLCellSource';
import {CodeCellSource} from '../CodeCellSource/CodeCellSource';

const b = block('jupyter-cell-source');

export type BlockSourcePropsType = {
    cell: TractoNotebookCell;
    isDirty?: boolean;
    isEditable?: boolean;
    isRunning?: boolean;
    isFocused?: boolean;
    onChange: (value: string) => void;
    onFocus?: (event: React.FocusEvent<HTMLElement>) => void;
    onMarkdownBlur?: () => void;
    onImagePaste?: (value: {name: string; type: string; base64: string}) => void;
};

export function JupyterCellSource({
    cell,
    isDirty,
    isRunning,
    isFocused,
    isEditable,
    onChange,
    onFocus,
    onMarkdownBlur,
    onImagePaste,
}: BlockSourcePropsType) {
    const editorRef = useRef<monaco.editor.IStandaloneCodeEditor>();
    const cellView = getCellView(cell);
    const source = cellView.view_source;
    const type = cellView.view_cell_type;

    const executionCount = cell.execution_count;

    let content;

    useEffect(() => {
        if (isEditable) {
            editorRef?.current?.focus();
        }
    }, [isEditable]);

    switch (type) {
        case NotebookCellType.CODE: {
            content = (
                <CodeCellSource
                    editorRef={editorRef}
                    language={'python'}
                    source={source}
                    onChange={onChange}
                    setupExtensions={setupMonacoJupyterExtensions}
                />
            );

            break;
        }
        case NotebookCellType.CHYT:
        case NotebookCellType.QL:
        case NotebookCellType.SPYT:
        case NotebookCellType.YQL: {
            const sqlConfig = getCellSQLConfig(cell);

            content = (
                <SQLCellSource
                    editorRef={editorRef}
                    onChange={onChange}
                    sqlConfig={sqlConfig}
                    source={source}
                />
            );
            break;
        }
        case NotebookCellType.MARKDOWN: {
            const {attachments} = cell as TractoNotebookMarkdownCell;
            content = (
                <MarkdownCellSource
                    source={source}
                    onChange={onChange}
                    editorRef={editorRef}
                    isEditable={isEditable}
                    onMarkdownBlur={onMarkdownBlur}
                    attachments={attachments}
                    onImagePaste={onImagePaste}
                />
            );
            break;
        }
        default: {
            content = <div>{`Cell Type ${type} not supported...`}</div>;
        }
    }

    const executionNumber = isRunning ? '*' : executionCount;

    const headerContent = executionNumber ? `[${executionNumber}]: ` : null;
    return (
        <div
            data-qa={JupyterCellQa.JupyterCellSource}
            className={b({focused: isFocused, dirty: isDirty})}
            onFocus={onFocus}
        >
            <div className={b('row')}>
                <pre data-qa={JupyterCellQa.JupyterCellSourceHeader} className={b('header')}>
                    {headerContent}
                </pre>
                <div className={b('content')}>{content}</div>
            </div>
        </div>
    );
}
