import React from 'react';
import type * as monaco from 'monaco-editor';
import TractoMonacoEditor, {
    type TractoEditorLanguage,
    type TractoMonacoEditorComponentProps,
} from '../../../../components/TractoMonacoEditor/TractoMonacoEditor';
import type {MultilineString} from '@jupyterlab/nbformat';

type CodeCellSourceProps = {
    source: MultilineString;
    editorRef: React.MutableRefObject<monaco.editor.IStandaloneCodeEditor | undefined>;
    onChange: (value: string) => void;
    language: TractoEditorLanguage;
} & Pick<TractoMonacoEditorComponentProps, 'setupExtensions'>;

export const CodeCellSource: React.FC<CodeCellSourceProps> = (props: CodeCellSourceProps) => {
    const {source, editorRef, onChange, language, setupExtensions} = props;

    const value = Array.isArray(source) ? source.join('') : source;

    return (
        <TractoMonacoEditor
            editorRef={editorRef}
            language={language}
            value={value}
            onChange={onChange}
            setupExtensions={setupExtensions}
        />
    );
};
