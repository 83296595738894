import axios, {type AxiosInstance} from 'axios';
import {RunsApi} from './runs';
import {WorkflowApi} from './workflow';
import {TractoYT} from '../../../config/ui-config';

export class OrchestractoApi {
    private static _api: OrchestractoApi | null = null;

    readonly runs: RunsApi;
    readonly workflow: WorkflowApi;

    private readonly client: AxiosInstance;

    private constructor(cluster: string) {
        this.client = axios.create({baseURL: `/${cluster}/orc`});

        this.workflow = new WorkflowApi(this.client);
        this.runs = new RunsApi(this.client);
    }

    static get api() {
        if (!this._api) {
            this._api = new OrchestractoApi(TractoYT.cluster);
        }

        return this._api;
    }
}
