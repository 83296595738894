import React, {useEffect} from 'react';

import {useSelector} from 'react-redux';
import {selectModalCreateJupytState} from '../../../store/selectors/modals';
import {YTDFDialog, makeErrorFields} from '@ytsaurus-ui-platform/src/ui/components/Dialog';
import {modalCreateJupytSlice} from '../../../store/slices/modals/modal-create-jupyt';
import {Text} from '@gravity-ui/uikit';
import type {YTError} from '@ytsaurus-ui-platform/src/@types/types';
import {WaitForDefaultPoolTree} from '@ytsaurus-ui-platform/src/ui/hooks/global-pool-trees';
import {createJupyt} from '../../../store/actions/jupyt/jupyt';
import {useTractoDispatch} from '../../../../../store/tracto-dispatch';
import {mapListToJupytSecrets} from '../../../../../../shared/utils/jupyt/secrets';
import {JupytSecretType} from '../../../../../../shared/types/jupyt/secrets';
import {selectJupytDefaults} from 'features/Jupyter/store/selectors/jupyt';
import {loadPoolTreesIfNotLoaded} from '@ytsaurus-ui-platform/src/ui/store/actions/global';
import {useHotkeysScope} from '@ytsaurus-ui-platform/src/ui/hooks/use-hotkeysjs-scope';

type FormValues = {
    alias: string;
    tree: string;
    poolTrees: string[];
    pool: string;
    dockerImage: string;
    runAfterCreation: boolean;
    secrets: JupytSecretType[];
    yt_token: string;
};

type JupytCreateDialogProps = {
    onApply?: () => void;
    onAfterJupytCreate?: (alias: string) => Promise<void> | void;
};

const DEFAULT_JUPYT_POOL = 'research';
const YT_TOKEN_KEY = 'YT_TOKEN';

export const JupytCreateDialog: React.FC<JupytCreateDialogProps> = (
    props: JupytCreateDialogProps,
) => {
    const dispatch = useTractoDispatch();
    const state = useSelector(selectModalCreateJupytState);
    const jupytDefaults = useSelector(selectJupytDefaults);

    const [error, _setError] = React.useState<YTError | undefined>();

    useEffect(() => {
        dispatch(loadPoolTreesIfNotLoaded());
    }, []);

    useHotkeysScope('jupyt-create-dialog', state.visible);

    const handleAdd = React.useCallback((formValues: FormValues) => {
        const secrets = mapListToJupytSecrets(formValues.secrets);

        if (formValues.yt_token) {
            secrets[YT_TOKEN_KEY] = formValues.yt_token;
        }

        return dispatch(
            createJupyt({
                alias: formValues.alias,
                speclet_options: {
                    pool_trees: formValues.poolTrees,
                    pool: formValues.pool,
                    active: formValues.runAfterCreation,
                    jupyter_docker_image: formValues.dockerImage.trim(),
                },
                secrets,
            }),
        )
            .then(() => props.onAfterJupytCreate?.(formValues.alias))
            .then(() => dispatch(modalCreateJupytSlice.actions.close()))
            .then(() => props.onApply?.());
    }, []);

    return (
        <WaitForDefaultPoolTree>
            {({defaultPoolTree}) => (
                <YTDFDialog<FormValues>
                    visible={state.visible}
                    headerProps={{title: 'Create kernel'}}
                    onAdd={(args) => {
                        return handleAdd(args.getState().values);
                    }}
                    onClose={() => {
                        dispatch(modalCreateJupytSlice.actions.cancel());
                    }}
                    fields={[
                        {
                            name: 'alias',
                            type: 'text',
                            caption: 'Kernel alias',
                            required: true,
                            validator: (name: string) => {
                                if (/^[A-Za-z][\w-]*$/.test(name)) {
                                    return;
                                }

                                return 'Alias does not match regular expression "^[A-Za-z][\\w-]*$"';
                            },
                        },
                        {
                            name: 'dockerImage',
                            type: 'text',
                            caption: 'Docker image',
                            required: true,
                        },
                        {
                            name: 'poolTrees',
                            type: 'pool-tree',
                            caption: 'Pool trees',
                        },
                        {
                            name: 'pool',
                            type: 'pool',
                            caption: 'Pool',
                            required: true,
                            extras: ({poolTrees}: FormValues) => {
                                return {
                                    poolTrees,
                                    placeholder: 'Pool name...',
                                };
                            },
                        },
                        {
                            name: 'yt_token',
                            type: 'yt-token-input',
                            caption: 'YT Token',
                            extras: () => {
                                return {
                                    description: `YT token generated for kernel`,
                                };
                            },
                        },
                        {
                            name: 'secrets',
                            type: 'jupyter-secrets',
                            caption: 'Secrets',
                        } as any,
                        {
                            name: 'poolNotice',
                            type: 'block',
                            visibilityCondition: {
                                when: 'pool',
                                isActive(pool) {
                                    return !pool;
                                },
                            },
                            extras: {
                                children: (
                                    <Text color="info-heavy" variant="body-2">
                                        Select a pool to start the jupyt after creation.
                                    </Text>
                                ),
                            },
                        },
                        {
                            name: 'runAfterCreation',
                            type: 'tumbler',
                            caption: 'Start after creation',
                            visibilityCondition: {
                                when: 'pool',
                                isActive(v) {
                                    return Boolean(v);
                                },
                            },
                        },
                        ...makeErrorFields([error]),
                    ]}
                    initialValues={{
                        poolTrees: [defaultPoolTree],
                        runAfterCreation: true,
                        dockerImage: jupytDefaults.default_kernel_image,
                        pool: DEFAULT_JUPYT_POOL,
                        secrets: [],
                    }}
                />
            )}
        </WaitForDefaultPoolTree>
    );
};
