import {YTDFDialog} from '@ytsaurus-ui-platform/src/ui/components/Dialog';
import {
    loadDelegationsFromCypress,
    removeDelegation,
} from 'features/SecretStore/store/actions/delegations';
import {selectDelegationToDelete} from 'features/SecretStore/store/selectors/delegations';
import {delegationsSlice} from 'features/SecretStore/store/slices/delegationsSlice';
import React, {useCallback} from 'react';
import {useSelector} from 'react-redux';
import {useTractoDispatch} from '../../../../store/tracto-dispatch';

type FormValues = {};

export const RemoveDelegationDialogContainer: React.FC = () => {
    const dispatch = useTractoDispatch();
    const delegation = useSelector(selectDelegationToDelete);
    const onClose = useCallback(() => {
        dispatch(delegationsSlice.actions.setDelegationToDelete({delegation: null}));
    }, []);

    if (!delegation) {
        return null;
    }

    return (
        <YTDFDialog<FormValues>
            pristineSubmittable
            visible={true}
            headerProps={{title: 'Remove delegation'}}
            footerProps={{textApply: 'Remove'}}
            onClose={onClose}
            onAdd={async () => {
                await dispatch(
                    removeDelegation({
                        delegation_id: delegation.delegation_id,
                    }),
                );
                await dispatch(loadDelegationsFromCypress());
            }}
            initialValues={{
                consumer_identity: delegation.consumer_identity,
                consumer_type: delegation.consumer_type,
            }}
            fields={[
                {
                    name: 'consumer_identity',
                    type: 'path',
                    caption: 'Consumer path',
                    extras: {
                        disabled: true,
                    },
                },
                {
                    name: 'consumer_type',
                    type: 'radio',
                    caption: 'Consumer type',
                    extras: {
                        disabled: true,
                        options: [{value: 'workflow', label: 'Workflow'}],
                    },
                },
            ]}
        />
    );
};
