import React, {useCallback, useMemo} from 'react';
import './BlueprintAttachedFilesComponent.scss';
import block from 'bem-cn-lite';
import {useTractoDispatch} from '../../../../store/tracto-dispatch';
import {selectAttachedFiles} from 'features/ImageBuilder/store/selectors/blueprint';
import {useSelector} from 'react-redux';
import {blueprintSlice} from 'features/ImageBuilder/store/slices/blueprintSlice';
import {
    AttachedFile,
    BlueprintAttachedFilesListComponent,
} from '../BlueprintAttachedFilesListComponent/BlueprintAttachedFilesListComponent';

const b = block('blueprint-attached-files');

type BlueprintAttachedFilesComponentProps = {
    className?: string;
};

export const BlueprintAttachedFilesComponent: React.FC<BlueprintAttachedFilesComponentProps> = ({
    className,
}) => {
    const dispatch = useTractoDispatch();

    const attachedFiles = useSelector(selectAttachedFiles);

    const value = useMemo(() => {
        return attachedFiles.map((file) => {
            return {
                localPath: file.local,
                cypressPath: file.yt_path,
            };
        });
    }, [attachedFiles]);

    const onUpdate = useCallback((secrets: AttachedFile[]) => {
        const attachedFiles = secrets.map((item) => {
            return {
                local: item.localPath,
                yt_path: item.cypressPath,
            };
        });
        dispatch(blueprintSlice.actions.setAttachedFiles({attachedFiles}));
    }, []);

    return (
        <div className={b(null, className)}>
            <div className="elements-heading elements-heading_size_xs">Attached files</div>
            <BlueprintAttachedFilesListComponent value={value} onUpdate={onUpdate} />
        </div>
    );
};
