import React from 'react';
import {useSelector} from 'react-redux';
import {Spin} from '@gravity-ui/uikit';
import {JupyterStatus} from 'features/Jupyter/components/JupytSelect/JupyterStatus/JupyterStatus';
import {
    selectCurrentJupytAlias,
    selectJupyterKernelStatus,
} from 'features/Jupyter/store/selectors/notebook';

export const JupyterStatusContainer = () => {
    const status = useSelector(selectJupyterKernelStatus);
    const currentAlias = useSelector(selectCurrentJupytAlias);
    const isLoading = status === 'unknown' && currentAlias;

    return <JupyterStatus status={status} icon={isLoading ? <Spin size="xs" /> : null} />;
};
