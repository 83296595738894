import {TractoThunkDispatch} from '../../../../../store/tracto-dispatch';
import {TractoRootState} from '../../../../../store/reducers';
import {getPath} from '@ytsaurus-ui-platform/src/ui/store/selectors/navigation';
import {getIsOldWorkflow} from '../../../utils/old-workflow';
import {WorkflowRunListItem} from '../../../types/runs';
import {isWorkflowAPIEnabled} from '../../selectors/workflow';
import {OrchestractoApi} from '../../../api';
import type {WorkflowExecutionStatus} from '../../../types/workflow';
import {
    getCurrentWorkflowLocationConfig,
    getCurrentWorkflowRunsPath,
} from '../../../utils/workflow';
import {ytApiV3} from '@ytsaurus-ui-platform/src/ui/rum/rum-wrap-api';
import ypath from '@ytsaurus-ui-platform/src/ui/common/thor/ypath';
import {runsSlice} from '../../slices/runs';
import CancelHelper from '@ytsaurus-ui-platform/src/ui/utils/cancel-helper';
import type {RelativeRangeDatePickerValue} from '@gravity-ui/date-components';
import {selectRunsDateFilter, selectRunsLimit} from '../../selectors/runs';
import {prepareRunsDateFilter} from '../../../utils/runs/filter';

type ListResponse = {
    key: string;
    creation_time: string;
    orc_trigger_type?: string;
    orc_result_status?: WorkflowExecutionStatus;
};

const cancelHelper = new CancelHelper();

export const loadWorkflowRunsFormCypress = (params?: {
    limit?: string;
    filter?: RelativeRangeDatePickerValue;
}) => {
    return async (dispatch: TractoThunkDispatch, getState: () => TractoRootState) => {
        const path = getPath(getState());
        const limit = params?.limit ?? selectRunsLimit(getState());
        const filter = params?.filter ?? selectRunsDateFilter(getState());

        const isOldWorkflow = await getIsOldWorkflow(path);

        let runs: WorkflowRunListItem[];

        if (isWorkflowAPIEnabled(getState()) && !isOldWorkflow) {
            const response = await OrchestractoApi.api.runs.listRuns({
                workflowPath: path,
                cancelToken: cancelHelper.removeAllAndGenerateNextToken(),
                limit,
                filter: prepareRunsDateFilter(filter),
            });

            runs = response.runs.map((run): WorkflowRunListItem => {
                return {
                    key: run.run_id,
                    startTime: run.created_at,
                    triggerType: run.trigger_type,
                    status: run.stage as WorkflowExecutionStatus,
                };
            });
        } else {
            const {workflowId, orchestractoRootPath} = getCurrentWorkflowLocationConfig(
                getPath(getState()),
            );

            const runPath = getCurrentWorkflowRunsPath({orchestractoRootPath, workflowId});

            const attributes: Array<keyof ListResponse> = [
                'creation_time',
                'key',
                'orc_trigger_type',
                'orc_result_status',
            ];

            const result = await ytApiV3.list({
                cancellation: cancelHelper.removeAllAndSave,
                path: runPath,
                attributes,
            });

            runs = result.map((item: any) => {
                const attributes: ListResponse = ypath.getAttributes(item);

                return {
                    key: attributes.key,
                    status: attributes.orc_result_status || 'unknown',
                    triggerType: attributes.orc_trigger_type,
                    startTime: attributes.creation_time,
                };
            });
        }

        dispatch(
            runsSlice.actions.setRuns({
                runs: runs.sort((a, b) => b.startTime.localeCompare(a.startTime)),
            }),
        );
    };
};
