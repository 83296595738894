import React, {ComponentProps, useCallback} from 'react';
import {useTractoDispatch} from '../../../../store/tracto-dispatch';
import {
    BlueprintMainImageParamsComponent,
    BlueprintSecondaryImageParamsComponent,
} from 'features/ImageBuilder/components/BlueprintImageParamsComponent/BlueprintImageParamsComponent';
import {blueprintSlice} from 'features/ImageBuilder/store/slices/blueprintSlice';
import {useSelector} from 'react-redux';
import {
    selectCpuLimit,
    selectImageName,
    selectImageRegistry,
    selectMemoryLimit,
} from 'features/ImageBuilder/store/selectors/blueprint';

type BlueprintMainImageParamsContainerProps = Pick<
    ComponentProps<typeof BlueprintMainImageParamsComponent>,
    'className'
>;

export const BlueprintMainImageParamsContainer: React.FC<BlueprintMainImageParamsContainerProps> = (
    ownProps,
) => {
    const dispatch = useTractoDispatch();

    const onImageNameUpdate = useCallback((value: string) => {
        dispatch(blueprintSlice.actions.setImageName({imageName: value}));
    }, []);

    const imageRegistry = useSelector(selectImageRegistry);

    const imageName = useSelector(selectImageName);

    const props: ComponentProps<typeof BlueprintMainImageParamsComponent> = {
        imageRegistry,
        imageName,
        onImageNameUpdate,
        ...ownProps,
    };

    return <BlueprintMainImageParamsComponent {...props} />;
};

type BlueprintSecondaryImageParamsContainerProps = Pick<
    ComponentProps<typeof BlueprintSecondaryImageParamsComponent>,
    'className'
>;

export const BlueprintSecondaryImageParamsContainer: React.FC<
    BlueprintSecondaryImageParamsContainerProps
> = (ownProps) => {
    const dispatch = useTractoDispatch();

    const cpuLimit = useSelector(selectCpuLimit);
    const memoryLimit = useSelector(selectMemoryLimit);

    const onCpuLimitUpdate = useCallback((value: string) => {
        dispatch(blueprintSlice.actions.setCpuLimit({cpuLimit: Number(value)}));
    }, []);

    const onMemoryLimitUpdate = useCallback((value: string) => {
        dispatch(blueprintSlice.actions.setMemoryLimit({memoryLimit: Number(value)}));
    }, []);

    const props: ComponentProps<typeof BlueprintSecondaryImageParamsComponent> = {
        cpuLimit,
        memoryLimit,
        onCpuLimitUpdate,
        onMemoryLimitUpdate,
        ...ownProps,
    };

    return <BlueprintSecondaryImageParamsComponent {...props} />;
};
