import {FormApi, YTDFDialog} from '@ytsaurus-ui-platform/src/ui/components/Dialog';
import {getPath} from '@ytsaurus-ui-platform/src/ui/store/selectors/navigation';
import {
    createDelegation,
    loadDelegationsFromCypress,
} from 'features/SecretStore/store/actions/delegations';
import {selectshowAddDelegationDialog} from 'features/SecretStore/store/selectors/delegations';
import {delegationsSlice} from 'features/SecretStore/store/slices/delegationsSlice';
import React, {useCallback} from 'react';
import {useSelector} from 'react-redux';
import {useTractoDispatch} from '../../../../store/tracto-dispatch';

type CreateDelegationDialogContainerProps = {};

type FormValues = {
    consumer_type: string;
    consumer_identity: string;
};

export const CreateDelegationDialogContainer: React.FC<
    CreateDelegationDialogContainerProps
> = () => {
    const dispatch = useTractoDispatch();
    const path = useSelector(getPath);
    const visible = useSelector(selectshowAddDelegationDialog);
    const onClose = useCallback(() => {
        dispatch(delegationsSlice.actions.hideAddDelegationDialog());
    }, []);

    return (
        <YTDFDialog<FormValues>
            pristineSubmittable
            visible={visible}
            headerProps={{title: 'Create delegation'}}
            footerProps={{textApply: 'Create'}}
            onClose={onClose}
            onAdd={(form: FormApi<FormValues>) => {
                const {consumer_identity, consumer_type} = form.getState().values;

                return dispatch(
                    createDelegation({consumer_identity, consumer_type, secret_path: path}),
                ).then(() => {
                    dispatch(loadDelegationsFromCypress());
                });
            }}
            initialValues={{
                consumer_identity: '',
                consumer_type: 'workflow',
            }}
            fields={[
                {
                    name: 'consumer_identity',
                    type: 'path',
                    required: true,
                    caption: 'Consumer path',
                },
                {
                    name: 'consumer_type',
                    type: 'radio',
                    caption: 'Consumer type',
                    extras: {
                        disabled: true,
                        options: [{value: 'workflow', label: 'Workflow'}],
                    },
                },
            ]}
        />
    );
};
