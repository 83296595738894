import React from 'react';
import {ytApiV3} from '@ytsaurus-ui-platform/src/ui/rum/rum-wrap-api';
import {TractoThunkDispatch} from '../../../../store/tracto-dispatch';
import {TractoRootState} from 'store/reducers';
import {getPath} from '@ytsaurus-ui-platform/src/ui/store/selectors/navigation';
import type {SecretStore} from 'features/SecretStore/types/secrets';
import {createTractoAsyncThunk} from '../../../../store/tracto-async-thunk';
import {getCluster} from '@ytsaurus-ui-platform/src/ui/store/selectors/global';
import {wrapApiPromiseByToaster} from '@ytsaurus-ui-platform/src/ui/utils/utils';
import {genNavigationUrl} from '@ytsaurus-ui-platform/src/ui/utils/navigation/navigation';
import Link from '@ytsaurus-ui-platform/src/ui/components/Link/Link';
import {secretsSlice} from '../slices/secretsSlice';
import {selectSecrets} from '../selectors/secrets';

export const loadSecretsFromCypress = () => {
    return async (dispatch: TractoThunkDispatch, getState: () => TractoRootState) => {
        const path = getPath(getState());

        const secretStore: SecretStore = await ytApiV3.get({path});

        const secrets = Object.entries(secretStore.secrets).map(([secretKey, secretValue]) => {
            return {
                name: secretKey,
                value: secretValue.value,
            };
        });

        dispatch(secretsSlice.actions.setSecrets({secrets}));
    };
};

export const saveSecretsToCypress = () => {
    return (_dispatch: TractoThunkDispatch, getState: () => TractoRootState) => {
        const path = getPath(getState());
        const secrets = selectSecrets(getState());

        const secretStore: SecretStore = secrets.reduce(
            (acc: SecretStore, item) => {
                if (item.name) {
                    acc.secrets[item.name] = {
                        value: item.value,
                    };
                }
                return acc;
            },
            {secrets: {}},
        );

        return ytApiV3.set({path}, secretStore);
    };
};

const CREATE_SECRET_STORE_NODE_ACTION = 'navigation.modal.create-secret-store';

export const createSecretStoreInCypress = createTractoAsyncThunk<
    void,
    {path: string; secrets: unknown}
>(CREATE_SECRET_STORE_NODE_ACTION, async ({path, secrets = {}}, thunkAPI) => {
    const promise = ytApiV3.create({
        path,
        type: 'document',
        attributes: {
            tracto_type: 'secret_store',
            inherit_acl: false,
            value: {secrets},
            acl: [
                {
                    action: 'allow',
                    inheritance_mode: 'object_only',
                    permissions: ['read', 'write', 'administer', 'remove', 'manage'],
                    subjects: ['owner'],
                },
            ],
        },
    });

    const cluster = getCluster(thunkAPI.getState());
    return wrapApiPromiseByToaster(promise, {
        toasterName: path,
        timeout: 10000,
        successTitle: 'Secret store created',
        errorTitle: 'Secret store creation failure',
        successContent: () => {
            return (
                <React.Fragment>
                    Secret store <Link url={genNavigationUrl({cluster, path})}>{path}</Link>{' '}
                    successfully created
                </React.Fragment>
            );
        },
        errorContent: (error) => {
            return `[code ${error.code}] ${error.message}`;
        },
    });
});
