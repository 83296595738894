import {NotebookPlaceholder} from 'features/Jupyter/components/NotebookPlaceholder/NotebookPlaceholder';
import React, {useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {readFileAsString} from 'utils/file';
import {addNotebookCell} from '../../store/actions/notebook/cell';
import {NotebookCellType} from '../../constants/cell';
import {setNotebookContent} from '../../store/actions/notebook/content';

const useNotebookPlaceholderProps = () => {
    const dispatch = useDispatch();

    const onFileChange = useCallback(async (value: FileList | null) => {
        const file = value && value[0];

        if (!file) {
            return;
        }

        const notebook = JSON.parse(await readFileAsString(file));

        dispatch(setNotebookContent({notebook, save: false}));
    }, []);

    const onAddFirstCellClick = useCallback(() => {
        dispatch(addNotebookCell({currentIndex: 0, type: NotebookCellType.CODE}));
    }, []);

    return {
        onFileChange,
        onAddFirstCellClick,
    };
};

export const NotebookPlaceholderContainer: React.FC = () => {
    const notebookPlaceholderProps = useNotebookPlaceholderProps();

    return <NotebookPlaceholder {...notebookPlaceholderProps} />;
};
