import {createSlice} from '@reduxjs/toolkit';

type InitialState = {
    open: boolean;
};

const initialState: InitialState = {
    open: false,
};

export const hotkeysSlice = createSlice({
    name: 'hotkeys',
    initialState,
    reducers: {
        open(state) {
            state.open = true;
        },
        close(state) {
            state.open = false;
        },
        reset() {
            return initialState;
        },
    },
});
