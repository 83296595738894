import {ServerConnection} from '@jupyterlab/services';
import {
    getHttpRequestInit,
    getJupyterBaseUrl,
    getJupyterWSBaseUrl,
} from '../../../../shared/utils/jupyter/jupyter-api';

export const getJupyterApiServerSettings = ({
    cluster,
    alias,
    path,
}: {
    cluster: string;
    alias: string;
    path: string;
}) => {
    return ServerConnection.makeSettings({
        baseUrl: getJupyterBaseUrl({cluster, alias}),
        wsUrl: getJupyterWSBaseUrl({cluster, alias}),
        init: getHttpRequestInit({path}),
    });
};
