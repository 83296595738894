import type {PayloadAction} from '@reduxjs/toolkit';
import {createSlice} from '@reduxjs/toolkit';
import cloneDeep_ from 'lodash/cloneDeep';
import * as nbformat from '@jupyterlab/nbformat';
import {IOutput, MultilineString} from '@jupyterlab/nbformat';
import {extractCellId, setCellId} from '../../utils/cell/common';
import {getItemStrict} from '../../utils/strict-selectors';
import type {IUpdateDisplayDataMsg} from '@jupyterlab/services/lib/kernel/messages';
import {CheckPermissionResult} from '@ytsaurus-ui-platform/src/shared/utils/check-permission';
import type {
    TractoNotebookCell,
    TractoNotebookCellSQLConfig,
    TractoNotebookContent,
} from '../../types/version';
import {getCellView} from '../../../../utils/cell';

type NotebookState = {
    editableCellId: string;
    focusedCellId: string;
    runningCells: Record<string, boolean>;
    dirtyCells: Record<string, TractoNotebookCell>;
    content: TractoNotebookContent | undefined;
    savedContent: TractoNotebookContent | undefined;
    writePermission: CheckPermissionResult['action'];
    isSavingInProgress: boolean;
    bufferCell: TractoNotebookCell | null;
};

const initialState: NotebookState = {
    editableCellId: '',
    focusedCellId: '',
    runningCells: {},
    dirtyCells: {},
    content: undefined,
    savedContent: undefined,
    writePermission: 'deny',
    isSavingInProgress: false,
    bufferCell: null,
};

const shiftCell = (cells: TractoNotebookCell[], oldIndex: number, newIndex: number) => {
    const replacedCell = cells[newIndex];
    cells[newIndex] = cells[oldIndex];
    cells[oldIndex] = replacedCell;
};

const getCellById = (cells: TractoNotebookCell[], cellId: string) => {
    return cells.find((cell) => extractCellId(cell) === cellId);
};

const getCellIndex = (cells: TractoNotebookCell[], cellId: string) => {
    return cells.findIndex((cell) => extractCellId(cell) === cellId);
};

export const notebookSlice = createSlice({
    name: 'jupyter.notebook',
    initialState,
    reducers: {
        moveCellUp: (state, action: PayloadAction<{currentIndex: number}>) => {
            const notebook = getItemStrict(state.content);

            const oldIndex = action.payload.currentIndex;
            const newIndex = Math.max(action.payload.currentIndex - 1, 0);

            shiftCell(notebook.cells, oldIndex, newIndex);
        },
        moveCellDown: (state, action: PayloadAction<{currentIndex: number}>) => {
            const notebook = getItemStrict(state.content);
            const newIndex = Math.min(action.payload.currentIndex + 1, notebook.cells.length - 1);

            shiftCell(notebook.cells, action.payload.currentIndex, newIndex);
        },
        changeCellPosition: (
            state,
            action: PayloadAction<{oldIndex: number; newIndex: number}>,
        ) => {
            const notebook = getItemStrict(state.content);
            shiftCell(notebook.cells, action.payload.oldIndex, action.payload.newIndex);
        },
        setCellSource: (
            state,
            action: PayloadAction<{
                cellId: string;
                source: MultilineString;
                view_source: MultilineString;
            }>,
        ) => {
            const cellId = action.payload.cellId;
            const notebook = getItemStrict(state.content);
            const cell = getItemStrict(getCellById(notebook.cells, cellId));

            getCellView(cell).view_source = action.payload.view_source;

            cell.source = action.payload.source;
        },
        updateDirtyCells: (state, action: PayloadAction<{cell: TractoNotebookCell}>) => {
            state.dirtyCells[action.payload.cell.id] = cloneDeep_(action.payload.cell);
        },
        removeDirtyCell: (state, action: PayloadAction<{cellId: string}>) => {
            delete state.dirtyCells[action.payload.cellId];
        },
        setCellOutputs: (state, action: PayloadAction<{cellId: string; outputs: IOutput[]}>) => {
            const notebook = getItemStrict(state.content);
            const cell = getCellById(notebook.cells, action.payload.cellId);

            if (cell && nbformat.isCode(cell)) {
                cell.outputs = action.payload.outputs;
            }
        },
        addCellOutput: (state, action: PayloadAction<{cellId: string; output: IOutput}>) => {
            const notebook = getItemStrict(state.content);
            const cell = getCellById(notebook.cells, action.payload.cellId);

            if (cell && nbformat.isCode(cell)) {
                cell.outputs.push(action.payload.output);
            }
        },
        updateCellDisplayData: (state, action: PayloadAction<{msg: IUpdateDisplayDataMsg}>) => {
            const notebook = getItemStrict(state.content);

            notebook.cells.forEach((cell) => {
                if (nbformat.isCode(cell)) {
                    cell.outputs.forEach((output) => {
                        if (
                            'metadata' in output &&
                            (output.metadata as any)?.transient?.display_id ===
                                action.payload.msg.content.transient.display_id
                        ) {
                            output.data = action.payload.msg.content.data;
                        }
                    });
                }
            });
        },
        setCellExecuteCount: (
            state,
            action: PayloadAction<{cellId: string; execution_count: nbformat.ExecutionCount}>,
        ) => {
            const notebook = getItemStrict(state.content);
            const cell = getCellById(notebook.cells, action.payload.cellId);

            if (cell && nbformat.isCode(cell)) {
                cell.execution_count = action.payload.execution_count;
            }
        },
        addCellAfter: (
            state,
            action: PayloadAction<{
                currentIndex: number;
                cell: TractoNotebookCell;
            }>,
        ) => {
            const {currentIndex, cell} = action.payload;
            const notebook = getItemStrict(state.content);
            notebook.cells.splice(currentIndex + 1, 0, cell);
        },
        deleteCell: (state, action: PayloadAction<{currentIndex: number}>) => {
            const notebook = getItemStrict(state.content);
            notebook.cells = notebook.cells.filter(
                (_cell, index) => action.payload.currentIndex !== index,
            );

            let newFocusedCellId = '';

            for (
                let index = action.payload.currentIndex;
                index >= 0 && !newFocusedCellId;
                index--
            ) {
                const cell = notebook.cells[index];

                if (cell) {
                    newFocusedCellId = extractCellId(cell);
                }
            }

            state.focusedCellId = newFocusedCellId;
        },
        setNotebook: (
            state,
            action: PayloadAction<{
                notebook: TractoNotebookContent;
                writePermission?: CheckPermissionResult['action'];
                save?: boolean;
            }>,
        ) => {
            state.content = action.payload.notebook;

            if (action.payload.save) {
                state.savedContent = action.payload.notebook;
            }

            if (action.payload.writePermission) {
                state.writePermission = action.payload.writePermission;
            }
        },
        updateSavedNotebookContent: (state) => {
            state.savedContent = state.content;
        },
        clearNotebookState: () => initialState,
        startCellExecution: (state, action: PayloadAction<{cellId: string}>) => {
            const notebook = getItemStrict(state.content);
            const cell = getCellById(notebook.cells, action.payload.cellId);

            if (cell && nbformat.isCode(cell)) {
                state.runningCells[action.payload.cellId] = true;
                cell.outputs = [];
            }
        },
        finishCellExecution: (state, action: PayloadAction<{cellId: string}>) => {
            delete state.runningCells[action.payload.cellId];
            delete state.dirtyCells[action.payload.cellId];
        },
        runAllCells(state) {
            const notebook = getItemStrict(state.content);
            state.runningCells = notebook.cells.reduce((acc: Record<string, boolean>, cell) => {
                if (nbformat.isCode(cell)) {
                    acc[extractCellId(cell)] = true;
                }
                return acc;
            }, {});
            state.dirtyCells = {};
        },
        interruptExecution: (state) => {
            state.runningCells = {};
        },
        setFocusedCellById: (state, action: PayloadAction<{cellId: string}>) => {
            state.focusedCellId = action.payload.cellId;
        },
        setFocusedCellByIndex: (state, action: PayloadAction<{index: number}>) => {
            const notebook = getItemStrict(state.content);
            const cell =
                notebook.cells[action.payload.index] || notebook.cells[notebook.cells.length - 1];

            if (cell) {
                state.focusedCellId = extractCellId(cell);
            }
        },
        changeCellType: (
            state,
            action: PayloadAction<{cell: TractoNotebookCell; cellId: string}>,
        ) => {
            const notebook = getItemStrict(state.content);
            const index = getCellIndex(notebook.cells, action.payload.cellId);

            notebook.cells[index] = action.payload.cell;
        },
        upFromCurrentCell: (state) => {
            const notebook = getItemStrict(state.content);
            const index = getCellIndex(notebook.cells, state.focusedCellId);

            if (index > 0) {
                state.focusedCellId = extractCellId(notebook.cells[index - 1]);
            }
        },
        downFromCurrentCell: (state) => {
            const notebook = getItemStrict(state.content);
            const index = getCellIndex(notebook.cells, state.focusedCellId);

            if (index !== -1 && index < notebook.cells.length - 1) {
                state.focusedCellId = extractCellId(notebook.cells[index + 1]);
            }
        },
        makeCellEditable(state) {
            state.editableCellId = state.focusedCellId;
        },
        removeCellEditable(state) {
            state.editableCellId = '';
        },
        setCellAttachment(
            state,
            action: PayloadAction<{cellId: string; name: string; type: string; base64: string}>,
        ) {
            const notebook = getItemStrict(state.content);
            const cell = getCellById(notebook.cells, action.payload.cellId);

            if (cell && nbformat.isMarkdown(cell)) {
                cell.attachments = cell.attachments || {};
                cell.attachments[action.payload.name] = {
                    [action.payload.type]: action.payload.base64,
                };
            }
        },
        setSavingInProgress(state, action: PayloadAction<{isSavingInProgress: boolean}>) {
            state.isSavingInProgress = action.payload.isSavingInProgress;
        },
        clearCellsOutputs(state) {
            const notebook = getItemStrict(state.content);
            notebook.cells.forEach((cell) => {
                if (nbformat.isCode(cell)) {
                    cell.outputs = [];
                }
            });
        },
        setBufferCell(state, action: PayloadAction<{cell: TractoNotebookCell}>) {
            state.bufferCell = cloneDeep_(action.payload.cell);
        },
        pasteBufferCell(
            state,
            action: PayloadAction<{
                currentIndex: number;
            }>,
        ) {
            if (!state.bufferCell) {
                return;
            }

            const cell = cloneDeep_(state.bufferCell);

            setCellId(cell, crypto.randomUUID());

            const notebook = getItemStrict(state.content);

            notebook.cells.splice(action.payload.currentIndex + 1, 0, cell);
        },
        setCellSQLConfig(
            state,
            action: PayloadAction<{cellId: string; sql: TractoNotebookCellSQLConfig}>,
        ) {
            const {cellId, sql} = action.payload;
            const notebook = getItemStrict(state.content);
            const cell = getItemStrict(getCellById(notebook.cells, cellId));

            getCellView(cell).sql = sql;
        },
    },
});
