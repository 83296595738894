import React from 'react';
import {JupyterCellQa} from '../../../../../shared/qa';
import {Icon, Select} from '@gravity-ui/uikit';
import {NotebookCellType} from '../../constants/cell';
import Button from '@ytsaurus-ui-platform/src/ui/components/Button/Button';
import {ArrowDown, ArrowUp, TrashBin} from '@gravity-ui/icons';
import type {TractoNotebookCell} from '../../types/version';
import {ACTIONS_CONTAINER_CONTROL_SIZE, ACTIONS_CONTAINER_ICON_SIZE} from './constants/size';
import {SUPPORTED_CELL_TYPES} from './constants/cell-types';
import {ExecuteButton} from './controls/ExecuteButton/ExecuteButton';
import {isCodeCellType, isSQLCell} from '../../../../utils/cell';
import block from 'bem-cn-lite';

import {JupyterCellSQLControlsContainer} from '../../containers/JupyterCellSQLControlsContainer/JupyterCellSQLControlsContainer';
import AddAboveIcon from '../../../../assets/img/svg/add-above.svg';
import AddBelowIcon from '../../../../assets/img/svg/add-below.svg';
import {JupyterButtonWithHotkeys} from '../JupyterButtonWithHotkeys/JupyterButtonWithHotkeys';

import './JupyterCellControls.scss';
import {CellDropdown} from './controls/CellDropdown/CellDropdown';
import {dropdownButtonProps, getDropdownItems} from './constants/dropdown';

const b = block('cell-controls');

type JupyterCellControlsProps = {
    isRunning: boolean;
    className: string;
    onRunClick: () => void;
    onCancelClick: () => void;
    onArrowUpClick: () => void;
    onArrowDownClick: () => void;
    onAddCellAboveClick: () => void;
    onAddCellBelowClick: () => void;
    onTrashBinClick: () => void;
    onChangeCellType: (value: string[]) => void;
    cellType: TractoNotebookCell['cell_type'];
    cellViewType: NotebookCellType;
    cellId: string;
};

export const JupyterCellControls: React.FC<JupyterCellControlsProps> = (
    props: JupyterCellControlsProps,
) => {
    const {
        onChangeCellType,
        onRunClick,
        onCancelClick,
        onArrowUpClick,
        onArrowDownClick,
        onTrashBinClick,
        onAddCellAboveClick,
        onAddCellBelowClick,
        isRunning,
        cellType,
        cellViewType,
        className,
        cellId,
    } = props;

    const handleActionContainerClick = React.useCallback((event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
    }, []);

    const dropdownItems = React.useMemo(() => getDropdownItems(cellId), [cellId]);

    return (
        <div className={b(null, className)}>
            <div className={b('left-controls')} onClick={handleActionContainerClick}>
                {isSQLCell(cellViewType) ? (
                    <JupyterCellSQLControlsContainer cellId={cellId} />
                ) : null}
            </div>
            <div className={b('right-controls')} onClick={handleActionContainerClick}>
                {isCodeCellType(cellType) ? (
                    <ExecuteButton
                        qa={JupyterCellQa.JupyterCellExecuteButton}
                        onRunClick={onRunClick}
                        onCancelClick={onCancelClick}
                        isRunning={isRunning}
                    />
                ) : null}
                <Select
                    qa={JupyterCellQa.JupyterCellTypeSelect}
                    value={[cellViewType]}
                    onUpdate={onChangeCellType}
                    size="s"
                    width={110}
                    options={SUPPORTED_CELL_TYPES}
                />
                <Button
                    qa={JupyterCellQa.JupyterCellMoveUpButton}
                    view="flat-secondary"
                    onClick={onArrowUpClick}
                    withTooltip
                    size={ACTIONS_CONTAINER_CONTROL_SIZE}
                    tooltipProps={{
                        content: 'Move this cell up',
                        placement: 'top',
                    }}
                >
                    <Icon data={ArrowUp} size={ACTIONS_CONTAINER_ICON_SIZE} />
                </Button>
                <Button
                    qa={JupyterCellQa.JupyterCellMoveDownButton}
                    view="flat-secondary"
                    onClick={onArrowDownClick}
                    withTooltip
                    size={ACTIONS_CONTAINER_CONTROL_SIZE}
                    tooltipProps={{
                        content: 'Move this cell down',
                        placement: 'top',
                    }}
                >
                    <Icon data={ArrowDown} size={ACTIONS_CONTAINER_ICON_SIZE} />
                </Button>
                <JupyterButtonWithHotkeys
                    id="insertAbove"
                    qa={JupyterCellQa.JupyterCellAddCellBeforeButton}
                    view="flat-secondary"
                    onClick={onAddCellAboveClick}
                    size={ACTIONS_CONTAINER_CONTROL_SIZE}
                >
                    <Icon data={AddAboveIcon} size={14} />
                </JupyterButtonWithHotkeys>
                <JupyterButtonWithHotkeys
                    id="insertBelow"
                    qa={JupyterCellQa.JupyterCellAddCellAfterButton}
                    view="flat-secondary"
                    onClick={onAddCellBelowClick}
                    size={ACTIONS_CONTAINER_CONTROL_SIZE}
                >
                    <Icon data={AddBelowIcon} size={14} />
                </JupyterButtonWithHotkeys>
                <JupyterButtonWithHotkeys
                    id="delete"
                    qa={JupyterCellQa.JupyterCellDeleteButton}
                    view="flat-secondary"
                    onClick={onTrashBinClick}
                    size={ACTIONS_CONTAINER_CONTROL_SIZE}
                >
                    <Icon data={TrashBin} size={ACTIONS_CONTAINER_ICON_SIZE} />
                </JupyterButtonWithHotkeys>
                <CellDropdown items={dropdownItems} button={dropdownButtonProps} />
            </div>
        </div>
    );
};
