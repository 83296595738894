import {ytApiV3} from '@ytsaurus-ui-platform/src/ui/rum/rum-wrap-api';
import {getCurrentWorkflowLocationConfig} from './workflow';

export const getIsOldWorkflow = (path: string) => {
    return ytApiV3.exists({
        parameters: {
            path: `${getCurrentWorkflowLocationConfig(path).orchestractoRootPath}/workflows/@orc_allow_create_workflows`,
        },
    });
};
