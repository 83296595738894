import {type JupytApi, jupytApiAction} from '../../../api/jupyt';
import {TractoThunkDispatch} from '../../../../../store/tracto-dispatch';
import {TractoRootState} from '../../../../../store/reducers';
import {JupytSecretsMapType} from '../../../../../../shared/types/jupyt/secrets';
import {getCluster} from '@ytsaurus-ui-platform/src/ui/store/selectors/global';
import CancelHelper from '@ytsaurus-ui-platform/src/ui/utils/cancel-helper';
import {isDeveloper} from '@ytsaurus-ui-platform/src/ui/store/selectors/global/is-developer';

const cancelHelper = new CancelHelper();

type GetJupytSecretsArgs = {
    alias: Extract<JupytApi, {action: 'get_secrets'}>['params']['alias'];
};

export function getJupytSecrets(args: GetJupytSecretsArgs) {
    return async (
        _dispatch: TractoThunkDispatch,
        getState: () => TractoRootState,
    ): Promise<JupytSecretsMapType> => {
        const state = getState();
        const cluster = getCluster(state);
        const isAdmin = isDeveloper(state);

        const respose = await jupytApiAction(
            'get_secrets',
            cluster,
            {
                alias: args.alias,
            },
            {isAdmin, cancelToken: cancelHelper.removeAllAndGenerateNextToken()},
        );

        return respose.result || {};
    };
}

type SetJupytSecretsArgs = {
    alias: Extract<JupytApi, {action: 'set_secrets'}>['params']['alias'];
    secrets: Extract<JupytApi, {action: 'set_secrets'}>['params']['secrets'];
};

export function setJupytSecrets({alias, secrets}: SetJupytSecretsArgs) {
    return (_dispatch: TractoThunkDispatch, getState: () => TractoRootState) => {
        const state = getState();
        const cluster = getCluster(state);
        const isAdmin = isDeveloper(state);

        return jupytApiAction(
            'set_secrets',
            cluster,
            {
                alias,
                secrets,
            },
            {isAdmin, cancelToken: cancelHelper.removeAllAndGenerateNextToken()},
        );
    };
}
