import React, {useCallback} from 'react';
import {Menu, type MenuProps, Popup, type PopupProps} from '@gravity-ui/uikit';
import {MenuWithHotkeysBaseItem} from './elements/MenuWithHotkeysBaseItem';
import type {MenuWithHotkeysItem, MenuWithHotkeysSingleItem} from './types';

export type MenuWithHotkeysProps = {
    items: MenuWithHotkeysItem[];
    onClose: () => void;
} & Omit<MenuProps, 'children'> &
    Pick<PopupProps, 'placement' | 'hasArrow' | 'open' | 'anchorRef'>;

export const MenuWithHotkeys: React.FC<MenuWithHotkeysProps> = (props: MenuWithHotkeysProps) => {
    const {items, placement, hasArrow, open, anchorRef, onClose, ...rest} = props;

    const handleItemClick = useCallback(
        (action: MenuWithHotkeysSingleItem['action'] | undefined) => {
            action?.();
            onClose();
        },
        [onClose],
    );

    return (
        <Popup
            hasArrow={hasArrow}
            placement={placement}
            open={open}
            anchorRef={anchorRef}
            onClose={onClose}
        >
            <Menu {...rest}>
                {items.map((item) => {
                    return item.group ? (
                        <Menu.Group label={item.label} key={item.label}>
                            {item.items.map((subItem) => (
                                <MenuWithHotkeysBaseItem
                                    key={subItem.id}
                                    onClick={handleItemClick}
                                    {...subItem}
                                />
                            ))}
                        </Menu.Group>
                    ) : (
                        <MenuWithHotkeysBaseItem
                            key={item.id}
                            onClick={handleItemClick}
                            {...item}
                        />
                    );
                })}
            </Menu>
        </Popup>
    );
};
