import React, {useCallback} from 'react';
import {YTApiId, ytApiV4Id} from '@ytsaurus-ui-platform/src/ui/rum/rum-wrap-api';
import {useSelector} from 'react-redux';
import {getCluster, getCurrentUserName} from '@ytsaurus-ui-platform/src/ui/store/selectors/global';
import {YTTokenInput, YTTokenInputProps} from '../../components/YTTokenInput/YTTokenInput';
import {isManageTokensInOAuthMode} from '@ytsaurus-ui-platform/src/ui/store/selectors/manage-tokens';

const useYTTokenInput = ({description, value, onChange}: YTTokenInputContainerProps) => {
    const cluster = useSelector(getCluster);
    const user = useSelector(getCurrentUserName);
    const isOAuth = useSelector(isManageTokensInOAuthMode);

    const onIssueToken = useCallback(
        async (password_sha256?: string) => {
            const token = await ytApiV4Id.issueToken(YTApiId.issueToken, {
                parameters: {
                    description: description ?? 'Automatically generated YT token',
                    user,
                    password_sha256,
                },
            });

            onChange(token);

            return token;
        },
        [description, user, onChange],
    );

    return {cluster, user, onIssueToken, onUpdate: onChange, value, isOAuth};
};

type YTTokenInputContainerProps = {
    value: string;
    onChange: (value: string) => void;
} & Pick<YTTokenInputProps, 'description'>;

export const YTTokenInputContainer: React.FC<YTTokenInputContainerProps> = (
    props: YTTokenInputContainerProps,
) => {
    return <YTTokenInput {...useYTTokenInput(props)} gap={2} />;
};
