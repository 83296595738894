import React, {useCallback} from 'react';
import {WorkflowEditorComponent} from 'features/Orchestracto/components/WorkflowEditorComponent/WorkflowEditorComponent';
import {
    selectIsWorkflowChanged,
    selectIsWorkflowRunning,
    selectIsWorkflowValid,
    selectWorkflow,
} from 'features/Orchestracto/store/selectors/workflow';
import {useSelector} from 'react-redux';
import {workflowSlice} from 'features/Orchestracto/store/slices/workflow';
import type {editor} from 'monaco-editor';
import {
    dispatchWorkflowActionWithValidation,
    saveWorkflowInCypress,
} from 'features/Orchestracto/store/actions/workflow';
import {oneTimeRunWorkflow} from '../../store/actions/workflow';
import {useTractoDispatch} from '../../../../store/tracto-dispatch';

export const WorkflowEditorContainer: React.FC = () => {
    const dispatch = useTractoDispatch();

    const workflow = useSelector(selectWorkflow);

    const isWorkflowRunning = useSelector(selectIsWorkflowRunning);
    const isWorkflowValid = useSelector(selectIsWorkflowValid);
    const isWorkflowChanged = useSelector(selectIsWorkflowChanged);

    const onChange = useCallback((value: string) => {
        dispatch(
            workflowSlice.actions.setWorkflow({
                workflow: value,
            }),
        );
    }, []);

    const onSave = useCallback(async () => {
        const action = async () => await dispatch(saveWorkflowInCypress());

        dispatch(dispatchWorkflowActionWithValidation(action));
    }, []);

    const onOneTimeRun = useCallback(() => {
        dispatch(workflowSlice.actions.setRunning({running: true}));

        const action = () =>
            onSave()
                .then(() => {
                    return dispatch(oneTimeRunWorkflow()).unwrap();
                })
                .finally(() => {
                    dispatch(workflowSlice.actions.setRunning({running: false}));
                });

        return dispatch(dispatchWorkflowActionWithValidation(action));
    }, [onSave]);

    const onValidate = useCallback(
        (markers: editor.IMarker[]) => {
            dispatch(
                workflowSlice.actions.setMarkers({
                    markers,
                }),
            );
        },
        [workflow],
    );

    return (
        <WorkflowEditorComponent
            isWorkflowValid={isWorkflowValid}
            isWorkflowChanged={isWorkflowChanged}
            isWorkflowRunning={isWorkflowRunning}
            initialValue={workflow}
            onChange={onChange}
            onSave={onSave}
            onOneTimeRun={onOneTimeRun}
            onValidate={onValidate}
        />
    );
};
