import * as React from 'react';
import {useCallback, useEffect} from 'react';
import {useSelector} from 'react-redux';
import {Button, Flex, Icon} from '@gravity-ui/uikit';
import chevronLeftSvg from '@gravity-ui/icons/svgs/chevron-left.svg';
import {getPath} from '@ytsaurus-ui-platform/src/ui/store/selectors/navigation';
import {getCluster} from '@ytsaurus-ui-platform/src/ui/store/selectors/global';
import block from 'bem-cn-lite';

import {deleteImage, fetchImage} from '../../store/slices/image';
import {fetchManifests} from '../../store/slices/manifests';
import {useTractoDispatch} from '../../../../store/tracto-dispatch';
import {
    getDockerFile,
    getLayersTableData,
    getTagsTableData,
    getTractoRegistryBlobConfig,
    getTractoRegistryUrl,
    isImageTabLoading,
} from '../../store/selectors/repositories';
import {TractoRegistryTagsList} from '../TractoRegistryTagsList/TractoRegistryTagsList';
import {TractoRegistryRepositoriesTagDescription} from '../TractoRegistryRepositoriesTagDescription/TractoRegistryRepositoriesTagDescription';
import {TractoRegistryImageLayers} from '../TractoRegistryImageLayersTable/TractoRegistryImageLayers';
import './TractoRegistryTab.scss';
import {NavigationTabLoader} from 'components/NavigationTabLoader/NavigationTabLoader';

const b = block('tracto-registry-tab');

export interface TractoRegistryTabProps {}

export const TractoRegistryTab: React.FunctionComponent<TractoRegistryTabProps> = () => {
    const [selectedTag, setSelectedTag] = React.useState('');
    const dispatch = useTractoDispatch();
    const path: string = useSelector(getPath);
    const cluster = useSelector(getCluster);
    const isLoading = useSelector(isImageTabLoading);
    const tags = useSelector(getTagsTableData);
    const layers = useSelector(getLayersTableData(selectedTag));
    const tractoRegistryUrl = useSelector(getTractoRegistryUrl);
    const blobConfig = useSelector(getTractoRegistryBlobConfig(selectedTag));
    const dockerFile = useSelector(getDockerFile(selectedTag));

    const repository = path
        .split('/')
        .filter(Boolean)
        .filter((segment) => segment !== '_tags')
        .join('/');

    useEffect(() => {
        dispatch(
            fetchImage({
                cluster,
                repository,
            }),
        )
            .unwrap()
            .then((image) => dispatch(fetchManifests({cluster, image})));
    }, [path, cluster, repository]);

    const handleDeleteImage = useCallback(
        (tag: string) => {
            dispatch(
                deleteImage({
                    tag,
                    cluster,
                    repository,
                }),
            );
        },
        [cluster, repository],
    );

    if (isLoading) {
        return <NavigationTabLoader />;
    }

    let content = (
        <TractoRegistryTagsList
            tags={tags}
            repository={repository}
            tractoRegistryUrl={tractoRegistryUrl}
            onDeleteImage={handleDeleteImage}
            onRowClick={(item) => {
                setSelectedTag(item.tag);
            }}
        />
    );

    if (selectedTag) {
        content = (
            <>
                <Button view="outlined" onClick={() => setSelectedTag('')}>
                    <Icon data={chevronLeftSvg} size="16" />
                </Button>
                <Flex width="100%" wrap="wrap" className={b('wrapper')}>
                    <div className={b('section')}>
                        <TractoRegistryRepositoriesTagDescription
                            tag={selectedTag}
                            blobConfig={blobConfig}
                            dockerFile={dockerFile}
                            repository={repository}
                            tractoRegistryUrl={tractoRegistryUrl}
                            buildOperationLink={(value) => {
                                return `/${cluster}/operations/${value}/details`;
                            }}
                            buildBluePrintLink={(value) => {
                                return `/${cluster}/navigation?path=//${value}`;
                            }}
                        />
                    </div>
                    <div className={b('section')}>
                        <TractoRegistryImageLayers
                            layers={layers}
                            repository={repository}
                            tractoRegistryUrl={tractoRegistryUrl}
                        />
                    </div>
                </Flex>
            </>
        );
    }

    return <div>{content}</div>;
};
