import React from 'react';
import type {Output} from '@diplodoc/transform';
import {tractoTransformMarkdown} from '../../api/markdown';
import type {MarkdownOptions} from '../../../shared/types/common/markdown';
import {TractoMarkdown} from '../../components/TractoMarkdown/TractoMarkdown';
import {Flex, Loader} from '@gravity-ui/uikit';

type TractoMarkdownProps = {
    text: string;
    options: MarkdownOptions;
    className?: string;
};

const useMarkdown = (
    text: TractoMarkdownProps['text'],
    options: TractoMarkdownProps['options'],
) => {
    const [result, setResult] = React.useState<Output>(() => ({
        result: {html: '', headings: []},
        logs: {error: [], disabled: [], warn: [], info: []},
    }));
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {
        async function transform() {
            setLoading(true);
            try {
                const data = await tractoTransformMarkdown({text, options});

                setResult(data);
            } catch (e) {
            } finally {
                setLoading(false);
            }
        }
        transform();
    }, [text, options]);

    return {...result, loading};
};

const TractoMarkdownContainerComponent: React.FC<TractoMarkdownProps> = (
    props: TractoMarkdownProps,
) => {
    const {className, options, text} = props;

    const {result, loading} = useMarkdown(text, options);

    return loading ? (
        <Flex alignItems="center" width="100%" height="100%" justifyContent="center">
            <Loader size="s" />
        </Flex>
    ) : (
        <TractoMarkdown html={result.html} className={className} />
    );
};

export const TractoMarkdownContainer = React.memo(TractoMarkdownContainerComponent);
