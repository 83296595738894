import type {TractoRootState} from '../../../../store/reducers';

export const getJupytOperation = (state: TractoRootState) =>
    state.tracto.jupyter.jupytOperation.operation;

export const getJupytOperationSpeclet = (state: TractoRootState) =>
    state.tracto.jupyter.jupytOperation.speclet;

export const getJupytOperationOptions = (state: TractoRootState) =>
    state.tracto.jupyter.jupytOperation.options;

export const getOperationAlias = (state: TractoRootState) =>
    state.tracto.jupyter.jupytOperation.alias;
