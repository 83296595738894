import {NotebookCellType} from '../../../constants/cell';
import {JupyterCellQa} from '../../../../../../shared/qa';
import {isTrueEnvValue} from '@gravity-ui/nodekit/dist/lib/utils/is-true-env';
import {getTractoConfigData} from '../../../../../config/ui-config';
import {selectify} from '../../../../../utils/select';

export const SUPPORTED_CELL_TYPES = [
    {
        value: NotebookCellType.CODE,
        content: 'Code',
        qa: JupyterCellQa.JupyterCellTypeCode,
    },
    {
        value: NotebookCellType.MARKDOWN,
        content: 'Markdown',
        qa: JupyterCellQa.JupyterCellTypeMarkdown,
    },
    {
        value: NotebookCellType.CHYT,
        content: 'CHYT',
        qa: JupyterCellQa.JupyterCellTypeCHYT,
        hidden: !isTrueEnvValue(getTractoConfigData().env.SHOW_JUPYTER_SQL_CELLS),
    },
    {
        value: NotebookCellType.SPYT,
        content: 'SPYT',
        qa: JupyterCellQa.JupyterCellTypeSPYT,
        hidden: !isTrueEnvValue(getTractoConfigData().env.SHOW_JUPYTER_SQL_CELLS),
    },
    {
        value: NotebookCellType.QL,
        content: 'QL',
        qa: JupyterCellQa.JupyterCellTypeQL,
        hidden: !isTrueEnvValue(getTractoConfigData().env.SHOW_JUPYTER_SQL_CELLS),
    },
    {
        value: NotebookCellType.YQL,
        content: 'YQL',
        qa: JupyterCellQa.JupyterCellTypeYQL,
        hidden: !isTrueEnvValue(getTractoConfigData().env.SHOW_JUPYTER_SQL_CELLS),
    },
]
    .filter(({hidden}) => !hidden)
    .map(selectify);
