import React, {useCallback} from 'react';
import {Icon, TextInput} from '@gravity-ui/uikit';
import Button from '@ytsaurus-ui-platform/src/ui/components/Button/Button';
import {Xmark} from '@gravity-ui/icons';
import block from 'bem-cn-lite';
import {produce} from 'immer';

import './BlueprintAttachedFilesListComponent.scss';
import PathEditor from '@ytsaurus-ui-platform/src/ui/containers/PathEditor/PathEditor';

const b = block('attached-files-list');

type AttachedFilesListProps = {
    value: AttachedFile[];
    onUpdate: (value: AttachedFile[]) => void;
};

export type AttachedFile = {
    localPath: string;
    cypressPath: string;
};

export function BlueprintAttachedFilesListComponent({value, onUpdate}: AttachedFilesListProps) {
    const onAddEntryClick = useCallback(() => {
        onUpdate(value.concat({localPath: '', cypressPath: ''}));
    }, [value]);

    return (
        <div className={b()}>
            {value.map(({cypressPath, localPath}, attachIndex) => {
                const onLocalPathUpdate = (newLocalPath: string) => {
                    onUpdate(
                        produce(value, (draft) => {
                            draft[attachIndex].localPath = newLocalPath;
                        }),
                    );
                };

                const onCypressPathUpdate = (newCypresPath: string) => {
                    onUpdate(
                        produce(value, (draft) => {
                            draft[attachIndex].cypressPath = newCypresPath;
                        }),
                    );
                };

                const onRemove = () => {
                    onUpdate(value.filter((_, index) => index !== attachIndex));
                };

                return (
                    <AttachedFileComponent
                        localPath={localPath}
                        cypressPath={cypressPath}
                        onLocalPathUpdate={onLocalPathUpdate}
                        onCypressPathUpdate={onCypressPathUpdate}
                        onRemove={onRemove}
                    />
                );
            })}
            <div className={b('add-entry-row', {'no-entires': !value.length})}>
                <Button className={b('add-entry-button')} onClick={onAddEntryClick}>
                    Add file
                </Button>
            </div>
        </div>
    );
}

type AttachedFileComponentProps = {
    localPath: string;
    cypressPath: string;
    onLocalPathUpdate?: (value: string) => void;
    onCypressPathUpdate?: (value: string) => void;
    onRemove: () => void;
};

function AttachedFileComponent({
    localPath,
    cypressPath,
    onLocalPathUpdate,
    onCypressPathUpdate,
    onRemove,
}: AttachedFileComponentProps) {
    return (
        <div className={b('attach-entry-container')}>
            <div className={b('attach-entry-row')}>
                <div className={b('attach-entry-left')}>Local path:</div>
                <div className={b('attach-entry-right')}>
                    <TextInput
                        value={localPath}
                        placeholder="Enter the path..."
                        onUpdate={onLocalPathUpdate}
                    />
                </div>
                <div className={b('attach-entry-actions')}>
                    <Button view="flat" size="s" onClick={onRemove}>
                        <Icon data={Xmark} size={14} />
                    </Button>
                </div>
            </div>
            <div className={b('attach-entry-row')}>
                <div className={b('attach-entry-left')}>Cypress path:</div>
                <div className={b('attach-entry-right')}>
                    <PathEditor
                        className={b('entry-path-editor')}
                        defaultPath={cypressPath}
                        onChange={onCypressPathUpdate}
                    />
                </div>
                <div className={b('attach-entry-actions')}></div>
            </div>
        </div>
    );
}
