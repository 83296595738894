import type {AxiosInstance} from 'axios';
import type {
    UpdateWorkflowRequestArgs,
    UpdateWorkflowSuccessResponse,
    ValidateWorkflowRequestArgs,
    ValidateWorkflowSuccessResponse,
} from './types';

export class WorkflowApi {
    private readonly client: AxiosInstance;

    constructor(client: AxiosInstance) {
        this.client = client;
    }

    async validateWorkflow(
        args: ValidateWorkflowRequestArgs,
    ): Promise<ValidateWorkflowSuccessResponse> {
        const {workflow} = args;

        const result = await this.client.post<ValidateWorkflowSuccessResponse>(
            '/api/v1/validate_workflow',
            {
                workflow,
            },
        );

        return result.data;
    }

    async updateWorkflow(args: UpdateWorkflowRequestArgs): Promise<UpdateWorkflowSuccessResponse> {
        const {workflow, workflowPath} = args;

        const result = await this.client.post<UpdateWorkflowSuccessResponse>(
            '/api/v1/update_workflow',
            {
                workflow,
                workflow_path: workflowPath,
            },
        );

        return result.data;
    }
}
