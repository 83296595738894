import type {V0NotebookContent} from '../../types/version/v0';
import type {V1NotebookCell, V1NotebookContent, V1NotebookMetadata} from '../../types/version/v1';
import {isMarkdown} from '@jupyterlab/nbformat';
import {NotebookCellType} from '../../constants/cell';
import {JupyterNotebookUIConfigVersion} from '../../constants/version';

export const mapV0NotebookContentToV1 = (notebook: V0NotebookContent): V1NotebookContent => {
    const notebookMetadata: V1NotebookMetadata['tracto'] = {
        is_solution_notebook: notebook.metadata.is_solution_notebook,
        notebook_cypress_id: notebook.metadata.notebook_cypress_id ?? crypto.randomUUID(),
        ui_config_version: JupyterNotebookUIConfigVersion.V1,
    };

    delete notebook.metadata.is_solution_notebook;
    delete notebook.metadata.notebook_cypress_id;

    return {
        ...notebook,
        tracto_notebook_content_version: JupyterNotebookUIConfigVersion.V1,
        metadata: {
            ...notebook.metadata,
            tracto: notebookMetadata,
        },
        cells: notebook.cells.map((cell): V1NotebookCell => {
            return {
                ...cell,
                id: cell.metadata.cell_id ?? crypto.randomUUID(),
                metadata: {
                    tracto: {
                        type: isMarkdown(cell) ? NotebookCellType.MARKDOWN : NotebookCellType.CODE,
                    },
                },
            };
        }),
    };
};
