import type {TractoNotebookCell} from '../features/Jupyter/types/version';
import {NotebookCellType, SQL_CELL_TYPES} from '../features/Jupyter/constants/cell';

export const isCodeCellType = (
    cellType: 'code' | 'markdown' | string | undefined,
): cellType is 'code' => {
    return cellType === 'code';
};

export const isMarkdownCellType = (
    cellType: 'code' | 'markdown' | string | undefined,
): cellType is 'markdown' => {
    return cellType === 'markdown';
};

export const getCellView = (cell: TractoNotebookCell) => {
    return cell.metadata.tracto;
};

export const getCellSQLConfig = (cell: TractoNotebookCell) => {
    return getCellView(cell).sql;
};

export const isSQLCell = (cellType: NotebookCellType) => {
    return SQL_CELL_TYPES.has(cellType);
};
