import type {TractoRootState} from 'store/reducers';
import {createSelector} from 'reselect';
import type {SQLCellViewType} from '../../types/cell/sql';
import {NotebookSQLHelper} from '../../utils/sql/helper';

export const selectSQLTemplates = (state: TractoRootState) => {
    const templates = state.tracto.jupyter.sql.templates;

    if (!templates) {
        throw new Error('SQL templates is not loaded.');
    }

    return templates;
};

export const selectSQLTemplateByCellType = createSelector(
    [
        selectSQLTemplates,
        (_state: TractoRootState, cellViewType: SQLCellViewType) => {
            return NotebookSQLHelper.getTemplateKey(cellViewType);
        },
    ],
    (templates, id) => {
        return templates[id];
    },
);
