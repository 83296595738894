import type {SupportedOutputTypes} from './types';
import type {IError, IStream} from '@jupyterlab/nbformat';

// eslint-disable-next-line no-control-regex
const removeANSISymbols = (text: string): string => text.replace(/\x1b\[[0-9;]*m/g, '');

const prepareErrorOutputContent = (output: IError): string =>
    removeANSISymbols(output.traceback.join('\n'));

const prepareStreamOutputContent = (output: IStream): string =>
    removeANSISymbols(Array.isArray(output.text) ? output.text.join('') : output.text);

export const prepareOutputCopyText = (outputs: SupportedOutputTypes[]): string => {
    return outputs.reduce((acc, output) => {
        switch (output.output_type) {
            case 'error':
                return acc + prepareErrorOutputContent(output);
            case 'stream':
                return acc + prepareStreamOutputContent(output);
            default:
                return acc;
        }
    }, '');
};
