import React, {useCallback} from 'react';
import {Flex, Hotkey, Menu, Text} from '@gravity-ui/uikit';
import type {MenuWithHotkeysSingleItem} from '../types';

type MenuWithHotkeysItemProps = MenuWithHotkeysSingleItem & {
    onClick: (action: MenuWithHotkeysSingleItem['action'] | undefined) => void;
};

export const MenuWithHotkeysBaseItem: React.FC<MenuWithHotkeysItemProps> = (
    props: MenuWithHotkeysItemProps,
) => {
    const {text, iconStart, iconEnd, wrapper, onClick, hotkey, platform, action} = props;

    const handleClick = useCallback(() => {
        onClick(action);
    }, [action, onClick]);

    const node = (
        <Menu.Item iconStart={iconStart} iconEnd={iconEnd} onClick={handleClick}>
            <Flex alignItems="center" justifyContent="space-between" gap={10}>
                <Text variant="inherit">{text}</Text>
                {hotkey && <Hotkey value={hotkey} platform={platform} />}
            </Flex>
        </Menu.Item>
    );

    return wrapper ? wrapper(node) : node;
};
