import {TractoRootState} from 'store/reducers';
import {BlueprintType} from '../actions/api/blueprints';
import {getPath} from '@ytsaurus-ui-platform/src/ui/store/selectors/navigation';
import {getBlueprintIdFromPath} from 'features/ImageBuilder/utils/blueprint';

export const selectContent = (state: TractoRootState) => {
    return state.tracto.imageBuilder.editor.content;
};

export const selectAttachedFiles = (state: TractoRootState) => {
    return state.tracto.imageBuilder.editor.attachedFiles;
};

export const selectBlueprint = (state: TractoRootState): BlueprintType => {
    const editorContent = selectContent(state);
    const files = selectAttachedFiles(state);
    const imageRegistry = selectImageRegistry(state);
    const imageName = selectImageName(state);
    const cpu_limit = selectCpuLimit(state);
    const memory_limit = selectMemoryLimit(state);

    return {
        content: editorContent.split('\n').map((item) => {
            return {
                type: 'raw',
                content: item,
            };
        }),
        files,
        registry_auth: [],
        docker_params: {
            image: {
                name: imageName,
                registry: imageRegistry,
            },
            build_args: '',
        },
        build_params: {
            cpu_limit,
            memory_limit,
        },
    };
};

export const selectBlueprintId = (state: TractoRootState) => {
    const path: string = getPath(state);

    return getBlueprintIdFromPath(path);
};

export const selectSavingInProgress = (state: TractoRootState) => {
    return state.tracto.imageBuilder.editor.savingInProgress;
};

export const selectIsBlueprintLoaded = (state: TractoRootState) => {
    return state.tracto.imageBuilder.editor.blueprintLoaded;
};

export const selectImageRegistry = (state: TractoRootState) => {
    return state.tracto.imageBuilder.editor.imageRegistry;
};

export const selectImageName = (state: TractoRootState) => {
    return state.tracto.imageBuilder.editor.imageName;
};

export const selectCpuLimit = (state: TractoRootState) => {
    return state.tracto.imageBuilder.editor.cpuLimit;
};

export const selectMemoryLimit = (state: TractoRootState) => {
    return state.tracto.imageBuilder.editor.memoryLimit;
};
