import type {WorkflowExecutionStatus} from '../types/workflow';

export const WORKFLOW_RUN_PERSISTENT_STATUS: Record<WorkflowExecutionStatus, boolean> = {
    running: false,
    to_do: false,
    done: true,
    failed: true,
    stopped: true,
    error: true,
};
