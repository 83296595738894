import React from 'react';
import type * as monaco from 'monaco-editor';
import type {SQLTemplates} from '../../types/sql/temaplate';
import {MonacoLanguage} from '@ytsaurus-ui-platform/src/ui/constants/monaco';
import type {TractoNotebookCellSQLConfig} from '../../types/version';
import type {MultilineString} from '@jupyterlab/nbformat';
import {CodeCellSource} from '../CodeCellSource/CodeCellSource';

const getLanguageByEngine = (engine: keyof SQLTemplates | undefined): MonacoLanguage => {
    switch (engine) {
        case 'chyt':
            return MonacoLanguage.CHYT;
        case 'spyt':
            return MonacoLanguage.SPYT;
        case 'ql':
            return MonacoLanguage.YTQL;
        case 'yql':
        default:
            return MonacoLanguage.YQL;
    }
};

type SQLCellSourceProps = {
    source: MultilineString;
    editorRef: React.MutableRefObject<monaco.editor.IStandaloneCodeEditor | undefined>;
    onChange: (value: string) => void;
    sqlConfig: TractoNotebookCellSQLConfig | undefined;
};

export const SQLCellSource: React.FC<SQLCellSourceProps> = (props: SQLCellSourceProps) => {
    const {source, editorRef, onChange, sqlConfig} = props;

    return (
        <CodeCellSource
            editorRef={editorRef}
            language={getLanguageByEngine(sqlConfig?.template_id)}
            source={source}
            onChange={onChange}
        />
    );
};
