import {IOutput, isCode} from '@jupyterlab/nbformat';
import {createSelector} from 'reselect';
import isEqual from 'lodash/isEqual';
import {TractoRootState} from '../../../../store/reducers';
import {type WidgetDisplayData, isWidgetData} from '../../utils/cell/widget';

export const selectNotebookContent = (state: TractoRootState) =>
    state.tracto.jupyter.notebook.content;

export const selectNotebookCells = (state: TractoRootState) =>
    state.tracto.jupyter.notebook.content?.cells || [];

export const selectNotebookCellIds = createSelector(
    (state: TractoRootState) => state.tracto.jupyter.notebook.content?.cells,
    (cells) => (cells || []).map((cell) => cell.id),
);

export const selectCell = (state: TractoRootState, cellId: string) => {
    return state.tracto.jupyter.notebook.content?.cells.find((cell) => cell.id === cellId);
};

export const selectCellOutputs = (state: TractoRootState, cellId: string) => {
    const cell = selectCell(state, cellId);

    let outputs: IOutput[] = [];

    if (cell && isCode(cell)) {
        outputs = cell.outputs;
    } else {
        outputs = [];
    }

    return outputs;
};

export const selectRunningCells = (state: TractoRootState) =>
    state.tracto.jupyter.notebook.runningCells;

export const selectFocusedCellId = (state: TractoRootState) => {
    return state.tracto.jupyter.notebook.focusedCellId;
};

export const selectFocusedCellIndex = (state: TractoRootState): number => {
    const focusedCellId = state.tracto.jupyter.notebook.focusedCellId;

    if (!state.tracto.jupyter.notebook.content) {
        return -1;
    }

    return state.tracto.jupyter.notebook.content.cells.findIndex((cell) => {
        return cell.id === focusedCellId;
    });
};

export const selectFocusedCell = (state: TractoRootState) => {
    const focusedCellId = state.tracto.jupyter.notebook.focusedCellId;

    return state.tracto.jupyter.notebook.content?.cells.find((cell) => {
        return cell.id === focusedCellId;
    });
};

export const selectEditableCell = (state: TractoRootState) =>
    state.tracto.jupyter.notebook.editableCellId;

export const selectCurrentJupytAlias = (state: TractoRootState) =>
    state.tracto.jupyter.jupyt.currentJupytAlias;

export const checkIfCurrentJupytSuspended = (state: TractoRootState) => {
    const jupyt = state.tracto.jupyter.jupyt.operations.find(
        (item) => item.$value === state.tracto.jupyter.jupyt.currentJupytAlias,
    );

    if (jupyt) {
        return Boolean(jupyt.$attributes?.yt_operation_suspended);
    }

    return false;
};

export const selectJupyterKernelStatus = (state: TractoRootState) =>
    state.tracto.jupyter.jupyt.jupyterKernelStatus;

export const selectIsNotebookSaved = (state: TractoRootState) =>
    isEqual(state.tracto.jupyter.notebook.content, state.tracto.jupyter.notebook.savedContent) ||
    state.tracto.jupyter.notebook.writePermission === 'deny';

export const selectIsSavingInProgress = (state: TractoRootState) =>
    state.tracto.jupyter.notebook.isSavingInProgress;

export const selectIsUserHasWritePermission = (state: TractoRootState) =>
    state.tracto.jupyter.notebook.writePermission === 'allow';

export const getNotebookCypressId = (state: TractoRootState) => {
    const notebookCypressId =
        state.tracto.jupyter.notebook?.content?.metadata?.tracto.notebook_cypress_id;

    if (notebookCypressId) {
        return String(notebookCypressId);
    }

    return null;
};

export const selectDirtyCells = (state: TractoRootState) =>
    state.tracto.jupyter.notebook.dirtyCells;

export const selectIsSolutionNotebook = (state: TractoRootState) =>
    state.tracto.jupyter.notebook?.content?.metadata?.tracto.is_solution_notebook;

export const doesNotebookHaveWidget = (state: TractoRootState) =>
    Boolean(
        state.tracto.jupyter.notebook?.content?.cells.some((item) => {
            if (isCode(item) && Array.isArray(item?.outputs)) {
                return item?.outputs.some((output) => {
                    if (output && typeof output === 'object' && 'data' in output) {
                        return isWidgetData(output as WidgetDisplayData);
                    }

                    return false;
                });
            }

            return false;
        }),
    );
