import {combineSlices} from '@reduxjs/toolkit';
import {notebookSlice} from './notebook';
import {jupytSlice} from './jupyt';
import {jupytOperationSlice} from './jupytOperation';
import {modals} from './modals';
import {sqlSlice} from './sql';

export const jupyterRoot = combineSlices({
    jupyt: jupytSlice.reducer,
    notebook: notebookSlice.reducer,
    jupytOperation: jupytOperationSlice.reducer,
    sql: sqlSlice.reducer,
    modals: modals,
});
