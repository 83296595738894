import React from 'react';
import {Flex, Loader} from '@gravity-ui/uikit';

export const NavigationTabLoader = () => {
    return (
        <Flex alignItems="center" justifyContent="center" width="100%" height="200px">
            <Loader />
        </Flex>
    );
};
