import {JUPYTER_PROXY_API_PREFIX} from '../../constants/jupyter/jupyter-api';

export const NOTEBOOK_PATH_HEADER = 'x-notebook-path';

export const getJupyterBaseUrl = ({cluster, alias}: {cluster: string; alias: string}) =>
    `${JUPYTER_PROXY_API_PREFIX}/${cluster}/${alias}`;

export const getJupyterWSBaseUrl = ({cluster, alias}: {cluster: string; alias: string}) =>
    '/ws' + getJupyterBaseUrl({cluster, alias});

export const getHttpRequestInit = ({path}: {path: string}): RequestInit => {
    return {
        headers: {
            [NOTEBOOK_PATH_HEADER]: path,
        },
    };
};
