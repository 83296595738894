import {createTractoAsyncThunk} from '../../../../../store/tracto-async-thunk';
import {getSQLTemplatesPath} from '../../../utils/sql/template';
import {wrapApiPromiseByToaster} from '@ytsaurus-ui-platform/src/ui/utils/utils';
import type {SQLTemplates} from '../../../types/sql/temaplate';
import {ytApiV4} from '@ytsaurus-ui-platform/src/ui/rum/rum-wrap-api';
import {NotebookSQLHelper} from '../../../utils/sql/helper';
import {sqlSlice} from '../../slices/sql';
import {getTractoConfigData} from '../../../../../config/ui-config';
import {isTrueEnvValue} from '@gravity-ui/nodekit/dist/lib/utils/is-true-env';

export const loadNotebookSQLTemplates = createTractoAsyncThunk<void, void>(
    'jupyter.actions.loadNotebookSQLTemplates',
    async (_, thunkAPI) => {
        const {env} = getTractoConfigData();

        if (!isTrueEnvValue(env.SHOW_JUPYTER_SQL_CELLS)) {
            return;
        }

        const {value} = await wrapApiPromiseByToaster<{value: SQLTemplates}>(
            ytApiV4.get({parameters: {path: getSQLTemplatesPath()}}),
            {
                skipSuccessToast: true,
                toasterName: 'NotebookSQLTemplates',
                errorTitle: 'Failed to load SQL templates',
            },
        );

        const templates = value;

        const sqlTypes = Object.keys(templates) as Array<keyof SQLTemplates>;

        sqlTypes.forEach((sqlType) => {
            NotebookSQLHelper.template(sqlType, templates[sqlType].code);
        });

        thunkAPI.dispatch(sqlSlice.actions.setTemplates({templates}));
    },
);
