import React from 'react';
import block from 'bem-cn-lite';

import './WorkflowEditorComponent.scss';
import TractoMonacoEditor from 'components/TractoMonacoEditor/TractoMonacoEditor';
import Button from '@ytsaurus-ui-platform/src/ui/components/Button/Button';
import {setupMonacoWorkflowExtensions} from 'components/TractoMonacoEditor/extensions';
import type {editor} from 'monaco-editor';
import {Flex} from '@gravity-ui/uikit';
import {useHotkey} from 'hooks/useHotkey';

const b = block('workflow-editor');

type WorkflowEditorComponentProps = {
    isWorkflowValid: boolean;
    isWorkflowChanged: boolean;
    isWorkflowRunning: boolean;
    initialValue: string;
    onChange: (value: string) => void;
    onSave: () => void;
    onValidate: (markers: editor.IMarker[]) => void;
    onOneTimeRun: () => void;
};

export const WorkflowEditorComponent: React.FC<WorkflowEditorComponentProps> = ({
    isWorkflowValid,
    isWorkflowChanged,
    isWorkflowRunning,
    initialValue,
    onChange,
    onSave,
    onValidate,
    onOneTimeRun,
}) => {
    const isSaveDisabled = !isWorkflowValid || !isWorkflowChanged;

    let content = 'Save';

    if (!isWorkflowChanged) {
        content = 'Saved';
    }

    if (!isWorkflowValid) {
        content = 'Workflow is invalid';
    }

    useHotkey({
        keys: 'control+s,command+s',
        handler: (event) => {
            event.preventDefault();
            onSave();
        },
    });

    useHotkey({
        keys: 'shift+enter',
        handler: (event) => {
            event.preventDefault();
            onOneTimeRun();
        },
    });

    return (
        <div className={b()}>
            <TractoMonacoEditor
                className={b('editor')}
                value={initialValue}
                onChange={onChange}
                language="json"
                adjustToContent={false}
                setupExtensions={setupMonacoWorkflowExtensions}
                onValidate={onValidate}
            />
            <Flex className={b('controls')} gap="2">
                <Button
                    className={b('apply-btn')}
                    view="action"
                    size="l"
                    onClick={onSave}
                    disabled={isSaveDisabled}
                >
                    {content}
                </Button>
                <Button
                    view="action"
                    size="l"
                    onClick={onOneTimeRun}
                    disabled={!isWorkflowValid}
                    loading={isWorkflowRunning}
                >
                    One-time Run
                </Button>
            </Flex>
        </div>
    );
};
