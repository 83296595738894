import {TractoRootState} from 'store/reducers';

export const selectDelegationsList = (state: TractoRootState) =>
    state.tracto.secretStore.delegations.delegations;

export const selectshowAddDelegationDialog = (state: TractoRootState) =>
    state.tracto.secretStore.delegations.showAddDelegationDialog;

export const selectDelegationToDelete = (state: TractoRootState) =>
    state.tracto.secretStore.delegations.delegationToDelete;
