export const enum NotebookCellType {
    CODE = 'CODE',
    MARKDOWN = 'MD',
    CHYT = 'CHYT',
    SPYT = 'SPYT',
    QL = 'QL',
    YQL = 'YQL',
}

export const SQL_CELL_TYPES = new Set([
    NotebookCellType.SPYT,
    NotebookCellType.QL,
    NotebookCellType.YQL,
    NotebookCellType.CHYT,
]);
