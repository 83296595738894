import React from 'react';
import block from 'bem-cn-lite';
import TractoMonacoEditor from 'components/TractoMonacoEditor/TractoMonacoEditor';

import './BlueprintEditorComponent.scss';

const b = block('blueprint-editor');

type BlueprintEditorComponentProps = {
    className?: string;
    value: string;
    onChange: (value: string) => void;
};

export const BlueprintEditorComponent: React.FC<BlueprintEditorComponentProps> = ({
    className,
    value,
    onChange,
}) => {
    return (
        <div className={b(null, className)}>
            <div className="elements-heading elements-heading_size_xs">Editor</div>
            <TractoMonacoEditor
                className={b('editor')}
                value={value}
                onChange={onChange}
                language="dockerfile"
            />
        </div>
    );
};
