import {PayloadAction, createSlice} from '@reduxjs/toolkit';
import {Delegation} from 'features/SecretStore/types/delegations';

type DelegationsInitialState = {
    delegations: Delegation[];
    showAddDelegationDialog: boolean;
    delegationToDelete: Delegation | null;
};

const initialState: DelegationsInitialState = {
    delegations: [],
    showAddDelegationDialog: false,
    delegationToDelete: null,
};

export const delegationsSlice = createSlice({
    name: 'secretStore.delegations-slice',
    initialState,
    reducers: {
        setDelegations: (state, action: PayloadAction<{delegations: Delegation[]}>) => {
            state.delegations = action.payload.delegations;
        },
        showAddDelegationDialog: (state) => {
            state.showAddDelegationDialog = true;
        },
        hideAddDelegationDialog: (state) => {
            state.showAddDelegationDialog = false;
        },
        setDelegationToDelete: (state, action: PayloadAction<{delegation: Delegation | null}>) => {
            state.delegationToDelete = action.payload.delegation;
        },
    },
});
