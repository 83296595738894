import {createAsyncThunk} from '@reduxjs/toolkit';
import {getCluster} from '@ytsaurus-ui-platform/src/ui/store/selectors/global';
import {RootState} from '@ytsaurus-ui-platform/src/ui/store/reducers';
import {jupytApiAction} from '../../../api/jupyt';
import CancelHelper, {isCancelled} from '@ytsaurus-ui-platform/src/ui/utils/cancel-helper';
import {TractoThunkDispatch} from '../../../../../store/tracto-dispatch';
import {jupytOperationSlice} from '../../slices/jupytOperation';
import {isDeveloper} from '@ytsaurus-ui-platform/src/ui/store/selectors/global/is-developer';

const cancelHelper = new CancelHelper();

export const jupytSpecletLoad = createAsyncThunk(
    'jupyter.jupytOperation/SpecletLoad',
    async (alias: string, thunkAPI) => {
        const state = thunkAPI.getState();
        const cluster = getCluster(state as RootState);
        const isAdmin = isDeveloper(state);

        return jupytApiAction(
            'get_speclet',
            cluster,
            {alias},
            {
                isAdmin,
                cancelToken: cancelHelper.removeAllAndGenerateNextToken(),
                skipErrorToast: true,
            },
        ).catch((error) => {
            if (!isCancelled(error)) {
                console.log('error', error);
                return thunkAPI.rejectWithValue(error);
            }

            return null;
        });
    },
);
export const jupytSpecletClearState = () => {
    return (dispatch: TractoThunkDispatch) => {
        dispatch(jupytOperationSlice.actions.clearState());
    };
};
