import {KernelManager, Session, SessionManager} from '@jupyterlab/services';
import {TractoLogger} from '../../../../utils/logging';
import {getJupyterApiServerSettings} from '../../utils/api';

let sessionManagerSingleton: SessionManager | null;

export const getOptionalSessionManager = () => sessionManagerSingleton;

export const getSessionManager = (
    kernelManager: KernelManager,
    {alias, cluster, path}: {alias: string; cluster: string; path: string},
): SessionManager => {
    if (sessionManagerSingleton) {
        return sessionManagerSingleton;
    }

    const serverSettings = getJupyterApiServerSettings({alias, cluster, path});

    sessionManagerSingleton = new SessionManager({serverSettings, kernelManager});

    return sessionManagerSingleton;
};

export const createSession = (
    sessionManager: SessionManager,
    {path, notebookId}: {path: string; notebookId: string},
): Promise<Session.ISessionConnection> => {
    TractoLogger.log(
        'Starting session connection with path: ',
        path,
        'and notebookId: ',
        notebookId,
    );

    return sessionManager.startNew({
        type: 'notebook',
        path: `${path}_${notebookId}`,
        name: '',
    });
};

export const resetSessionManager = () => {
    sessionManagerSingleton = null;
};
