import {TractoRootState} from 'store/reducers';

export const selectIsUniversePoolExist = (state: TractoRootState) =>
    state.tracto.universeResources.resources.isUniversePoolExist;

export const selectVisibleResourceType = (state: TractoRootState) =>
    state.tracto.universeResources.resources.visibleResourceType;

export const selectPoolTreesList = (state: TractoRootState) =>
    state.tracto.universeResources.resources.poolTreesList;

export const selectPoolTreeResources = (state: TractoRootState) =>
    state.tracto.universeResources.resources.poolTreeResources;
