import type {IOutput} from '@jupyterlab/nbformat';
import {useMemo} from 'react';
import type {SupportedOutputTypes} from './types';
import {prepareOutputCopyText} from './helpers';

type UseCopyOutputArgs = {
    outputs: IOutput[];
};

const SUPPORTED_TO_COPY_OUTPUT_TYPES = new Set<string>([
    'stream',
    'error',
] satisfies SupportedOutputTypes['output_type'][]);

export const useCopyOutput = ({outputs}: UseCopyOutputArgs) => {
    const supportedOutputs = useMemo(() => {
        return outputs.filter((o) => {
            return SUPPORTED_TO_COPY_OUTPUT_TYPES.has(o.output_type);
        }) as SupportedOutputTypes[];
    }, [outputs]);

    const isCopyButtonVisible = supportedOutputs.length > 0;

    const copyText = isCopyButtonVisible ? prepareOutputCopyText(supportedOutputs) : '';

    return {isCopyButtonVisible, copyText};
};
