import {PayloadAction, createSlice} from '@reduxjs/toolkit';

type SecretItem = {
    name: string;
    value: string;
};

type DelegationsInitialState = {
    secrets: SecretItem[];
};

const initialState: DelegationsInitialState = {
    secrets: [],
};

export const secretsSlice = createSlice({
    name: 'secretStore.delegations-slice',
    initialState,
    reducers: {
        setSecrets: (state, action: PayloadAction<{secrets: SecretItem[]}>) => {
            state.secrets = action.payload.secrets;
        },
    },
});
