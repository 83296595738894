import React, {useCallback, useEffect, useMemo} from 'react';
import {JupyterGrid} from '../../components/JupyterGrid/JupyterGrid';
import {JupyterCellContainer} from '../JupyterCellContainer/JupyterCellContainer';
import {AddJupyterCellButtonContainer} from '../AddJupyterCellButtonContainer/AddJupyterCellButtonContainer';
import {NotebookPlaceholderContainer} from '../NotebookPlaceholderContainer/NotebookPlaceholderContainer';
import isEqual from 'lodash/isEqual';
import {SortEndHandler} from 'react-sortable-hoc';
import {useDispatch, useSelector} from 'react-redux';
import {notebookSlice} from 'features/Jupyter/store/slices/notebook';
import {selectNotebookCellIds} from '../../store/selectors/notebook';
import {useHotkey} from 'hooks/useHotkey';
import {NotebookPageQa} from '../../../../../shared/qa';
import {useLocation} from 'react-router';
import {getAnchorHashCellIdSelector} from '../../utils/cell/anchor';

const useJupyterGrid = () => {
    const dispatch = useDispatch();
    const location = useLocation();

    const cellIds = useSelector(selectNotebookCellIds, (prevState, newState) =>
        isEqual(prevState, newState),
    );

    const cellsIdsSet = useMemo(() => {
        return new Set(cellIds);
    }, [cellIds]);

    const anchorCellId = useMemo(() => location.hash.replace('#', ''), [location.hash]);

    const handleSortEnd: SortEndHandler = useCallback((sort) => {
        dispatch(
            notebookSlice.actions.changeCellPosition({
                oldIndex: sort.oldIndex,
                newIndex: sort.newIndex,
            }),
        );
    }, []);

    useHotkey({
        keys: 'up',
        handler: (event) => {
            event.preventDefault();
            dispatch(notebookSlice.actions.upFromCurrentCell());
        },
    });

    useHotkey({
        keys: 'down',
        handler: (event) => {
            event.preventDefault();
            dispatch(notebookSlice.actions.downFromCurrentCell());
        },
    });

    useEffect(() => {
        if (anchorCellId && cellsIdsSet.has(anchorCellId)) {
            dispatch(notebookSlice.actions.setFocusedCellById({cellId: anchorCellId}));
            document
                .querySelector(getAnchorHashCellIdSelector(anchorCellId))
                ?.scrollIntoView({behavior: 'smooth', block: 'center'});
        }
    }, [anchorCellId, cellsIdsSet]);

    const renderSeparator = useCallback(
        ({index}: {index: number}) => (
            <AddJupyterCellButtonContainer addIndex={index} qa={NotebookPageQa.InsertCellControl} />
        ),
        [],
    );

    const renderAddCellButton = useCallback(
        ({index}: {index: number}) => (
            <AddJupyterCellButtonContainer addIndex={index} qa={NotebookPageQa.AddCellControl} />
        ),
        [],
    );

    const renderPlaceholder = useCallback(() => <NotebookPlaceholderContainer />, []);

    const renderCell = useCallback(
        ({index}: {index: number}) => <JupyterCellContainer cellIndex={index} />,
        [],
    );

    return {
        cellIds,
        handleSortEnd,
        renderSeparator,
        renderAddCellButton,
        renderPlaceholder,
        renderCell,
    };
};

export const JupyterGridContainer = () => {
    const jupyterGridProps = useJupyterGrid();

    return <JupyterGrid {...jupyterGridProps} />;
};
