import {createTractoAsyncThunk} from '../../../../../store/tracto-async-thunk';
import type {TractoNotebookCellSQLConfig} from '../../../types/version';
import {getItemStrict} from '../../../utils/strict-selectors';
import {selectCell} from '../../selectors/notebook';
import {getCellSQLConfig} from '../../../../../utils/cell';
import {notebookSlice} from '../../slices/notebook';
import _merge from 'lodash/merge';
import {batch} from 'react-redux';
import {NotebookSQLHelper} from '../../../utils/sql/helper';

export const updateSQLTemplateVariables = createTractoAsyncThunk<
    void,
    {cellId: string; variables: Record<string, string>}
>('jupyter.sql-cell.actions.updateSQLTemplateVariables', (payload, thunkAPI) => {
    const {cellId, variables} = payload;
    const state = thunkAPI.getState();

    const cell = getItemStrict(selectCell(state, cellId));

    const oldSqlConfig = getItemStrict(getCellSQLConfig(cell));

    const updatedVariables = _merge({}, oldSqlConfig.variables, variables);

    const sql: TractoNotebookCellSQLConfig = _merge({}, oldSqlConfig, {
        variables: updatedVariables,
    });

    batch(() => {
        thunkAPI.dispatch(
            notebookSlice.actions.setCellSQLConfig({
                cellId,
                sql,
            }),
        );

        thunkAPI.dispatch(
            notebookSlice.actions.setCellSource({
                cellId,
                source: NotebookSQLHelper.getQuery(sql.template_id, updatedVariables),
                view_source: updatedVariables.query,
            }),
        );
    });
});
