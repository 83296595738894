import Link from '@ytsaurus-ui-platform/src/ui/components/Link/Link';
import React from 'react';
import YT from '@ytsaurus-ui-platform/src/ui/config/yt-config';
import {Page} from '@ytsaurus-ui-platform/src/shared/constants/settings';
import {useSelector} from 'react-redux';
import {selectBlueprintId, selectImageName} from 'features/ImageBuilder/store/selectors/blueprint';

const ONE_DAY_S = 24 * 3600 * 1000;

const makeIntervalQuery = () => {
    const to = Date.now() / 1000;

    const from = to - ONE_DAY_S * 7;

    return `to=${to}&from=${from}`;
};

export const BluePrintCrossLinksContainer: React.FC = () => {
    const blueprint_id = useSelector(selectBlueprintId);

    const cluster = YT.cluster;

    const filter = `Tracto Wizard build ${blueprint_id}`;

    const dataMode = 'archive';

    const imageName = useSelector(selectImageName);

    const lastWeekBuildUrl = `/${cluster}/${Page.OPERATIONS}?filter=${filter}&dataMode=${dataMode}&${makeIntervalQuery()}`;

    const registryLinkUrl = `/${cluster}/${Page.NAVIGATION}?path=//${imageName}`;

    return (
        <div>
            <div className="elements-heading elements-heading_size_xs">Cross-links</div>
            <div className="elements-section">
                <Link url={registryLinkUrl}>Tracto registry</Link>
            </div>
            <div className="elements-section">
                <Link
                    url={lastWeekBuildUrl}
                    onClick={(e) => {
                        (e.target as HTMLLinkElement).href =
                            `/${cluster}/${Page.OPERATIONS}?filter=${filter}&dataMode=${dataMode}&${makeIntervalQuery()}`;
                    }}
                >
                    Build history
                </Link>
            </div>
        </div>
    );
};
