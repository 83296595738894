import type {JupytOwnerType} from '../types';
import React from 'react';
import {RadioButton, type RadioButtonOption} from '@gravity-ui/uikit';
import block from 'bem-cn-lite';

import './JupytSelectPopup.scss';

type JupytSelectPopupProps = {
    value: JupytOwnerType;
    onUpdate: (value: JupytOwnerType) => void;
    renderFilter: () => React.JSX.Element | null;
    renderList: () => React.JSX.Element;
    renderExtraPopupContent?: (args: {
        renderList: () => React.JSX.Element;
        ownerType: JupytOwnerType;
    }) => React.JSX.Element;
};

const options: RadioButtonOption<JupytOwnerType>[] = [
    {content: "User's kernels", value: 'user'},
    {content: 'All kernels', value: 'all'},
];

const b = block('jupyt-select-popup');

export const JupytSelectPopup: React.FC<JupytSelectPopupProps> = (props: JupytSelectPopupProps) => {
    const {value, onUpdate, renderFilter, renderList, renderExtraPopupContent} = props;
    return (
        <>
            <RadioButton value={value} onUpdate={onUpdate} options={options} className={b()} />
            {renderFilter()}
            {renderExtraPopupContent
                ? renderExtraPopupContent({renderList, ownerType: value})
                : renderList()}
        </>
    );
};
