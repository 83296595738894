import {useEffect, useRef} from 'react';
import hotkeys, {HotkeysEvent} from 'hotkeys-js';

function useRefHook<T>(value: T) {
    const ref = useRef(value);
    ref.current = value;
    return ref;
}

type UseHotkeyPayload = {
    keys: string;
    handler: (evt: KeyboardEvent, hotkeysEvent: HotkeysEvent) => void;
    scope?: string;
};

const tractoHotkeyFilter = () => true;

export const useHotkey = ({handler, keys, scope = 'tracto-hotkey'}: UseHotkeyPayload) => {
    const handlerRef = useRefHook(handler);

    useEffect(() => {
        const handler = (event: KeyboardEvent, hotkeyEvent: HotkeysEvent) => {
            handlerRef.current(event, hotkeyEvent);
        };

        // We do that because otherwise hotkeys are not fire on text inputs
        // https://github.com/jaywcjlove/hotkeys-js/blob/b0038773f3b902574f22af747f3bb003a850f1da/index.d.ts#L154
        const originalFilter = hotkeys.filter;
        hotkeys.filter = tractoHotkeyFilter;

        const originalScope = hotkeys.getScope();
        hotkeys.setScope(scope);

        hotkeys(keys, scope, handler);

        return () => {
            hotkeys.filter = originalFilter;
            hotkeys.setScope(originalScope);
            hotkeys.unbind(keys, scope, handler);
        };
    }, []);
};
