import {useUpdater} from '@ytsaurus-ui-platform/src/ui/hooks/use-updater';
import {WorkflowRunsTabComponent} from 'features/Orchestracto/components/WorkflowRunsTabComponent/WorkflowRunsTabComponent';
import {
    loadWorkflowRunsFormCypress,
    resetWorkflowState,
} from 'features/Orchestracto/store/actions/runs';
import {
    selectHasNonPersistentRunInList,
    selectIsRunsLoaded,
} from 'features/Orchestracto/store/selectors/runs';
import React, {useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {StepDetailsModal} from '../StepDetailsModal/StepDetailsModal';
import {NavigationTabLoader} from 'components/NavigationTabLoader/NavigationTabLoader';

export const WorkflowRunsTabContainer: React.FC = () => {
    const dispatch = useDispatch();
    const isRunsLoaded = useSelector(selectIsRunsLoaded);
    const hasNonPersistentRun = useSelector(selectHasNonPersistentRunInList);

    const update = useCallback(() => {
        dispatch(loadWorkflowRunsFormCypress());
    }, []);

    useUpdater(update, {timeout: hasNonPersistentRun ? 5000 : undefined});

    useEffect(() => {
        return () => {
            dispatch(resetWorkflowState());
        };
    }, []);

    if (!isRunsLoaded) {
        return <NavigationTabLoader />;
    }

    return (
        <>
            <WorkflowRunsTabComponent />
            <StepDetailsModal />
        </>
    );
};
