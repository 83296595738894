import {SQLViewTemplate} from 'features/Jupyter/types/sql/view';
import React from 'react';
import {BasicSQLControl} from './elements/BasicSQLControl';

type JupyterCellSQLControlsProps = {
    template: Array<SQLViewTemplate>;
    onVariableChange: (variable: string, value: string) => void;
};

export const JupyterCellSQLControls: React.FC<JupyterCellSQLControlsProps> = (
    props: JupyterCellSQLControlsProps,
) => {
    const {template, onVariableChange} = props;

    return (
        <>
            {template.map(({variable, default_value, view, required, title, value}) => (
                <BasicSQLControl
                    title={title}
                    required={required}
                    defaultValue={default_value}
                    onChange={onVariableChange}
                    id={variable}
                    view={view}
                    value={value}
                />
            ))}
        </>
    );
};
