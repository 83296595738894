import React from 'react';
import cn from 'bem-cn-lite';
import {Button, DropdownMenu, DropdownMenuItem} from '@gravity-ui/uikit';
import Icon from '@ytsaurus-ui-platform/src/ui/components/Icon/Icon';
import Modal from '@ytsaurus-ui-platform/src/ui/components/Modal/Modal';
import {KernelPageQa} from '../../../../../../../shared/qa';
import type {JupytServerStateType} from '../../../../api/jupyt';
import {Deferred} from '../../../../../../utils/deferred';

import './JupytListTableActions.scss';

const block = cn('jupyt-list-table-actions');

const useDeleteKernelConfirmModal = () => {
    const defer = React.useRef<Deferred>();
    const [visible, setVisible] = React.useState(false);

    const handleCancel = () => {
        setVisible(false);
        defer.current?.reject();
    };

    const handleConfirm = () => {
        setVisible(false);
        defer.current?.resolve(null);
    };

    const renderModal = (kernelName: string) => (
        <Modal
            visible={visible}
            title={`Remove ${kernelName || ''}`}
            content="Are you sure you want to delete the kernel?"
            onCancel={handleCancel}
            onConfirm={handleConfirm}
            onOutsideClick={handleCancel}
        />
    );

    return {
        renderModal,
        startConfirmation: () => {
            defer.current = new Deferred();

            setVisible(true);

            return defer.current;
        },
    };
};

export function JupytListTableActions({
    onRemoveClick,
    onStartClick,
    onStopClick,
    onResumeClick,
    isSuspended,
    state,
    view,
    kernelName,
}: {
    onRemoveClick: () => void;
    onStartClick: () => void;
    onStopClick: () => void;
    onResumeClick?: () => void;
    isSuspended?: boolean;
    state?: JupytServerStateType;
    view?: 'dropdown' | 'list';
    kernelName: string;
}) {
    const {renderModal, startConfirmation} = useDeleteKernelConfirmModal();

    const start = {
        icon: <Icon awesome="play-circle" />,
        text: 'Start',
        key: 'start',
        action: () => {
            onStartClick();
        },
    };

    const stop = {
        icon: <Icon awesome="stop-circle" />,
        text: 'Stop',
        key: 'stop',
        action: () => {
            onStopClick();
        },
    };
    const remove = {
        icon: <Icon awesome="trash-alt" />,
        text: 'Remove',
        key: 'remove',
        action: () => {
            startConfirmation()
                .promise.then(() => onRemoveClick())
                .catch(() => null);
        },
    };
    const resume = {
        icon: <Icon awesome="play-circle" />,
        text: 'Resume',
        key: 'resume',
        action: () => {
            onResumeClick?.();
        },
    };

    const items = React.useMemo(() => {
        if (isSuspended && state === 'active') {
            return [resume, stop];
        }

        if (state === 'inactive') {
            return [start];
        }

        return [stop];
    }, [isSuspended, state]);

    if (view === 'list') {
        return (
            <div className={block({view})} data-qa={KernelPageQa.KernelPageActionsList}>
                {[...items, remove].map((item) => {
                    return (
                        <Button key={item.key} onClick={item.action} title={item.text}>
                            {item.icon}
                            {item.text}
                        </Button>
                    );
                })}
                {renderModal(kernelName)}
            </div>
        );
    }

    const menuItems: Array<Array<DropdownMenuItem>> = [items, [remove]];

    return (
        <>
            <DropdownMenu
                popupProps={{
                    qa: KernelPageQa.KernelPageActionsPopup,
                }}
                switcher={
                    <Button view={'flat'} qa={KernelPageQa.KernelPageActions}>
                        <Icon awesome="ellipsis-h" />
                    </Button>
                }
                items={menuItems}
            />
            {renderModal(kernelName)}
        </>
    );
}
