import React, {useCallback, useMemo} from 'react';

import {useSelector} from 'react-redux';
import {FormApi, YTDFDialog} from '@ytsaurus-ui-platform/src/ui/components/Dialog';
import {createNotebook} from '../../../store/actions/notebook/content';
import {useTractoDispatch} from '../../../../../store/tracto-dispatch';
import {getPath} from '@ytsaurus-ui-platform/src/ui/store/selectors/navigation';
import {updateView} from '@ytsaurus-ui-platform/src/ui/store/actions/navigation';
import {useHistory} from 'react-router';
import {getCluster, getCurrentUserName} from '@ytsaurus-ui-platform/src/ui/store/selectors/global';
import {tractoLocalStorage} from '../../../../../utils/tracto-local-storage';
import {getOperationAlias} from 'features/Jupyter/store/selectors/jupytOperation';
import {readFileAsString} from 'utils/file';
import {selectModalCreateDocumentState} from 'features/Navigation/store/selectors/modals/modal-create-document';
import {modalCreateDocumentSlice} from 'features/Navigation/store/slices/modals/modal-create-document';

type FormValues = {notebookPath: string; notebook: File};

type NotebookSaveDialogProps = {
    path: string;
    onAdd: (payload: {path: string}) => void;
};

const NotebookSaveDialog = (props: NotebookSaveDialogProps) => {
    const dispatch = useTractoDispatch();
    const state = useSelector(selectModalCreateDocumentState);
    const handleIsApplyDisabled = useCallback(() => {
        return false;
    }, []);

    const onAdd = useCallback(
        async (formApi: FormApi<FormValues, Partial<FormValues>>) => {
            const {notebookPath: path, notebook} = formApi.getState().values;

            let content;

            if (notebook) {
                content = JSON.parse(await readFileAsString(notebook));
            }

            const {payload} = await dispatch(createNotebook({path, content}));

            if (payload) {
                dispatch(modalCreateDocumentSlice.actions.close());
                return props.onAdd({path});
            }

            return Promise.reject();
        },
        [props.onAdd],
    );

    return (
        <YTDFDialog<FormValues>
            visible={state.documentType === 'notebook'}
            headerProps={{title: 'Create notebook'}}
            onAdd={onAdd}
            onClose={() => {
                dispatch(modalCreateDocumentSlice.actions.close());
            }}
            fields={[
                {
                    name: 'notebookPath',
                    required: true,
                    type: 'text',
                    caption: 'Notebook path',
                    tooltip: 'Path to new notebook',
                    initialValue: props.path,
                },
                {
                    type: 'file-picker',
                    name: 'notebook',
                    caption: 'Notebook file',
                    tooltip: 'You can upload existing notebook file',
                    onChange: (
                        value: File,
                        _oldValue: File | null,
                        _field: unknown,
                        _fieldName: unknown,
                        formApi: FormApi<FormValues, Partial<FormValues>>,
                    ) => {
                        const initialPath = props.path;
                        const initialPathWithoutFile = initialPath
                            .split('/')
                            .slice(0, -1)
                            .join('/');
                        const newNotebookPath = initialPathWithoutFile + '/' + value.name;

                        formApi.change('notebookPath', newNotebookPath);
                    },
                    initialValue: null,
                } as any,
            ]}
            isApplyDisabled={handleIsApplyDisabled}
        />
    );
};

export const NavigationNotebookSaveDialog = () => {
    const dispatch = useTractoDispatch();
    const onAdd = useCallback(() => {
        dispatch(updateView());
    }, []);
    const path = `${useSelector(getPath)}/New notebook`;
    return <NotebookSaveDialog onAdd={onAdd} path={path} />;
};

export const KernelNotebookSaveDialog = () => {
    const history = useHistory();
    const cluster = useSelector(getCluster);
    const user = useSelector(getCurrentUserName);
    const alias = useSelector(getOperationAlias);
    const onAdd = useCallback(
        ({path}: {path: string}) => {
            if (alias) {
                tractoLocalStorage.storeNotebookMetadata({
                    id: path,
                    defaultAlias: alias,
                });
            }
            history.push(`/${cluster}/navigation?path=${path}`);
        },
        [history, alias],
    );
    const path = useMemo(() => {
        return `//tmp/temporary_notebooks/${user}-notebook-${crypto.randomUUID().slice(0, 8)}`;
    }, []);
    return <NotebookSaveDialog onAdd={onAdd} path={path} />;
};
