import React, {useState} from 'react';
import {Alert, Button, Dialog, Flex, FlexProps, TextInput} from '@gravity-ui/uikit';
import {
    type FormApi,
    YTDFDialog,
    makeErrorFields,
} from '@ytsaurus-ui-platform/src/ui/components/Dialog';
import {YTError} from '@ytsaurus-ui-platform/src/@types/types';
import {sha256} from '@ytsaurus-ui-platform/src/ui/utils/sha256';
import axios from 'axios';

type PasswordModalProps = {
    visible: boolean;
    user: string;
    cluster: string;
    description?: string;
    onSuccess: (password: string) => void;
    onClose: () => void;
    onIssueToken: (password_sha256?: string) => Promise<string>;
};

const PasswordModal: React.FC<PasswordModalProps> = (props: PasswordModalProps) => {
    const {visible, onSuccess, onClose, user, cluster, description, onIssueToken} = props;

    const [error, setError] = useState<YTError>();

    const handleSubmit = React.useCallback(
        async (data: FormApi<{password: string}>) => {
            setError(undefined);

            const password = data.getState().values.password;

            return axios
                .post(`/api/yt/${cluster}/login`, {
                    username: user,
                    password,
                })
                .then(() => {
                    return sha256(password);
                })
                .then(onIssueToken)
                .then((token) => onSuccess(token))
                .catch((err) => setError(err?.response?.data || err));
        },
        [onSuccess, user, cluster, description],
    );

    return (
        <Dialog open={visible} onClose={onClose} hasCloseButton={true}>
            <YTDFDialog
                visible={true}
                headerProps={{
                    title: 'Authentication',
                }}
                fields={[
                    {
                        name: 'error-block',
                        type: 'block',
                        extras: {
                            children: (
                                <Alert message="To access tokens management, you need enter your password" />
                            ),
                        },
                    },
                    {
                        name: 'password',
                        type: 'text',
                        required: true,
                        caption: 'Password',
                        extras: () => ({type: 'password'}),
                    },
                    ...makeErrorFields([error]),
                ]}
                modal={false}
                onAdd={handleSubmit}
                pristineSubmittable={true}
                footerProps={{propsButtonCancel: {onClick: onClose}}}
            />
        </Dialog>
    );
};

export type YTTokenInputProps = {
    value: string;
    user: string;
    cluster: string;
    onUpdate: (value: string) => void;
    description?: string;
    isOAuth?: boolean;
    onIssueToken: (password_sha256?: string) => Promise<string>;
} & FlexProps;

export const YTTokenInput: React.FC<YTTokenInputProps> = (props: YTTokenInputProps) => {
    const {value, onUpdate, cluster, user, description, onIssueToken, isOAuth, ...flexProps} =
        props;

    const [token, setToken] = React.useState<YTTokenInputProps['value']>(value);
    const [visible, setVisible] = React.useState(false);

    const handleTokenUpdate = React.useCallback(
        (newToken: string) => {
            setToken(newToken);
            setVisible(false);
            onUpdate(newToken);
        },
        [onUpdate],
    );

    const handleOpen = React.useCallback(async () => {
        if (isOAuth) {
            handleTokenUpdate(await onIssueToken());
        } else {
            setVisible(true);
        }
    }, [isOAuth, onIssueToken, onUpdate]);

    const handleClose = React.useCallback(() => {
        setVisible(false);
    }, []);

    return (
        <>
            <Flex {...flexProps}>
                <TextInput
                    value={token}
                    onUpdate={handleTokenUpdate}
                    placeholder={'Please enter YT token'}
                />
                <Button view="action" onClick={handleOpen}>
                    Create new YT token
                </Button>
            </Flex>
            <PasswordModal
                description={description}
                visible={visible}
                cluster={cluster}
                user={user}
                onSuccess={handleTokenUpdate}
                onClose={handleClose}
                onIssueToken={onIssueToken}
            />
        </>
    );
};
