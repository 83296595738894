import type {YTError} from '@ytsaurus-ui-platform/src/@types/types';

export function isYTError(error: unknown): error is YTError {
    if (!error || typeof error !== 'object') {
        return false;
    }

    const ytError = error as YTError;

    if (typeof ytError.message !== 'string') {
        return false;
    }

    if (ytError.code !== undefined && typeof ytError.code !== 'number') {
        return false;
    }

    return true;
}
