import React, {useCallback} from 'react';
import {
    DescriptorType,
    Flex,
    Label,
    type LabelProps,
    TableColumnConfig,
    TextInput,
} from '@gravity-ui/uikit';
import {TableProps} from '@gravity-ui/uikit/build/esm/components/Table/Table';
import {WorkflowRunListItem} from 'features/Orchestracto/types/runs';
import type {WorkflowExecutionStatus} from '../../types/workflow';
import './WorkflowRunsListComponent.scss';
import block from 'bem-cn-lite';
import {dateTimeParse, guessUserTimeZone} from '@gravity-ui/date-utils';
import {
    RelativeRangeDatePicker,
    type RelativeRangeDatePickerValue,
} from '@gravity-ui/date-components';
import {RUNS_FILTER_PRESETS, RUNS_FILTER_UI_FORMAT} from '../../constants/runs/filter';
import {MemorizedUIKitTable} from '../../../../components/MemorizedTable/MemorizedUIKitTable';

const b = block('workflow-runs-list');

interface WorkflowRunsListComponentProps {
    data: WorkflowRunListItem[];
    selectedItemId?: string;
    onRowClick: TableProps<WorkflowRunListItem>['onRowClick'];
    onDatetimeChange: (v: RelativeRangeDatePickerValue | null) => void;
    onLimitChange: (v: string) => void;
    limit: string;
    filter: RelativeRangeDatePickerValue;
}

const statusToLabelMap: Record<WorkflowExecutionStatus, LabelProps['theme']> = {
    done: 'success',
    failed: 'danger',
    error: 'danger',
    to_do: 'unknown',
    running: 'warning',
    stopped: 'unknown',
};

const columns: TableColumnConfig<WorkflowRunListItem>[] = [
    {
        id: 'id',
        name: 'Id',
        template: (item) => {
            return item.key;
        },
    },
    {
        id: 'status',
        name: 'Status',
        template: (item) => {
            return <Label theme={statusToLabelMap[item.status] ?? 'unknown'}>{item.status}</Label>;
        },
    },
    {
        id: 'trigger-reason',
        name: 'Trigger reason',
        template: (item) => {
            return item.triggerType;
        },
    },
    {
        id: 'start-time',
        name: 'Start time',
        template: (item) => {
            return dateTimeParse(item.startTime)?.format(RUNS_FILTER_UI_FORMAT) ?? `null`;
        },
    },
];

export const WorkflowRunsListComponent: React.FC<WorkflowRunsListComponentProps> = ({
    data,
    selectedItemId,
    onRowClick,
    onDatetimeChange,
    onLimitChange,
    filter,
    limit,
}) => {
    const getRowDescriptor = useCallback(
        (row: WorkflowRunListItem) => {
            const descriptor: DescriptorType = {
                classNames: [b('row', {selected: row.key === selectedItemId})],
            };

            return descriptor;
        },
        [selectedItemId],
    );

    return (
        <div className={b()}>
            <Flex alignItems="center" gap={5}>
                <RelativeRangeDatePicker
                    defaultTimeZone={guessUserTimeZone()}
                    size="l"
                    onUpdate={onDatetimeChange}
                    value={filter}
                    format={RUNS_FILTER_UI_FORMAT}
                    className={b('range-picker')}
                    withPresets={true}
                    withApplyButton={true}
                    presetTabs={RUNS_FILTER_PRESETS}
                />
                <TextInput
                    label="Limit"
                    size="l"
                    className={b('limit-input')}
                    value={limit}
                    onUpdate={onLimitChange}
                    autoComplete={false}
                />
            </Flex>
            <MemorizedUIKitTable
                className={b('table')}
                data={data}
                columns={columns}
                onRowClick={onRowClick}
                getRowDescriptor={getRowDescriptor}
            />
        </div>
    );
};
