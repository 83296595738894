import React from 'react';
import {Copy} from '@gravity-ui/icons';

import type {
    BoundDropdownWithHotkeysItem,
    BoundDropdownWithHotkeysProps,
} from '../../../../../libs/hotkeys';
import type {JUPYTER_HOTKEYS} from '../../../constants/hotkeys';
import {CopyToClipboard} from '@gravity-ui/uikit';

const getCellLink = (cellId: string) => {
    return new URL(
        `${window.location.pathname}${window.location.search}#${cellId}`,
        window.location.origin,
    ).toString();
};

export const getDropdownItems = (
    cellId: string,
): BoundDropdownWithHotkeysItem<typeof JUPYTER_HOTKEYS>[] => {
    return [
        {
            group: true,
            label: 'Collaboration',
            items: [
                {
                    id: 'copy-link',
                    text: 'Copy link to cell',
                    iconStart: <Copy />,
                    wrapper: (node) => (
                        <CopyToClipboard text={getCellLink(cellId)}>{() => node}</CopyToClipboard>
                    ),
                },
            ],
        },
    ];
};

export const dropdownButtonProps: BoundDropdownWithHotkeysProps<typeof JUPYTER_HOTKEYS>['button'] =
    {
        view: 'flat-secondary',
    };
