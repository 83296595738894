import React, {useCallback, useMemo} from 'react';
import {FormApi, YTDFDialog} from '@ytsaurus-ui-platform/src/ui/components/Dialog';
import {useSelector} from 'react-redux';
import {blueprintSlice} from 'features/ImageBuilder/store/slices/blueprintSlice';
import {useTractoDispatch} from '../../../../store/tracto-dispatch';
import {userRunNewBuild} from 'features/ImageBuilder/store/actions/blueprints';
import {selectNewBuildDialogVisible} from 'features/ImageBuilder/store/selectors/runs';
import moment from 'moment';

type FormValues = {
    tag: string;
};

export const NewBuildDialogContainer: React.FC = () => {
    const dispatch = useTractoDispatch();
    const visible = useSelector(selectNewBuildDialogVisible);

    const onClose = useCallback(() => {
        dispatch(blueprintSlice.actions.hideNewBuildDialog());
    }, []);

    const onAdd = useCallback(async (form: FormApi<FormValues>) => {
        const {tag} = form.getState().values;
        await dispatch(userRunNewBuild({tag}));
        dispatch(blueprintSlice.actions.hideNewBuildDialog());
    }, []);

    const initialTag = useMemo(() => {
        return `${moment().format('YYYY-MM-DD')}-${crypto.randomUUID().slice(0, 4)}`;
    }, [visible]);

    const handleIsApplyDisabled = useCallback(() => false, []);

    return (
        <YTDFDialog<FormValues>
            visible={visible}
            headerProps={{title: 'Start blueprint build'}}
            onClose={onClose}
            fields={[
                {
                    type: 'text',
                    name: 'tag',
                    caption: 'Image tag',
                    required: true,
                    extras: {
                        placeholder: 'Please enter image tag',
                    },
                    initialValue: initialTag,
                },
            ]}
            onAdd={onAdd}
            isApplyDisabled={handleIsApplyDisabled}
        />
    );
};
