import React, {ComponentProps, useCallback} from 'react';
import {BlueprintEditorComponent} from 'features/ImageBuilder/components/BlueprintEditorComponent/BlueprintEditorComponent';
import {useTractoDispatch} from '../../../../store/tracto-dispatch';
import {blueprintSlice} from 'features/ImageBuilder/store/slices/blueprintSlice';
import {useSelector} from 'react-redux';
import {selectContent} from 'features/ImageBuilder/store/selectors/blueprint';

type BlueprintEditorContainerProps = Pick<
    ComponentProps<typeof BlueprintEditorComponent>,
    'className'
>;

export const BlueprintEditorContainer: React.FC<BlueprintEditorContainerProps> = (ownProps) => {
    const dispatch = useTractoDispatch();
    const value = useSelector(selectContent);

    const onChange = useCallback((content: string) => {
        dispatch(blueprintSlice.actions.setContent({content}));
    }, []);

    const props: ComponentProps<typeof BlueprintEditorComponent> = {
        value,
        onChange,
        ...ownProps,
    };

    return <BlueprintEditorComponent {...props} />;
};
