import {AsideFallback, PageLayout} from '@gravity-ui/navigation';
import React from 'react';
import type {AppNavigationProps} from '@ytsaurus-ui-platform/src/ui/containers/AppNavigation/AppNavigationPageLayout';
import {getSysUserData} from '../../../yt-api/v4/users';
import {getDefaultSysUser, getSysUserEmail, getSysUserName} from '../../../utils/user';
import {RumWrapper, YTApiId} from '@ytsaurus-ui-platform/src/ui/rum/rum-wrap-api';
import {RumMeasureTypes} from '@ytsaurus-ui-platform/src/ui/rum/rum-measure-types';
import {getCluster, getSettingsCluster} from '@ytsaurus-ui-platform/src/ui/store/selectors/global';
import {useDispatch, useSelector} from 'react-redux';
import type {TractoAppNavigationProps} from '../components/AppNavigation/AppNavigation';
import {importManageTokens} from '@ytsaurus-ui-platform/src/ui/containers/ManageTokens';
import {useHistory} from 'react-router';
import YT from '@ytsaurus-ui-platform/src/ui/config/yt-config';
import {getUserType, promisifiedInternalUserAvatarCheck} from '@nebius-ui/ava';

const Aside = React.lazy(() => import('../components/AppNavigation/AppNavigation'));

function AppNavigationPageLayoutComponent(props: AppNavigationProps) {
    const dispatch = useDispatch();
    const history = useHistory();

    const {onChangeCompact, className, compact, currentUser, authWay, ...rest} = props;

    const cluster = useSelector(getCluster);
    const settingsCluster = useSelector(getSettingsCluster);

    const [userConfig, setUserConfig] = React.useState<TractoAppNavigationProps['userConfig']>({
        userType: 'uncertain',
        config: {
            email: '',
        },
        status: 'loading',
    });

    const handleManageTokensClick = React.useCallback(() => {
        return importManageTokens().then((actions) => {
            dispatch(actions.openManageTokensModal());
        });
    }, []);

    const handlePasswordChangeClick = React.useCallback(() => {
        history.push(`/${YT.cluster}/change-password`);
    }, [history]);

    React.useEffect(() => {
        if (!cluster) {
            return;
        }

        const rumId = new RumWrapper(cluster, RumMeasureTypes.NAVIGATION_PRELOAD);

        rumId.fetch(
            YTApiId.navigationAttributes,
            getSysUserData(currentUser)
                .then((userData) => {
                    if (!userData.value) {
                        return [getDefaultSysUser(currentUser), false] satisfies [
                            {email: string; name: string},
                            boolean,
                        ];
                    }

                    const email = getSysUserEmail(userData.value);
                    const name = getSysUserName(userData.value);
                    return Promise.all([{email, name}, promisifiedInternalUserAvatarCheck(email)]);
                })
                .then(([config, check]) => {
                    const userType = getUserType(config.email, check);
                    setUserConfig({userType, config, status: 'success'});
                })
                .catch(() => {
                    setUserConfig((prev) => ({...prev, userType: 'unknown', status: 'success'}));
                }),
        );
    }, [currentUser, cluster]);

    const showSettings = Boolean(settingsCluster && currentUser);
    let showUserIcon = Boolean(currentUser);

    if (authWay === 'passwd') {
        showUserIcon = Boolean(settingsCluster) && Boolean(currentUser);
    }

    return (
        <PageLayout compact={compact} className={className}>
            <React.Suspense fallback={<AsideFallback />}>
                <Aside
                    {...rest}
                    onManageTokensClick={handleManageTokensClick}
                    onPasswordChangeClick={handlePasswordChangeClick}
                    onChangeCompact={onChangeCompact}
                    currentUser={currentUser}
                    userConfig={userConfig}
                    showSettings={showSettings}
                    showUserIcon={showUserIcon}
                    authWay={authWay}
                />
            </React.Suspense>

            <PageLayout.Content>{props.children}</PageLayout.Content>
        </PageLayout>
    );
}

export const AppNavigationPageLayout = React.memo(AppNavigationPageLayoutComponent);
