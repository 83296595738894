import type {TractoNotebookContent} from '../features/Jupyter/types/version';

type LocalStorageNotebook = {
    id: string;
    content?: TractoNotebookContent;
    defaultAlias?: string;
};

export interface YTTractoStorageData {
    notebooks: LocalStorageNotebook[];
}

export function storageKey(key: keyof YTTractoStorageData) {
    return `yt_nebius_${key}`;
}

const MAX_NOTEBOOKS_TO_STORE = 40;

class TractoLocalStorage {
    getNotebook(id: string): undefined | LocalStorageNotebook {
        const notebooks = this.get('notebooks');

        if (!Array.isArray(notebooks)) {
            return;
        }

        return notebooks.find((item) => item?.id === id);
    }

    storeNotebookMetadata(value: LocalStorageNotebook) {
        let notebooks = this.get('notebooks');

        if (!Array.isArray(notebooks)) {
            notebooks = [value];
        } else {
            const existingValue = notebooks.find((item) => item.id === value.id);
            if (existingValue) {
                Object.assign(existingValue, value);
            } else {
                notebooks.unshift(value);
            }
        }

        notebooks = notebooks.slice(0, MAX_NOTEBOOKS_TO_STORE);

        this.set('notebooks', notebooks);
    }

    deleteNotebookMetadata(id: string) {
        const notebooks = this.get('notebooks');

        if (notebooks) {
            const updatedNotebooks = notebooks.filter((item) => item.id !== id);

            this.set('notebooks', updatedNotebooks);
        }
    }

    private get<K extends keyof YTTractoStorageData>(k: K): undefined | YTTractoStorageData[K] {
        try {
            const rawValue = localStorage.getItem(storageKey(k));
            return rawValue !== null && rawValue !== undefined ? JSON.parse(rawValue) : [];
        } catch {
            return undefined;
        }
    }

    private set<K extends keyof YTTractoStorageData, V extends YTTractoStorageData[K]>(k: K, v: V) {
        localStorage.setItem(storageKey(k), JSON.stringify(v));
    }
}

export const tractoLocalStorage = new TractoLocalStorage();
