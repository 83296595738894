import type {TemplateView} from '../../../types/sql/view';
import React, {useCallback} from 'react';
import {TextInput} from '@gravity-ui/uikit';

type BasicSQLControlProps = {
    required: boolean;
    defaultValue: string;
    onChange: (variable: string, value: string) => void;
    id: string;
    view: TemplateView;
    title: string;
    value: string;
};

export const BasicSQLControl: React.FC<BasicSQLControlProps> = (props: BasicSQLControlProps) => {
    const {defaultValue, onChange, id, view, title, value} = props;

    const handleChange = useCallback(
        (changedValue: string) => {
            onChange(id, changedValue);
        },
        [id],
    );

    switch (view) {
        case 'input':
            return (
                <TextInput
                    value={value}
                    label={title}
                    size="s"
                    defaultValue={defaultValue}
                    onUpdate={handleChange}
                />
            );
        default:
            return null;
    }
};
