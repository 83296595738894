import {useDispatch, useSelector} from 'react-redux';
import React, {useCallback} from 'react';
import {withFocusBeforeCellAction} from '../../utils/notebook';
import {notebookSlice} from '../../store/slices/notebook';
import {TractoRootState} from '../../../../store/reducers';
import type {TractoNotebookCell} from '../../types/version';
import {selectNotebookCells, selectRunningCells} from '../../store/selectors/notebook';
import {extractCellId} from '../../utils/cell/common';
import * as nbformat from '@jupyterlab/nbformat';
import {executeCellByClick, interruptExecution} from '../../store/actions/execute';
import {NotebookCellType} from '../../constants/cell';
import {JupyterCellControls} from '../../components/JupyterCellControls/JupyterCellControls';
import {getCellView} from '../../../../utils/cell';
import {addNotebookCell, changeCellType} from '../../store/actions/notebook/cell';

const useJupyterCellControls = (cellIndex: number) => {
    const dispatch = useDispatch();

    const cell = useSelector<TractoRootState, TractoNotebookCell>(
        (state) => selectNotebookCells(state)[cellIndex],
    );

    const cellView = getCellView(cell);

    const cellId = extractCellId(cell);

    const cellType = cell.cell_type;
    const cellViewType = cellView.view_cell_type;

    const isRunning = useSelector<TractoRootState, boolean>(
        (state) => selectRunningCells(state)[cellId],
    );

    const onTrashBinClick = useCallback(
        withFocusBeforeCellAction(dispatch, cellId, () => {
            dispatch(notebookSlice.actions.deleteCell({currentIndex: cellIndex}));
        }),
        [cellIndex, cellId],
    );

    const onRunClick = useCallback(
        withFocusBeforeCellAction(dispatch, extractCellId(cell), () => {
            if (nbformat.isCode(cell)) {
                dispatch(executeCellByClick({cell}));
            }

            return undefined;
        }),
        [cell],
    );

    const onArrowUpClick = useCallback(
        withFocusBeforeCellAction(dispatch, cellId, () => {
            dispatch(notebookSlice.actions.moveCellUp({currentIndex: cellIndex}));
        }),
        [cellIndex, cellId],
    );

    const onArrowDownClick = useCallback(
        withFocusBeforeCellAction(dispatch, cellId, () => {
            dispatch(notebookSlice.actions.moveCellDown({currentIndex: cellIndex}));
        }),
        [cellIndex, cellId],
    );

    const onChangeCellType = useCallback(
        withFocusBeforeCellAction(dispatch, cellId, (options: string[]) => {
            const [type] = options as NotebookCellType[];

            dispatch(
                changeCellType({
                    cellId,
                    type,
                }),
            );
        }),
        [cellId],
    );

    const onCancelClick = useCallback(
        withFocusBeforeCellAction(dispatch, cellId, () => {
            dispatch(interruptExecution());
        }),
        [cellId],
    );

    const onAddCellBelowClick = useCallback(() => {
        dispatch(
            addNotebookCell({
                currentIndex: cellIndex,
                type: NotebookCellType.CODE,
            }),
        );
    }, [cellIndex]);

    const onAddCellAboveClick = useCallback(() => {
        dispatch(
            addNotebookCell({
                currentIndex: cellIndex - 1,
                type: NotebookCellType.CODE,
            }),
        );
    }, [cellIndex]);

    return {
        onTrashBinClick,
        onRunClick,
        onArrowUpClick,
        onArrowDownClick,
        onChangeCellType,
        onAddCellBelowClick,
        onAddCellAboveClick,
        isRunning,
        onCancelClick,
        cellType,
        cellViewType,
        cellId,
    };
};

type JupyterCellControlsContainerProps = {
    cellIndex: number;
    className: string;
};

export const JupyterCellControlsContainer: React.FC<JupyterCellControlsContainerProps> = (
    props: JupyterCellControlsContainerProps,
) => {
    const controlProps = useJupyterCellControls(props.cellIndex);

    return <JupyterCellControls {...controlProps} className={props.className} />;
};
