import {selectNotebookContent} from '../../selectors/notebook';
import {createTractoAsyncThunk} from '../../../../../store/tracto-async-thunk';
import {notebookSlice} from '../../slices/notebook';
import {getItemStrict} from '../../../utils/strict-selectors';
import {mapNotebookToLatestVersion} from '../../../mappers/notebook';
import {
    createNotebookInCypress,
    getNotebookContentFromCypress,
    saveNotebookInCypress,
} from '../../../api/kernel/content-manager';
import {getCluster, getCurrentUserName} from '@ytsaurus-ui-platform/src/ui/store/selectors/global';
import {getPath} from '@ytsaurus-ui-platform/src/ui/store/selectors/navigation';
import {checkUserPermissionsUI} from '@ytsaurus-ui-platform/src/ui/utils/acl/acl-api';
import type {TractoNotebookContent} from '../../../types/version';
import cloneDeep from 'lodash/cloneDeep';
import {CheckPermissionResult} from '@ytsaurus-ui-platform/src/shared/utils/check-permission';

const SAVE_NOTEBOOK_CONTENT = 'jupyter/SAVE_NOTEBOOK_CONTENT';
const SAVE_AS_NOTEBOOK_CONTENT = 'jupyter/SAVE_AS_NOTEBOOK_CONTENT';
const GET_NOTEBOOK_CONTENT = 'jupyter/GET_NOTEBOOK_CONTENT';
const CLEAR_NOTEBOOK_STATE = 'jupyter/CLEAR_NOTEBOOK_STATE';

export const saveNotebookContent = createTractoAsyncThunk<void>(
    SAVE_NOTEBOOK_CONTENT,
    async (_, thunkApi) => {
        const notebook = getItemStrict(selectNotebookContent(thunkApi.getState()));

        const cluster = getCluster(thunkApi.getState());

        const path: string = getPath(thunkApi.getState());

        thunkApi.dispatch(notebookSlice.actions.setSavingInProgress({isSavingInProgress: true}));

        try {
            await saveNotebookInCypress({
                cluster,
                content: notebook,
                path,
            });
        } finally {
            thunkApi.dispatch(
                notebookSlice.actions.setSavingInProgress({isSavingInProgress: false}),
            );
        }

        thunkApi.dispatch(notebookSlice.actions.updateSavedNotebookContent());
    },
);

export const createNotebook = createTractoAsyncThunk<
    boolean,
    {path: string; content?: TractoNotebookContent}
>(SAVE_AS_NOTEBOOK_CONTENT, async ({path, content}, thunkApi) => {
    const cluster = getCluster(thunkApi.getState());

    try {
        await createNotebookInCypress({
            path,
            cluster,
        });

        if (content) {
            await saveNotebookInCypress({
                cluster,
                content,
                path,
            });
        }

        return true;
    } catch {
        return thunkApi.rejectWithValue(false);
    }
});

export const copySolutionNotebook = createTractoAsyncThunk<
    boolean,
    {notebookPath: string; notebookContent: TractoNotebookContent}
>(SAVE_AS_NOTEBOOK_CONTENT, async ({notebookPath, notebookContent}, thunkApi) => {
    const cluster = getCluster(thunkApi.getState());

    try {
        await createNotebookInCypress({
            path: notebookPath,
            cluster,
        });

        const metadata = cloneDeep(notebookContent.metadata);

        delete metadata.tracto.is_solution_notebook;

        const content = {
            ...notebookContent,
            metadata,
        };

        await saveNotebookInCypress({
            cluster,
            content,
            path: notebookPath,
        });
        return true;
    } catch {
        return thunkApi.rejectWithValue(false);
    }
});

export const loadNotebookContent = createTractoAsyncThunk<void, string>(
    GET_NOTEBOOK_CONTENT,
    async (path: string, thunkApi) => {
        const cluster = getCluster(thunkApi.getState());
        const user = getCurrentUserName(thunkApi.getState());

        const [{action}] = await checkUserPermissionsUI(path, user, ['write']);

        const model = await getNotebookContentFromCypress({
            cluster,
            path,
        });

        thunkApi.dispatch(
            setNotebookContent({
                notebook: mapNotebookToLatestVersion(model.content),
                writePermission: action,
            }),
        );
    },
);

export const setNotebookContent = createTractoAsyncThunk<
    void,
    {
        notebook: TractoNotebookContent;
        writePermission?: CheckPermissionResult['action'];
        save?: boolean;
    }
>('jupyter/SET_NOTEBOOK_CONENT', async ({notebook, writePermission, save}, thunkApi) => {
    thunkApi.dispatch(
        notebookSlice.actions.setNotebook({
            notebook: mapNotebookToLatestVersion(notebook),
            writePermission,
            save: save ?? true,
        }),
    );
});

export const clearNotebookState = createTractoAsyncThunk<void, void>(
    CLEAR_NOTEBOOK_STATE,
    (_, thunkApi) => {
        thunkApi.dispatch(notebookSlice.actions.clearNotebookState());
    },
);
