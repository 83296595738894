import {SecretListItem} from 'components/SecretsList/SecretsList';
import React, {ComponentProps, useCallback, useEffect, useState} from 'react';
import {SecretStoreTabComponent} from 'features/SecretStore/components/SecretStoreTabComponent/SecretStoreTabComponent';
import {useTractoDispatch} from '../../../../store/tracto-dispatch';
import {
    loadSecretsFromCypress,
    saveSecretsToCypress,
} from 'features/SecretStore/store/actions/secrets';
import {useSelector} from 'react-redux';
import {selectSecrets} from 'features/SecretStore/store/selectors/secrets';
import {secretsSlice} from 'features/SecretStore/store/slices/secretsSlice';
import {delegationsSlice} from 'features/SecretStore/store/slices/delegationsSlice';
import {loadDelegationsFromCypress} from 'features/SecretStore/store/actions/delegations';

export const SecretStoreTabContainer: React.FC = () => {
    const dispatch = useTractoDispatch();
    const secrets = useSelector(selectSecrets);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        (async () => {
            await Promise.all([
                dispatch(loadSecretsFromCypress()),
                dispatch(loadDelegationsFromCypress()),
            ]);
            setLoading(false);
        })();
    }, []);

    const onUpdate = useCallback((value: SecretListItem[]) => {
        dispatch(secretsSlice.actions.setSecrets({secrets: value}));
    }, []);

    const onSaveClick = useCallback(() => {
        dispatch(saveSecretsToCypress());
    }, [secrets]);

    const onCreateDelegationClick = useCallback(() => {
        dispatch(delegationsSlice.actions.showAddDelegationDialog());
    }, []);

    const props: ComponentProps<typeof SecretStoreTabComponent> = {
        value: secrets,
        loading,
        onUpdate,
        onSaveClick,
        onCreateDelegationClick,
    };

    return <SecretStoreTabComponent {...props} />;
};
