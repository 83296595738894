import React from 'react';
import {
    Button,
    type ButtonProps,
    Flex,
    Hotkey,
    Popover,
    type PopoverProps,
    Text,
} from '@gravity-ui/uikit';
import type {HotkeyDescriptor} from '../../types';
import block from 'bem-cn-lite';

import './ButtonWithHotkey.scss';

const b = block('button-with-hotkey');

type PopoverContentProps = {hotkey: Pick<HotkeyDescriptor, 'command' | 'key' | 'platform'>};

const PopoverContent: React.FC<PopoverContentProps> = ({hotkey}: PopoverContentProps) => {
    return (
        <Flex gap={2} alignItems="center" className={b('content')} justifyContent="space-around">
            <Text variant="code-inline-2">{hotkey.command}</Text>
            <Hotkey value={hotkey.key} platform={hotkey.platform} />
        </Flex>
    );
};

export type ButtonWithHotkeyProps = {
    hotkey: Pick<HotkeyDescriptor, 'command' | 'key' | 'platform'>;
    popupPlacement?: PopoverProps['placement'];
    popupClassName?: string;
} & ButtonProps;

export const ButtonWithHotkey: React.FC<ButtonWithHotkeyProps> = (props: ButtonWithHotkeyProps) => {
    const {hotkey, popupPlacement, popupClassName, ...button} = props;

    const content = React.useMemo(() => {
        return <PopoverContent hotkey={hotkey} />;
    }, [hotkey]);

    return (
        <Popover
            tooltipContentClassName={b('tooltip-content')}
            size="s"
            className={popupClassName}
            placement={popupPlacement ?? 'top'}
            content={content}
        >
            <Button {...button} />
        </Popover>
    );
};
