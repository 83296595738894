export const enum JupyterNotebookUIConfigVersion {
    /**
     * initial config, might be also undefined
     * */
    V0 = '0',
    /**
     * - move all tracto metadata for notebook and cells under `tracto` property
     * - introduce `type` property for cell with values `CODE` | `MD` | `SQL`
     * - move cell_id from metadata to id property (https://jupyter.org/enhancement-proposals/62-cell-id/cell-id.html)
     * - cell.id made required since we support 4.5 version of notebook
     */
    V1 = '1',
    /**
     * - rename `tracto.type` to `tracto.view_cell_type` for cells metadata
     * - introduce `tracto.view_source` which will be displayed in cells view
     */
    V2 = '2',
}
