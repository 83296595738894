import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {deleteImage} from './image';

export const fetchManifests = createAsyncThunk(
    'tracto-registry/fetchManifests',
    async (params: {cluster: string; image: {name: string; tags: string[]}}) => {
        const getManifest = (tag: string) =>
            fetch(
                `/${params.cluster}/tracto-registry/v2/${params.image.name}/manifests/${tag}`,
            ).then((response) => response.json());

        const getBlob = (digest: string) =>
            fetch(
                `/${params.cluster}/tracto-registry/v2/${params.image.name}/blobs/${digest}`,
            ).then((response) => response.json());

        return await Promise.all(
            params.image.tags.map(async (tag) => {
                const manifest = (await getManifest(tag)) as DockerManifest;
                const blob = (await getBlob(manifest.config.digest)) as DockerImageConfig;

                return {tag, manifest, blob};
            }),
        );
    },
);

interface DockerManifest {
    schemaVersion: number;
    mediaType: string;
    config: {
        mediaType: string;
        size: number;
        digest: string;
    };
    layers: {
        mediaType: string;
        size: number;
        digest: string;
    }[];
}

export interface DockerImageConfig {
    architecture: string;
    variant?: string;
    config: {
        Env: string[];
        Cmd: string[];
        WorkingDir: string;
        Labels: {
            'org.opencontainers.image.created': string;
            'org.opencontainers.image.description': string;
            'org.opencontainers.image.licenses': string;
            'org.opencontainers.image.ref.name': string;
            'org.opencontainers.image.revision': string;
            'org.opencontainers.image.source': string;
            'org.opencontainers.image.title': string;
            'org.opencontainers.image.url': string;
            'org.opencontainers.image.version': string;
            maintainer: string;
        };
        ArgsEscaped: boolean;
    };
    created: string;
    history: {
        created: string;
        created_by: string;
        empty_layer?: boolean;
        comment?: string;
    }[];
    os: string;
    rootfs: {
        type: string;
        diff_ids: string[];
    };
}

interface ManifestsSliceState {
    manifests: {tag: string; manifest: DockerManifest; blob: DockerImageConfig}[];
    status: 'idle' | 'loading' | 'failed';
}

const initialState = {
    manifests: [],
    status: 'idle',
} satisfies ManifestsSliceState as ManifestsSliceState;

export const manifestsSlice = createSlice({
    name: 'manifests',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchManifests.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchManifests.fulfilled, (state, action) => {
                state.status = 'idle';
                state.manifests = action.payload;
            })
            .addCase(fetchManifests.rejected, (state) => {
                state.status = 'failed';
            })
            .addCase(deleteImage.fulfilled, (state, action) => {
                state.manifests = state.manifests.filter(
                    (manifest) => manifest.tag !== action.payload.tag,
                );
            });
    },
});
