import axios from 'axios';
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';

export const fetchImage = createAsyncThunk(
    'tracto-registry/fetchImages',
    async (opts: {cluster: string; repository: string}) => {
        return axios
            .get(`/${opts.cluster}/tracto-registry/v2/${opts.repository}/tags/list`)
            .then((res) => res.data as unknown as RepositoryTag);
    },
);

export const deleteImage = createAsyncThunk(
    'tracto-registry/DeleteImages',
    async (opts: {cluster: string; repository: string; tag: string}) => {
        return axios
            .delete(`/${opts.cluster}/tracto-registry/v2/${opts.repository}/manifests/${opts.tag}`)
            .then(() => ({tag: opts.tag}));
    },
);

type FetchStatus = 'idle' | 'loading' | 'failed';

interface RepositoryTag {
    name: string;
    tags: string[];
}

interface ImageSliceState {
    tags: string[];
    repository: string;
    status: FetchStatus;
    deleteStatus: FetchStatus;
}

const initialState = {
    repository: '',
    tags: [],
    status: 'idle',
    deleteStatus: 'idle',
} satisfies ImageSliceState as ImageSliceState;

export const imageSlice = createSlice({
    name: 'image',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchImage.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchImage.fulfilled, (state, action) => {
                state.status = 'idle';
                state.tags = action.payload.tags;
                state.repository = action.payload.name;
            })
            .addCase(fetchImage.rejected, (state) => {
                state.status = 'failed';
            })
            .addCase(deleteImage.pending, (state) => {
                state.deleteStatus = 'loading';
            })
            .addCase(deleteImage.fulfilled, (state, action) => {
                state.deleteStatus = 'idle';
                state.tags = state.tags.filter((tag) => tag !== action.payload.tag);
            })
            .addCase(deleteImage.rejected, (state) => {
                state.deleteStatus = 'failed';
            });
    },
});
