import {DelegationsListComponent} from 'features/SecretStore/components/DelegationsListComponent/DelegationsListComponent';
import React from 'react';
import {useTractoDispatch} from '../../../../store/tracto-dispatch';
import {CreateDelegationDialogContainer} from '../CreateDelegationDialogContainer/CreateDelegationDialogContainer';
import {useSelector} from 'react-redux';
import {selectDelegationsList} from 'features/SecretStore/store/selectors/delegations';
import {RemoveDelegationDialogContainer} from '../RemoveDelegationDialogContainer/RemoveDelegationDialogContainer';
import {delegationsSlice} from 'features/SecretStore/store/slices/delegationsSlice';
import {Delegation} from 'features/SecretStore/types/delegations';

export const DelegationsListContainer: React.FC = () => {
    const dispatch = useTractoDispatch();

    const delegationsList = useSelector(selectDelegationsList);

    const onRemove = (delegation: Delegation) => {
        dispatch(delegationsSlice.actions.setDelegationToDelete({delegation}));
    };

    return (
        <div>
            <DelegationsListComponent data={delegationsList} onRemove={onRemove} />
            <CreateDelegationDialogContainer />
            <RemoveDelegationDialogContainer />
        </div>
    );
};
