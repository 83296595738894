import React from 'react';
import block from 'bem-cn-lite';
import './AddJupyterCellButton.scss';
import Button from '@ytsaurus-ui-platform/src/ui/components/Button/Button';

const b = block('add-jupyter-cell-button');

type AddJupyterCellButtonProps = {
    onClick: () => void;
    qa?: string;
};

export const AddJupyterCellButton = ({onClick, qa}: AddJupyterCellButtonProps) => {
    return (
        <Button qa={qa} className={b()} onClick={onClick} width="max">
            Click to add a cell
        </Button>
    );
};
